import { useEffect, useState } from 'react'
import _, { capitalize } from 'lodash'
import VerticalBarChartForCount from './charts/VerticalBarChartForCount'
import { getCategoryByAge, getCountryName } from '../../utils/commonUtils'
import HorizontalBarChart from './charts/HorizontalBarChart'
import { CONST } from '../../constants'

/*
        {
            "id": 90,
            "strava_activity_id": 9754738243,
            "distance": 93929.5,
            "moving_time": 13664,
            "elapsed_time": 11668,
            "elapsed_time_hour": 3.2,
            "start_date_local": "2006-10-13T17:33:22Z",
            "average_speed": 5.572,
            "team": "Team3",
            "club": "CD",
            "rider_sex": "MALE",
            "rider_country_code": "KR",
            "rider_full_name": "Samantha Rowe",
            "rider_age_cat": 35
        },
 */

const itemKey = 'ageRange'
const yKey = 'riders'
// const xKey = 'age'
const xKey = 'rider_age_cat'

function RiderCountByAge ({ rideRecords, color }) {

  const [data, setData] = useState()
  const [maxRiderCount, setMaxRiderCount] = useState(100)

  useEffect(() => {

    //
    // http://www.java2s.com/ref/javascript/node.js-lodash-index.html

    // todo REF: lodash groupby case (key)
    // const groupByCountry = _.groupBy(rideRecords, 'rider_country_code')
    // console.log("--------> groupByCountry:", JSON.stringify(groupByCountry, undefined, 2))

    // todo REF: lodash groupby case (fn)
    // const statusMap = _.groupBy(rideRecords,function(item) {
    //   return item.age >= 50 ? 'young' : 'old';
    // })
    // console.log("--------> statusMap:", JSON.stringify(statusMap, undefined, 2))

    // todo REF: lodash groupby case (fn with switch/case)
    // const catByAge = _.groupBy(rideRecords, function (item) {
    //   return getCategoryByAge(item.age)
    // })
    // console.log('--------> statusMap:', JSON.stringify(catByAge, undefined, 2))

    // todo REF: lodash countBy case (fn)
    // let filtered = _.countBy(rideRecords, (item) => item[itemKey])
    // filtered = Object.entries(filtered).map(([key, value]) => ({
    //   [yKey]: key,
    //   [xKey]: value,
    // }))
    // console.log('--------> filtered:', JSON.stringify(filtered, undefined, 2))
    const catAddedList = _.map(rideRecords, (item) => ({
      ...item,
      [itemKey]: getCategoryByAge(item.rider_age_cat)
    }))
    let filtered = _.countBy(catAddedList, (item) => item[itemKey])
    // console.log('--------> filtered:', JSON.stringify(filtered, undefined, 2))

    // 없는 나이대는 0으로 추가
    const d = {}
    for (const ageCatObj of CONST.AGE_CATETORIES) {
      d[ageCatObj.cat] = 0
    }
    filtered = {
      ...d,
      ...filtered,
    }
    // console.log("--------> d:", d)

    filtered = Object.entries(filtered).map(([key, value]) => ({
      [xKey]: key,
      [yKey]: value,
    }))
    filtered = _.sortBy(filtered, xKey)
    // filtered = _(filtered)
    //   .sortBy(xKey)
    //   .value()


    setData(filtered)

    /*
    [
      {
        "age": "- 19",
        "riders": "1"
      },
      {
        "age": "20 - 24",
        "riders": "8"
      },
     */

    const ridersList = _.map(filtered, yKey)
    // console.log("--------> ridersList:", ridersList)
    // console.log("--------> _.max(ridersList):", _.max(ridersList))
    // console.log("------->>>>>> xxx:", _.max(ridersList) + 10)
    setMaxRiderCount(_.max(ridersList))

  }, [])

  return (
    <div className="">
      <h1 className="chart-title">Age Category Count</h1>
      <div className="chart-container">
        {data && maxRiderCount && (
          <HorizontalBarChart
            data={data}
            yKey={yKey}
            xKey={xKey}
            color={color}
            domainMin={0}
            domainMax={maxRiderCount}
            // domainMax={200}
            xAxisHeight={70}
          />
        )}
      </div>
    </div>
  )
}

export default RiderCountByAge