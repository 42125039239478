import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import Navbar from './components/layout/Navbar'
import Footer from './components/layout/Footer'
// import Home from './pages/Home'
import About from './pages/About'
// import Login from './pages/Login'
// import YourDistance from './pages/YourDistance'
import StravaRedirect from './pages/StravaRedirect'
// import { StravaAuthProvider } from './context/strava_auth/StravaAuthContext'
import SecurePageSample from './pages/SecurePageSample'
import PrivateRoutes from './PrivateRoutes'
import RideEventList from './pages/RideEventList'
import RideEvent from './pages/RideEvent'
import { LocationProvider } from './context/location/LocationContext'
import { useRef } from 'react'
import MenuDrawer from './components/layout/MenuDrawer'
import 'react-toastify/dist/ReactToastify.css'
// import { DjangoAuthProvider } from './context/django_auth/DjangoAuthContext'
import ForceToGetUserInfo from './pages/ForceToGetUserInfo'
import Profile from './pages/Profile'
import { AuthProvider } from './context/auth/AuthContext'
import StravaResoruceTest from './pages/StravaResourceTest'
import DjangoResourceTest from './pages/DjangoResourceTest'
import StravaActivitySubmission from './pages/StravaActivitySubmission'
import RideListPage from './pages/RideListPage'
import CreateRideEvent from './pages/mgmt/CreateRideEvent'
import EditRideEvent from './pages/mgmt/EditRideEvent'
import MyTestPage from './pages/MyTestPage'

function App () {

  const location = useLocation()

  // Array of excluded routes to hide navbar/footer
  const authPageRoutes = ['/sign-in', '/sign-up', '/force-to-get-user-info', '/forgot-password', '/privacy-policy']

  const drawerToggleButton = useRef()

  return (
    // <LocationProvider>
    //   <StravaAuthProvider>
    //     <Router>
    //       <div className="flex flex-col justify-between h-screen">
    //         <Navbar/>
    //         <main className="container mx-auto px-3 pb-12">
    //           <Routes>
    //             <Route path="/" element={<Home/>}/>
    //             <Route path="/about" element={<About/>}/>
    //             <Route path="/ride-event-list" element={<RideEventList/>}/>
    //             <Route path="/ride-event-list/:rideEventId" element={<RideEvent/>}/>
    //             <Route path="/login" element={<Login/>}/>
    //             <Route path="/redirect/exchange_token" element={<StravaRedirect/>}/>
    //             {/*<Route path="/yourdistance" element={<YourDistance />} />*/}
    //             <Route path="/" element={<PrivateRoutes/>}>
    //               <Route path="/secure_page_sample" element={<SecurePageSample/>}/>
    //               <Route path="/my-activities" element={<MyActivities/>}/>
    //             </Route>
    //           </Routes>
    //         </main>
    //         <Footer/>
    //       </div>
    //     </Router>
    //     <ToastContainer/>
    //   </StravaAuthProvider>
    // </LocationProvider>

    <LocationProvider>
      <AuthProvider>
        {/*<DjangoAuthProvider>*/}
        {/*  <StravaAuthProvider>*/}
            {/* Footer가 화면 맨아래 출력되지 위해 화면 위~아래 모두 사용 (h-screen) */}
            {/* todo note: -----------------------------*/}
            {/* todo note: DO NOT TOUCH CLASS */}
            {/* todo note: 최상단 DIV */}
            {/* todo note: -----------------------------*/}
            <div className="drawer">
              <input ref={drawerToggleButton} id="my-drawer" type="checkbox" className="drawer-toggle"/>

              {/* todo note: -----------*/}
              {/* todo note: 내용*/}
              {/* todo note: -----------*/}
              <div className="drawer-content">
                {/* TODO BEST NOTE: InfiniteScroll 구현시 필수항목 */}
                {/*  - 1. 아래 div에서 id="scrollableDiv" 및 "overflow-y-auto" 설정해주어야 함 */}
                {/*  - 2. InfiniteScroll에서는 scrollableTarget="scrollableDiv" 설정해주어야 함 */}
                {/*  - Scroll 작동 유무는 InfiniteScroll에서 onScroll={onScroll} 설정하여 로그를 관찰 */}
                {/*  - 상단 div인 drawer-content으로도 가능하나 페이지의 설정의 자유도를 위해 아래서 따로 구성함. 예를 들어, h-screen을 해야 footer가 맨아래 출력됨 */}
                <div id="scrollableDiv" className="flex flex-col h-screen overflow-y-auto touch-pan-y">

                  {/*<div>*/}
                  {/* Sign-in/Sign-out 페이지에서 Navbar 출력하지 않음 */}
                  {!authPageRoutes.includes(location.pathname) && (
                    <>
                      <Navbar/>
                      {/*<MobileMenu/>*/}
                    </>
                  )}

                  {/* todo note: DO NOT TOUCH CLASS */}
                  {/* todo note: 나중에 mt-18로 변경 */}
                  <main className="container mx-auto">
                    {/* main 엘리먼트 안에 Routes 넣기 */}
                    <Routes>
                      {/*<Route path="/" element={<Home/>}/>*/}
                      <Route path="/" element={<RideEventList/>}/>
                      <Route path="/about" element={<About/>}/>
                      <Route path="/ride-event-list" element={<RideEventList/>}/>
                      <Route path="/ride-event-list/:rideEventId" element={<RideEvent/>}/>
                      {/*<Route path="/login" element={<Login/>}/>*/}
                      <Route path="/redirect/exchange_token" element={<StravaRedirect/>}/>
                      <Route path="/ride-list-page" element={<RideListPage/>}/>
                      {/*<Route path="/yourdistance" element={<YourDistance />} />*/}
                      <Route path="/" element={<PrivateRoutes/>}>
                        <Route path="/secure_page_sample" element={<SecurePageSample/>}/>
                        <Route path="/force-to-get-user-info" element={<ForceToGetUserInfo/>}/>
                        <Route path="/profile" element={<Profile/>}/>
                        <Route path="/strava-activity-submission" element={<StravaActivitySubmission/>}/>
                        <Route path="/strava-resource-test" element={<StravaResoruceTest/>}/>
                        <Route path="/django-resource-test" element={<DjangoResourceTest/>}/>
                        <Route path="/new-ride-event" element={<CreateRideEvent/>}/>
                        <Route path="/edit-ride-event" element={<EditRideEvent/>}/>
                        <Route path="/my-test-page" element={<MyTestPage/>}/>
                      </Route>
                    </Routes>
                  </main>
                  {/*</div>*/}

                  {!authPageRoutes.includes(location.pathname) && (
                    <Footer/>
                  )}
                </div>
              </div>

              {/* todo note: -----------*/}
              {/* todo note: 서랍 메뉴*/}
              {/* todo note: -----------*/}
              <MenuDrawer drawerToggleButton={drawerToggleButton}/>
              {/*<div className="drawer-side">*/}
              {/*  <label htmlFor="my-drawer" className="drawer-overlay"></label>*/}
              {/*  <ul className="menu p-4 w-80 h-full bg-base-200 text-base-content">*/}
              {/*    /!*<li><a>Sidebar Item 1</a></li>*!/*/}
              {/*    /!*<li><a>Sidebar Item 2</a></li>*!/*/}

              {/*    <li>*/}
              {/*      <Link to="/explore-rides" className="btn btn-ghost btn-sm rounded-btn">*/}
              {/*        Rides*/}
              {/*      </Link>*/}
              {/*    </li>*/}
              {/*    <li>*/}
              {/*      <Link to="/explore-clubs" className="btn btn-ghost btn-sm rounded-btn">*/}
              {/*        Clubs*/}
              {/*      </Link>*/}
              {/*    </li>*/}

              {/*  </ul>*/}
              {/*</div>*/}


            </div>
            <ToastContainer/>
          {/*</StravaAuthProvider>*/}
        {/*</DjangoAuthProvider>*/}
      </AuthProvider>
    </LocationProvider>
  )
}

export default App