import React, { useContext, useEffect, useState } from 'react'
import SelectCountry from '../form/SelectCountry'
import Datepicker from 'react-tailwindcss-datepicker'
import { toast } from 'react-toastify'
import { CONST } from '../../constants'
import { updateProfileNew } from '../../utils/djangoApi'
import Spinner from '../layout/Spinner'
import AuthContext from '../../context/auth/AuthContext'
import defaultProfileImg from '../../img/anonymous.png'
// import Datepicker from 'react-tailwindcss-datepicker'
// import DateTimePicker from 'react-datetime-picker'
// import { USER_ACTION_TYPE } from '../../context/user/UserReducer'
// import { updateProfile } from 'firebase/auth'
// import { getKeywords, patchDoc } from '../../services/firebaseDB'
// import { CONST } from '../../constants'
// import { toast } from 'react-toastify'
// import defaultProfileImg from '../../img/anonymous.png'
// import { FaEdit } from 'react-icons/fa'
// import { uploadProfileImg } from '../../services/firebaseStorage'
// import { doc, updateDoc } from 'firebase/firestore'
// import { db } from '../../firebase.config'
// import Spinner from '../layout/Spinner'
// import SelectCountry from '../form/SelectCountry'
// import UserContext from '../../context/user/UserContext'

// -----------------
// REVIEWED
// -----------------

/*
---------- || 사용시 ----------
undefined || : false 이므로 우항 대입
null ||     : false 이므로 우항 대입
'' ||       : false 이므로 우항 대입
[] ||       : true 이므로 그대로 사용
{} ||       : true 이므로 그대로 사용
---------- !! 사용시 ----------
undefined !! : false (boolean 타입으로 변경됨)
null !!     : false (boolean 타입으로 변경됨)
'' !!       : false (boolean 타입으로 변경됨)
[] !!       : true (boolean 타입으로 변경됨)
{} !!       : true (boolean 타입으로 변경됨)
---------- ?? 사용시 undefined, null일 경우만 우항이 대입됨 ----------
undefined ?? : false로 간주되어 우항 대입
null ??     : false로 간주되어 우항 대입
'' ??       : 그대로 사용 <------- todo note: 주의 !!!! &&과 차이나는 부분
[] ??       : 그대로 사용
{} ??       : 그대로 사용

ex) lodash 사용해 체크. 아래는 모두 true
const a = undefined
const b = null
const c = ''
const d = {}
const e = []
console.log("------->>>>>> ", isUndefined(a))
console.log("------->>>>>> ", isNull(b))
console.log("------->>>>>> ", isEmpty(c))
console.log("------->>>>>> ", isEmpty(d))
console.log("------->>>>>> ", isEmpty(e))
*/

function EditUserInfo ({ onSuccess }) {

  const [loading, setLoading] = useState(false)
  const { stravaUser, djangoProfile, saveDjangoProfile } = useContext(AuthContext)

  // const { currentUser, profile, loading, dispatch } = useContext(UserContext)
  // const { djangoTokens, profile, setProfile } = useContext(DjangoAuthContext)

  const [dateRangeForDob, setDateRangeForDob] = useState('')

  console.log('------->>>>>> formData set')
  const [formData, setFormData] = useState({
    sex: '', //<-- todo note: undefined 보다 공백값인 ""으로 설정해야 에러가 나오지 않음
    email: '',
    country_code: '',
    dob: '',
    club: '',
    first_name: '',
    last_name: '',

    // image: '', //<-- 파일
    // photoURL: '', //<-- todo note: 상동
    // country: '',
    // city: '',
    // distanceUnit: '',
  })
  // 쉽게 사용하기 위해 비구조화
  console.log('------->>>>>> formData destructure')
  const { sex, email, country_code, dob, club, first_name, last_name } = formData

  useEffect(() => {
    console.log('--------> [EditUserInfo.useEffect] stravaUser:', stravaUser)
    console.log('--------> [EditUserInfo.useEffect] djangoProfile:', djangoProfile)
    if (stravaUser && djangoProfile) {
      console.log('------->>>>>> SET FORM DATA !!!!!!!!!!!!!!!!!')
      console.log('------->>>>>> djangoProfile.sex:', djangoProfile.sex)
      setFormData({
        ...formData,
        sex: djangoProfile.sex ?? '',
        email: djangoProfile.email ?? '', //<-- 바로 가입 후 온 경우 profile.email은 null임
        country_code: djangoProfile.country_code ?? '',
        dob: djangoProfile.dob ?? '',
        club: djangoProfile.club ?? '',
        first_name: djangoProfile.first_name ?? '',
        last_name: djangoProfile.last_name ?? '',

        // displayName: profile.displayName ?? '',
        // photoURL: profile.photoURL ?? '',
        // country: profile.country ?? '',
        // city: profile.city ?? '',
        // distanceUnit: profile.distanceUnit ?? '',
      })
      setDateRangeForDob({ startDate: djangoProfile.dob, endDate: djangoProfile.dob })
    }
  }, [stravaUser, djangoProfile])
  console.log('------->>>>>> sex:', sex)

  const onSubmit = async (e) => {
    // 이미지를 제외한 입력을 업데이트 (auth 및 profile in DB)
    e.preventDefault()

    console.log('--------> formData:', JSON.stringify(formData, undefined, 2))
    if (!formData.dob) {
      toast.warn('Date of birth is required', { autoClose: CONST.TOAST_AUTO_CLOSE })
      return
    }
    if (!formData.sex) {
      toast.warn('Gender field is required', { autoClose: CONST.TOAST_AUTO_CLOSE })
      return
    }

    try {
      setLoading(true)

      const response = await updateProfileNew(formData)
      saveDjangoProfile(response.data)

      toast.success('Profile updated', { autoClose: CONST.TOAST_AUTO_CLOSE })
      onSuccess()
    } catch (e) {
      console.log(e)
      toast.error('Could not update profile details', { autoClose: CONST.TOAST_AUTO_CLOSE })
    } finally {
      setLoading(false)
    }

  }

  // todo note: 입력창을 위한 universal 핸들러. input의 값이 변경되면 setState에 값을 사용 (BEST !!!)
  const onMutate = (e) => {

    // 입력값이 문자열 'true','false'인 경우 boolean 변수에 타입 저장
    let boolean = null
    if (e.target.value === 'true') {
      boolean = true
    }
    if (e.target.value === 'false') {
      boolean = false
    }

    // 입력이 파일인 경우를 위해 (싱글, 복수의 파일 선택에 상관없이 동일) 멀티파일 선택은 'multiple'을 <input> 엘리먼트에 추가하면 됨
    if (e.target.files) {
      setFormData((prevState) => ({
        ...prevState,
        // image: e.target.files[0], //<-- 하나의 파일만 오므로 하나만 대입시킴
        [e.target.id]: e.target.files[0], //<-- 하나의 파일만 오므로 하나만 대입시킴
      }))
    }

    // note: ??를 사용하면 전자가 null, undefined인 경우에만 후자를 사용
    // Text/Booleans/Numbers 타입 입력인 경우를 위해
    if (!e.target.files) {
      setFormData((prevState) => ({
        ...prevState,
        [e.target.id]: boolean ?? e.target.value,
      }))
    }
  }

  const onDateChange = (dateObj) => {
    console.log('--------> dateObj:', JSON.stringify(dateObj, undefined, 2))
    /*
    dateObj: {
      "startDate": "2000-01-05",
      "endDate": "2000-01-05"
    }
     */
    setDateRangeForDob(dateObj)
    setFormData((prevState) => ({
      ...prevState,
      dob: dateObj.startDate
    }))
  }

  // const onUpdatePhoto = async (e) => {
  //   dispatch({ type: USER_ACTION_TYPE.SET_LOADING })
  //
  //   const fileObj = e.target.files[0]
  //   try { // 파일 업로드 후 url 받기
  //     // const dstFilePath = `images/${auth.currentUser.uid}-${fileObj.name}-${uuidv4()}`
  //     const photoURL = await uploadProfileImg(currentUser.uid, fileObj)
  //     console.log('photoURL:', photoURL)
  //
  //     // photoURL state 업데이트하여 현재 페이지의 사진을 업데이트
  //     setFormData({
  //       ...formData,
  //       photoURL,
  //     })
  //
  //     // 1. Update auth (user) in firebase
  //     // todo note: 참고로 auth 업데이트시
  //     //  - auth의 필드는 한정되어 있음
  //     //  - 새로 생성할 수 없음
  //     //  - 업데이트 가능한 필드가 따로 있음 (displayName, photoURL 등등)
  //     await updateProfile(currentUser, {
  //       // todo note: 이 함수는 displayName, photoURL만 업데이트 가능
  //       photoURL,
  //     })
  //
  //     // 2. Update DB in firestore
  //     const userRef = doc(db, CONST.COLLECTION.USERS, currentUser.uid)
  //     await updateDoc(userRef, {
  //       photoURL,
  //     })
  //
  //     // 3. 업데이트 context
  //     dispatch({
  //       type: USER_ACTION_TYPE.GET_PROFILE, payload: {
  //         ...profile,
  //         photoURL,
  //       }
  //     })
  //
  //     toast.success('Photo has been updated', { autoClose: 1000 })
  //   } catch (e) {
  //     console.log(e)
  //     toast.error('Failed to update photo', { autoClose: 1000 })
  //     if (loading) {
  //       dispatch({ type: USER_ACTION_TYPE.STOP_LOADING })
  //     }
  //   }
  // }

  if (loading) {
    return <Spinner/>
  }

  return (
    <>
      {/* ---------------- */}
      {/* profile edit form */}
      {/* ---------------- */}
      {/*<form className="flex flex-col mt-10 space-y-5" onSubmit={onSubmit}>*/}

      <div className="flex my-5">
        <img
          className="rounded-full"
          src={stravaUser.profile || defaultProfileImg}
          alt="Avatar"
        />
      </div>

      <form className="page-form" onSubmit={onSubmit}>

        {/* first_name */}
        <div className="form-field">
          <label className="form-label">First Name</label>
          <input id="first_name"
                 type="text"
                 placeholder="First Name"
                 className="input input-bordered w-full"
                 value={first_name}
                 onChange={onMutate}
                 required
          />
        </div>

        {/* last_name */}
        <div className="form-field">
          <label className="form-label">Last Name</label>
          <input id="last_name"
                 type="text"
                 placeholder="Last Name"
                 className="input input-bordered w-full"
                 value={last_name}
                 onChange={onMutate}
                 required
          />
        </div>

        {/* club */}
        <div className="form-field">
          <label className="form-label">Club</label>
          <input id="club"
                 type="text"
                 placeholder="Club"
                 className="input input-bordered w-full"
                 value={club}
                 onChange={onMutate}
                 required
          />
        </div>

        {/* email */}
        <div className="form-field">
          <label className="form-label">Email</label>
          <input id="email"
                 type="email"
                 placeholder="Email"
                 className="input input-bordered w-full"
                 value={email}
                 onChange={onMutate}
                 required
          />
        </div>

        {/* country_code */}
        <div className="form-field">
          <label className="form-label">Country</label>
          {/* get full list from https://github.com/michaelwittig/node-i18n-iso-countries */}
          <SelectCountry
            id="country_code"
            className="select select-bordered w-full"
            onChange={onMutate}
            value={country_code}
            required
          >
          </SelectCountry>
        </div>


        {/* sex */}
        <div className="form-field">
          <label className="form-label">Gender</label>
          <div className="btn-group btn-group-horizontal">
            {Object.values(CONST.SEX).map((v) => (
              <button
                key={v}
                className={sex === v ? 'border-neutral-300 btn btn-active btn-outline' : 'border-neutral-300 btn btn-outline'}
                type="button"
                id="sex"
                value={v}
                onClick={onMutate}
              >
                {v}
              </button>
            ))
            }
          </div>
        </div>

        {/* dob /}
        {/* For Date or Date Range - 2023 */}
        {/* note: value의 값이 항상 {"startDate": "2000-01-05","endDate": "2000-01-05"} 형식이지만 뷰 때문에 그냥 사용 */}
        {/* https://react-tailwindcss-datepicker.vercel.app/install */}
        {/* https://beatsoft.co/react-tailwindcss-datepicker-step-by-step/ */}
        <div className="form-field">
          <label className="form-label">Date of Birth</label>
          <Datepicker
            containerClassName="border border-neutral-300 rounded-lg"
            startFrom={new Date('2000-01-01')}
            useRange={false}
            asSingle={true}
            value={dateRangeForDob}
            onChange={onDateChange}
          />
        </div>

        {/*  SUBMIT */}
        <button type="submit" className="btn btn-primary mt-5">Update</button>
      </form>
    </>

    // <>
    //   {/* Avatar REF: https://www.material-tailwind.com/docs/html/avatar */}
    //   {/* Replace Input type FILE by Icon: https://www.youtube.com/watch?v=dvyvikylNmI*/}
    //   {/* todo best note: File 입력 대신 사진 클릭시 파일 입력을 받도록 함.
    //     * 1. <label>에서 file input 대신 사용될 수 있는 'for'를 제공
    //     * 2. <label>이 아닌 <img> 클릭시 파일 입력을 받고자 하므로 <label>을 <img> 상단에 위치시키도록 함
    //     */}
    //   <div className="relative">
    //     <img
    //       className="object-cover h-24 w-24 mt-5 rounded-full"
    //       src={photoURL || defaultProfileImg}
    //       alt="Avatar"
    //     />
    //     <FaEdit className="absolute top-5 right-0.5 text-2xl text-cyan-900/70"/>
    //     <label className="absolute inset-0 cursor-pointer" htmlFor="image"/>
    //   </div>
    //   <input
    //     className="hidden"
    //     type="file"
    //     id="image"
    //     onChange={onUpdatePhoto}
    //     accept=".jpg,.png,.jpeg"
    //   />
    //
    //   {/* ---------------- */}
    //   {/* User edit form */}
    //   {/* ---------------- */}
    //   {/*<form className="flex flex-col mt-10 space-y-5" onSubmit={onSubmit}>*/}
    //   <form className="page-form" onSubmit={onSubmit}>
    //
    //     {/* DISPLAY NAME */}
    //     <div className="form-field">
    //       <label className="form-label">Display Name</label>
    //       <input id="displayName"
    //              type="text"
    //              placeholder="Display Name"
    //              className="input input-bordered w-full"
    //              value={displayName}
    //              onChange={onMutate}
    //              required
    //       />
    //     </div>
    //
    //     {/* COUNTRY */}
    //     <div className="form-field">
    //       <label className="form-label">Country</label>
    //       {/* get full list from https://github.com/michaelwittig/node-i18n-iso-countries */}
    //       <SelectCountry
    //         id="country"
    //         className="select select-bordered w-full"
    //         onChange={onMutate}
    //         value={country}
    //         required
    //       >
    //       </SelectCountry>
    //     </div>
    //
    //     {/* CITY */}
    //     <div className="form-field">
    //       <label className="form-label">City</label>
    //       <input id="city"
    //              type="text"
    //              placeholder="City"
    //              className="input input-bordered w-full"
    //              value={city}
    //              onChange={onMutate}
    //              required
    //       />
    //     </div>
    //
    //     {/* DISTANCE UNIT */}
    //     <div className="form-field">
    //       <label className="form-label">Distance Unit</label>
    //       <div className="btn-group btn-group-horizontal">
    //         {Object.values(CONST.DISTANCE_UNIT).map((v) => (
    //           <button
    //             key={v}
    //             className={distanceUnit === v ? 'btn btn-active btn-outline' : 'btn btn-outline'}
    //             type="button"
    //             id="distanceUnit"
    //             value={v}
    //             onClick={onMutate}
    //           >
    //             {v}
    //           </button>
    //         ))
    //         }
    //       </div>
    //     </div>
    //
    //     {/*  SUBMIT */}
    //     <button type="submit" className="btn btn-primary">Update</button>
    //   </form>
    // </>
  )
}

export default EditUserInfo