import { MdDirectionsBike } from 'react-icons/md'
import { CONST } from '../../constants'

function Footer() {
  const footerYear = new Date().getFullYear()

  return (
    <footer className="flex flex-col footer-center mt-10 mb-32 italic">
      <div className="flex items-center justify-center space-x-2">
        <MdDirectionsBike className="text-lg"/>
        <p className="font-bold">{CONST.APP_NAME}</p>
      </div>
      <div>
        <p className="text-sm">Copyright &copy; {footerYear} All rights reserved</p>
      </div>
      <a className="cursor-pointer text-sm"
         onClick={() => {window.location.href = `mailto:${CONST.SUPPORT_EMAIL}`}}>{CONST.SUPPORT_EMAIL}</a>
    </footer>
  )
}

export default Footer