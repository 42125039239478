import { BsDot } from 'react-icons/bs'
import { useContext, useState } from 'react'
// import StravaAuthContext from '../../context/strava_auth/StravaAuthContext'
import { useNavigate } from 'react-router-dom'
import {
  getBadgeByStatus,
  // getBadgeByStatus,
  getCountryName,
  getDistanceKmMiles,
  getFormattedDate1,
  getFormattedDate2, getFormattedDate3
} from '../../utils/commonUtils'
import { CONST } from '../../constants'
import FlagImg from '../img/FlagImg'
import NoImage from '../layout/NoImage'
import { defaultImage } from '../img/defaultImages'

/* rideEvent sample:
{
  "id": 31,
  "name": "Multi-layered motivating model",
  "status": "OPEN",
  "distance_meter": 600000,
  "image": "https://picsum.photos/1280/720",
  "image_thumbnail": "https://picsum.photos/1280/720",
  "course_url": "https://schwartz.com/blog/search/wp-contentmain.php",
  "chat_url": "https://davis.com/category/categoriesindex.htm",
  "description": "Reverse-engineered exuding open architecture",
  "country_code": "KR",
  "cities": [
    "Johor Bahru",
    "Singapore"
  ],
  "start_at": "2023-10-01T13:07:54Z",
  "end_at": "2023-10-14T00:27:43Z",
  "rider_count": 81,
  "created_at": "2023-09-20T10:36:41.472080Z",
  "updated_at": "2023-09-20T10:36:41.472083Z"
}
 */
function RideEventItem ({ rideEvent, onClickRideEvent }) {

  return (
    // <div className="flex flex-col space-y-1.5 border-b-2 py-3">
    <div className="flex flex-col border-b-2 py-3 cursor-pointer" onClick={() => onClickRideEvent(rideEvent.id)}>

      {/*<p className="font-bold truncate bg-red-300">{rideEvent.name}</p>*/}
      {/*<p className="font-bold bg-red-300">{rideEvent.name}</p>*/}

      {/* todo note: (BEST) truncate에서 스크린을 넘어가면 %로 조정 */}
      <p className="truncate font-bold">{rideEvent.name}</p>

      {/* parent row 1 */}
      {/*<div className="ride-item-row">*/}
      <div className="flex space-x-2">

        {/* --------- */}
        {/* 좌측 이미지 */}
        {/* --------- */}
        {/* note: 상단 div에 width 설정하고 내부는 w-full로 해야함 !! */}
        {/* note: height를 설정하려면 <img>의 className에서 height를 설정 */}
        {/* https://tailwindcss.com/docs/object-fit */}
        {/* todo note: (BEST) flex-none 으로 왼쪽 공간 고정 */}
        <div className="flex-none relative w-1/3">
          <img
            className="w-full object-cover rounded-xl"
            src={rideEvent.image_thumbnail || defaultImage.rideEventListDefaultImg}
            alt="Image"
            onError={(e) => {
              // todo note: (BEST) image 로드시 404 에러가 나오는 경우 기본 이미지 설정
              e.target.src = defaultImage.rideEventListDefaultImg
            }}
          />
          <div className="absolute top-1 left-1">
            {/*<span className={`badge ${getBadgeByStatus(rideEvent.status)} p-2`}>{rideEvent.status}</span>*/}
            <span className={getBadgeByStatus(rideEvent.status)}>{rideEvent.status}</span>
            {/*<span className={`badge-${rideEvent.status}`}>{rideEvent.status.toUpperCase()}</span>*/}
            {/*<span className="upcoming">{rideEvent.status}</span>*/}
            {/*{rideEvent.status && (*/}
            {/*  // <span className={`badge-${rideEvent.status.toLowerCase()}`}>{rideEvent.status}</span>*/}
            {/*  // <span className={`badge-upcoming`}>{rideEvent.status}</span>*/}
            {/*)}*/}
          </div>
        </div>

        {/* --------- */}
        {/* 우측 내용 */}
        {/* --------- */}
        {/*<div className="flex flex-col space-y-0 w-2/3">*/}
        <div className="flex flex-col space-y-0">
          {/* row 1 */}
          {/*<div className=" ride-item-row  bg-red-300">*/}
          {/*  /!*<p className="w-3/5 font-bold flex-wrap">{rideEvent.name}</p>*!/*/}
          {/*  /!*<p className="w-[70%] font-bold truncate">{rideEvent.name}</p>*!/*/}
          {/*  <p className="w-[60%] font-bold flex-wrap">{rideEvent.name}</p>*/}
          {/*  /!*<p className="w-3/5 font-bold flex-wrap">xxx</p>*!/*/}
          {/*</div>*/}
          {/* row 2 */}
          <div className="ride-item-row space-x-1">
            <span
              className="text-2xl font-bold">{getDistanceKmMiles(rideEvent.distance_meter, CONST.DISTANCE_UNIT.KM, 0)}</span>
            <span>{CONST.DISTANCE_UNIT.KM}</span>
            {rideEvent.status !== CONST.RIDE_EVENT_STATUS.UPCOMING && (
              <>
                <BsDot/>
                <span className="text-2xl font-bold">{rideEvent.rider_count}</span>
                <span>Riders</span>
              </>
            )}
          </div>
          {/* row 3 */}
          <div className="ride-item-row">
            {/* flag images: https://github.com/emcrisostomo/flags */}
            <FlagImg country_code={rideEvent.country_code} className="w-5 shadow"/>
            <span className="">{getCountryName(rideEvent.country_code)}</span>
            {/*{rideEvent.cities && (*/}
            {/*  <>*/}
            {/*    <BsDot/>*/}
            {/*    <p className="w-2/3 truncate">{rideEvent.cities}</p>*/}
            {/*    /!*<p className="">{rideEvent.cities.slice(0, 10)}</p>*!/*/}
            {/*    /!*{rideEvent.cities.length > 10 && '...'}*!/*/}
            {/*  </>*/}
            {/*)}*/}
          </div>
          <div className="ride-item-row">
            {rideEvent.cities && (
                <p className="text-sm italic">{rideEvent.cities}</p>
            )}
          </div>
        </div>
      </div>

      {/* parent row 2 */}
      <div className="flex justify-between text-sm italic">
        <div className="">
          <span>{getFormattedDate1(rideEvent.start_at)}</span> ~ <span>{getFormattedDate2(rideEvent.end_at)}</span>
        </div>
        {/*<div>*/}
          <span>{getFormattedDate3(rideEvent.created_at)}</span>
        {/*</div>*/}
      </div>

    </div>
  )
}

export default RideEventItem