import React, { useEffect, useState } from 'react'
import _, { truncate } from 'lodash'
import { getSpeedKphMphByCountryCode, getSpeedKphMphStrByCountryCode } from '../../utils/commonUtils'
import { Link } from 'react-router-dom'
import { MdOutlineMoreHoriz } from 'react-icons/md'
import FlagImg from '../img/FlagImg'
import defaultProfileImg from '../../img/anonymous.png'

/* rideRecords:
  {
    "id": 99,
    "strava_activity_id": 9566349616,
    "distance": 1684.5,
    "moving_time": 19600,
    "elapsed_time": 15051,
    "elapsed_time_hour": 4.2,
    "start_date_local": "2023-09-27T16:24:42Z",
    "average_speed": 7.932,
    "team": null,
    "club": "WCR",
    "rider_username": "rider_user_98",
    "rider_sex": "MALE",
    "rider_country_code": "MY",
    "rider_full_name": "Gregory Berger",
    "rider_age_cat": 45
  },
 */
function RiderListTable ({ rideRecords, size, geoLocationCountry }) {

  // todo here - top elapse 10, strava profile link, image upload, comments
  // todo here - top elapse 10, strava profile link, image upload, comments
  // todo here - top elapse 10, strava profile link, image upload, comments

  const [data, setData] = useState()

  useEffect(() => {
    if (rideRecords && rideRecords.length > 0) {
      const sortedRiders = _(rideRecords)
        .sortBy('elapsed_time')
        .slice(0, size || rideRecords.length)
        .value()
      // console.log('--------> sortedRiders:', JSON.stringify(sortedRiders, undefined, 2))
      setData(sortedRiders)
    }
  }, [rideRecords])

  if (!data) {
    return null
  }

  return (
    <>
      <div className="flex justify-between items-center">
        <h1 className="chart-title">Rider List</h1>
        {size && (
          <div>
            <Link to="/ride-list-page" state={{ rideRecords, geoLocationCountry }}>
              {/*<MdOutlineMoreHoriz className="text-2xl"/>*/}
              <span>more</span>
            </Link>
          </div>
        )}
      </div>
      <div className="mt-5">
        <table className="table table-xs">
          <thead>
          <tr>
            <th></th>
            <th>Name</th>
            {/*<th></th>*/}
            <th>Team</th>
            <th>Finish</th>
            <th>AVG Pace</th>
          </tr>
          </thead>
          <tbody>
          {data.map((rider, idx) => (
            <tr key={rider.id}>
              <th>{idx + 1}</th>
              <td>
                <div className="flex space-x-2">
                  <img
                    className="rounded-full w-5 h-5"
                    src={rider.rider_strava_profile_medium || defaultProfileImg}
                    alt="Avatar"
                  />

                  {/* flag images: https://github.com/emcrisostomo/flags */}
                  <span>{truncate(rider.rider_full_name, {'length':15, 'omission':'..'})}</span>
                </div>
              </td>
              {/*<td>*/}
              {/*  <FlagImg country_code={rider.rider_country_code} className="w-5 shadow"/>*/}
              {/*</td>*/}
              <td>{rider.team}</td>
              <td>{rider.elapsed_time_hour}h</td>
              <td>{getSpeedKphMphStrByCountryCode(rider.average_speed, geoLocationCountry)}</td>
            </tr>
          ))}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default RiderListTable