import { createContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { CONST } from '../../constants'

const AuthContext = createContext()

export const AuthProvider = ({ children }) => {

  const navigate = useNavigate()

  const [stravaUser, setStravaUser] = useState(() => {
    // todo note: 최초 AuthContext 로드시에 초기값 설정. 즉, 최초 1회만 실행)
    const stravaUser = localStorage.getItem(CONST.LOCAL_STORAGE_KEY.STRAVA_USER)//<-- todo note: localStorage에서 가져오는 값은 무조건 문자열
    // console.log('[AuthProvider] stravaUser from local storage: ', stravaUser)
    if (stravaUser) {
      return JSON.parse(stravaUser) //<-- todo note: 문자열을 파싱하여 값/객체/배열로 변경
    }
    return null
  })

  /*Sample of stravaUser:
  {
    "id": 89937081, <---- strava profile 링크 생성에 사용
    "profile_medium": "https://dgalywyr863hv.cloudfront.net/pictures/athletes/89937081/24071241/1/medium.jpg",
    "profile": "https://dgalywyr863hv.cloudfront.net/pictures/athletes/89937081/24071241/1/large.jpg",
  }
   */

  const [djangoProfile, setDjangoProfile] = useState(() => {
    // todo note: 최초 AuthContext 로드시에 초기값 설정. 즉, 최초 1회만 실행)
    const djangoProfile = localStorage.getItem(CONST.LOCAL_STORAGE_KEY.DJANGO_PROFILE)//<-- todo note: localStorage에서 가져오는 값은 무조건 문자열
    // console.log('[AuthProvider] djangoProfile from local storage: ', djangoProfile)
    if (djangoProfile) {
      return JSON.parse(djangoProfile) //<-- todo note: 문자열을 파싱하여 값/객체/배열로 변경
    }
    return null
  })

  /*Sample of djangoProfile:
  {
      "id": 103,
      "sex": "MALE",
      "email": "asdf@asdf.com",
      "country_code": "AS",
      "dob": "2000-01-01",
      "club": "asdf",
      "username": "yhojung",
      "first_name": "aaa", <-- SSO 로그인시 자동 설정됨
      "last_name": "bbb", <-- SSO 로그인시 자동 설정됨
      "is_staff": "True"
  }
   */

  const saveDjangoProfile = (data) => {
    setDjangoProfile(data)
    localStorage.setItem(CONST.LOCAL_STORAGE_KEY.DJANGO_PROFILE, JSON.stringify(data))
  }

  // todo note: 여기서 로그인 처리하지 않음. 왜? 로그인을 위해 useContext로 불러들여야 하기 때문. service 호출이 더 쉬움
  // const login = () => {
  //   console.log("------->>>>>> [AuthProvider] login() called")
  //   const { REACT_APP_CLIENT_ID, REACT_APP_REDIRECT_URL } = process.env
  //   window.location = `http://www.strava.com/oauth/authorize?client_id=${REACT_APP_CLIENT_ID}&response_type=code&redirect_uri=${REACT_APP_REDIRECT_URL}/exchange_token&approval_prompt=force&scope=activity:read`
  // }

  const logout = () => {
    console.log("------->>>>>> [AuthProvider] logout() called")
    setStravaUser(null)
    localStorage.removeItem(CONST.LOCAL_STORAGE_KEY.STRAVA_USER)
    localStorage.removeItem(CONST.LOCAL_STORAGE_KEY.STRAVA_TOKENS)
    setDjangoProfile(null)
    localStorage.removeItem(CONST.LOCAL_STORAGE_KEY.DJANGO_PROFILE)
    localStorage.removeItem(CONST.LOCAL_STORAGE_KEY.DJANGO_TOKENS)
    navigate("/")
  }

  // const isLoggedIn = () => {
  //   console.log("------->>>>>> [AuthProvider] isLoggedIn() called")
  //   if (!djangoProfile) {
  //     console.log("------->>>>>> [AuthProvider] djangoProfile is falsey")
  //     return false
  //   }
  //   // djangoProfile의 값이 있으나 property의 값이 없는 것이 있으면 force 페이지로 이동
  //   const profileValues = Object.values(djangoProfile)
  //   for (const profileVal of profileValues) {
  //     if (!profileVal) {
  //       console.log("------->>>>>> [AuthProvider] profileVal is false. profileVal:", profileVal)
  //       navigate("/force-to-get-user-info")
  //       return
  //     }
  //   }
  //   navigate("/")
  // }

  return (
    <AuthContext.Provider
      value={{
        stravaUser,
        setStravaUser,
        djangoProfile,
        saveDjangoProfile,
        // login,
        logout,
        // isLoggedIn,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContext
