import { FaBars, FaGithub } from 'react-icons/fa'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'
// import StravaAuthContext from '../../context/strava_auth/StravaAuthContext'
// import { STRAVA_AUTH_ACTION_TYPE } from '../../context/strava_auth/StravaAuthReducer'
// import { stravaLogin } from '../../context/strava_auth/StravaAuthActions'
// import LocationContext from '../../context/location/LocationContext'
import { MdArrowBackIosNew } from 'react-icons/md'
import { CONST } from '../../constants'
import AuthContext from '../../context/auth/AuthContext'

function Navbar ({ title }) {

  const { djangoProfile } = useContext(AuthContext)

  // const { geoLocationCountry, setCountryCode } = useContext(LocationContext)
  // const { userInfo, dispatch } = useContext(StravaAuthContext)
  const navigate = useNavigate()

  // Home 페이지인 경우 navbar에서 뒤로가기 아이콘을 숨기기
  const [isHomePage, setIsHomePage] = useState(false)
  const location = useLocation()

  useEffect(() => {
    if (location?.pathname === '/' || location?.pathname === '/home') {
      setIsHomePage(true)
    } else {
      setIsHomePage(false)
    }
  }, [location])

  // const onLogin = async () => {
  //   console.log('------->>>>>> onLogin !!!')
  //   await stravaLogin()
  // }

  // const onLogout = () => {
  //   console.log('------->>>>>> onLogout !!!')
  //   dispatch({type: STRAVA_AUTH_ACTION_TYPE.LOGOUT_RESET})
  //   navigate("/")
  // }

  return (
    // todo note: youtube와 같이 fixed를 사용해 상단을 고정하는 경우 내용부분이 일정하게 드래그되지 않았음. 그냥 fixed하지 않음
    // <div className="fixed navbar shadow-lg bg-neutral text-neutral-content z-10">
    <div className="flex navbar shadow-lg bg-neutral text-neutral-content">

      {/* Go Back */}
      <div className="h-full w-1/5 px-1" onClick={() => navigate(-1)}>
        {!isHomePage && (
          <MdArrowBackIosNew className="text-2xl"/>
        )}
      </div>


      {/* App name */}
      <div className="flex-1 justify-center">
        <Link to="/">
          <p className="btn btn-ghost normal-case text-xl">{title}</p>
        </Link>
      </div>

      {/*/!* 웹: 우측 링크 메뉴 (flex-1으로 모두 채우기) *!/*/}
      {/*<div className="hidden px-2 mx-2 md:flex">*/}
      {/*  <div className="flex justify-end">*/}
      {/*    <Link to="/" className="btn btn-ghost btn-sm rounded-btn">*/}
      {/*      Home*/}
      {/*    </Link>*/}
      {/*    <Link to="/explore-rides" className="btn btn-ghost btn-sm rounded-btn">*/}
      {/*      Rides*/}
      {/*    </Link>*/}
      {/*    <Link to="/explore-clubs" className="btn btn-ghost btn-sm rounded-btn">*/}
      {/*      Clubs*/}
      {/*    </Link>*/}
      {/*    <Link to="/profile" className="btn btn-ghost btn-sm rounded-btn">*/}
      {/*      Profile*/}
      {/*    </Link>*/}
      {/*    <Link to="/sign-in" className="btn btn-ghost btn-sm rounded-btn">*/}
      {/*      Sign In*/}
      {/*    </Link>*/}
      {/*  </div>*/}
      {/*</div>*/}


      {/* 필터 출력 버튼 */}
      <div className="flex justify-end w-1/5">
        <label htmlFor="my-drawer" className="drawer-button p-3">
          {djangoProfile ? (
            <FaBars className="text-2xl text-green-300"/>
          ) : (
            <FaBars className="text-2xl text-red-200"/>
          )}
        </label>
      </div>


      {/* 우측 로그인 버튼 또는 프로파일 이미지 */}
      {/*{!loading && (*/}
      {/*  <div className="flex mr-3">*/}
      {/*    {profile.id ? (*/}
      {/*      <Link to="/profile">*/}
      {/*        <img*/}
      {/*          className="h-10 w-10 rounded-full"*/}
      {/*          src={photoURL || defaultProfileImg}*/}
      {/*          alt="Avatar"*/}
      {/*        />*/}
      {/*      </Link>*/}
      {/*    ) : (*/}
      {/*      <button className="py-1 px-2 rounded-lg border border-gray-400 hover:bg-gray-500"*/}
      {/*              onClick={() => navigate('/sign-in')}>Sign In</button>*/}
      {/*    )}*/}
      {/*  </div>*/}
      {/*)}*/}

    </div>
  )

  // return (
  //   <nav className="navbar mb-12 shadow-lg bg-neutral text-neutral-content">
  //     <div className="container mx-auto">
  //
  //       {/* 좌측 로고 및 타이틀 */}
  //       <div className="flex-none px-2 mx-2">
  //         <FaGithub className="inline pr-2 text-3xl"/>
  //         <Link to="/" className="text-lg font-bold align-middle">
  //           {title}
  //         </Link>
  //       </div>
  //
  //       {/*  우측 링크 메뉴 (flex-1으로 모두 채우기) */}
  //       <div className="flex-1 px-2 mx-2">
  //         <div className="flex justify-end">
  //           {userInfo && (
  //             <div className="font-bold mr-8">
  //               <span>Welcome, {userInfo.firstname}</span>
  //             </div>
  //           )}
  //           <Link to="/" className="btn btn-ghost btn-sm rounded-btn">
  //             Home
  //           </Link>
  //           <Link to="/ride-event-list" className="btn btn-ghost btn-sm rounded-btn">
  //             RideEvents
  //           </Link>
  //           <Link to="/secure_page_sample" className="btn btn-ghost btn-sm rounded-btn">
  //             SecurePageSample
  //           </Link>
  //           <Link to="/about" className="btn btn-ghost btn-sm rounded-btn">
  //             About
  //           </Link>
  //           <Link to="/lodash-examples" className="btn btn-ghost btn-sm rounded-btn">
  //             LodashExamples
  //           </Link>
  //           <Link to="/my-activities" className="btn btn-ghost btn-sm rounded-btn">
  //             MyActivities
  //           </Link>
  //           {userInfo ? (
  //             <div>
  //               <a className="btn btn-ghost btn-sm rounded-btn" onClick={onLogout}>Logout</a>
  //             </div>
  //           ) : (
  //             <div>
  //               <a className="btn btn-ghost btn-sm rounded-btn" onClick={onLogin}>Login</a>
  //             </div>
  //           )}
  //         </div>
  //       </div>
  //
  //     </div>
  //   </nav>
  // )
}

Navbar.defaultProps = {
  title: CONST.APP_NAME,
}

export default Navbar