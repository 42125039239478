import { useEffect } from 'react'
import { fetchProfileNew } from '../utils/djangoApi'

function DjangoResourceTest () {

  useEffect(() => {
    const getProfile = async () => {
      try {
        const response = await fetchProfileNew()
        console.log('------->>>>>> [getProfile] response:', response)
      } catch (e) {
        console.log('------->>>>>> error. e:', e)
        //   token refresh 시도 후에도 실패하면 여기로 옴

      }
    }
    getProfile()
  }, [])

  return (
    <div>
      DjangoResourceTest_Page
    </div>
  )
}

export default DjangoResourceTest