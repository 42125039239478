import { useEffect, useState } from 'react'
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'
import _ from 'lodash'
import { CONST } from '../../../constants'

// Recharts
// https://github.com/recharts/recharts <---- github
// https://recharts.org/en-US/examples <---- examples
// https://blog.logrocket.com/top-8-react-chart-libraries/ <--- other charts

function VerticalBarChartForCount ({ data, color, xKey, yKey }) {
  // https://codesandbox.io/s/recharts-horizontal-bar-with-dual-y-axis-25v91?file=/src/index.js:1765-1777

  return (
    <ResponsiveContainer width="100%" height={40 * data.length} debounce={50}>
      {/* Recharts options */}
      {/* https://recharts.org/en-US/examples/CustomContentOfTooltip */}
      <BarChart
        // width={500}
        // height={300}
        data={data}
        margin={{
          top: 0,
          right: 0,
          left: 30,
          bottom: 0,
        }}
        layout="vertical"
      >
        {/*<CartesianGrid strokeDasharray="3 3"/>*/}
        <XAxis hide
               axisLine={false}
               type="number"
               // padding={{ left: 10, right: 10 }}
        />
        <YAxis dataKey={yKey}
               type="category"
               axisLine={false}
               tickLine={false}
               fontSize={CONST.BAR_CHART.FONT_SIZE}
               padding={{ top: 5, bottom: 20 }}
        />
        {/*<XAxis type="number"*/}
        {/*       width={15}*/}
        {/*  // tickFormatter={(value) => `${_.round(value / 60 / 60, 2)}\nhrs`}*/}
        {/*  // domain={[*/}
        {/*  //   Math.min(...top10.map(item => item.elapsed_time)) * 0.95,*/}
        {/*  //   Math.max(...top10.map(item => item.elapsed_time)),*/}
        {/*  // ]}*/}
        {/*       fontSize={CONST.BAR_CHART.FONT_SIZE}*/}
        {/*/>*/}
        {/*<Legend/>*/}
        <Tooltip/>
        {/*<Tooltip formatter={(value, name, props) => `${shortEnglishHumanizer(value * 1000)}`}*/}
        {/*         labelFormatter={value => `${value}`}*/}
        {/*/>*/}
        <Bar dataKey={xKey} fill={color}/>
      </BarChart>
    </ResponsiveContainer>

  )
}

export default VerticalBarChartForCount