import spinner from './assets/spinner.gif'

function Spinner ({size}) {
  return (
    <img
      width={size || 100}
      className="text-center mx-auto"
      src={spinner}
      alt="Loading..."
    />
  )
}

export default Spinner
