import CommentItem from './CommentItem'
import { useState } from 'react'
import CommentForm from './CommentForm'

function CommentList ({ comments, level, login_user_rider_id, onCreateComment, onDeleteComment }) {

  return (
    <>
      {comments.length > 0 && comments.map((comment) => (
        <div key={comment.id}>
          {/*<div className={`ml-[${level}%]`}>*/}
          <div className={level > 1 ? 'ml-[10%]' : ''}>
            <CommentItem key={comment.id}
                         comment={comment}
                         level={level}
                         login_user_rider_id={login_user_rider_id}
                         onCreateComment={onCreateComment}
                         onDeleteComment={onDeleteComment}
            />
          </div>
        </div>
      ))}

    </>
  )
}

export default CommentList