import axios from 'axios'
import { CONST } from '../../constants'

const djangoApiInterceoptor = axios.create({})

djangoApiInterceoptor.interceptors.request.use(
  (config) => { //<-- 1st arg
    const tokensData = JSON.parse(localStorage.getItem(CONST.LOCAL_STORAGE_KEY.DJANGO_TOKENS))
    config.headers.setAuthorization(`Bearer ${tokensData.access}`)
    return config
  }
)

djangoApiInterceoptor.interceptors.response.use(
  (response) => { //<-- 1st arg
    return response
  },
  async (error) => { //<-- 2nd arg
    if (error.response.status === 401) {
      const tokensData = JSON.parse(localStorage.getItem(CONST.LOCAL_STORAGE_KEY.DJANGO_TOKENS))
      const apiResponse = await axios.post(
        `${process.env.REACT_APP_DJANGO_HOST}/token/refresh/`,
        {
          refresh: tokensData.refresh,
        },
      )
      localStorage.setItem(CONST.LOCAL_STORAGE_KEY.DJANGO_TOKENS, JSON.stringify({
        refresh: apiResponse.data.refresh,
        access: apiResponse.data.access,
      }))
      error.config.headers.setAuthorization(`Bearer ${apiResponse.data.access}`)
      return axios(error.config)
    } else {
      return Promise.reject(error)
    }
  }
)

export default djangoApiInterceoptor