import {
  FaSignInAlt,
  FaBars,
  FaUserFriends,
  FaCalendarCheck,
  FaUserCircle,
  FaSignOutAlt,
  FaHome,
  FaBiking
} from 'react-icons/fa'
import { toast } from 'react-toastify'
import { Link, useNavigate } from 'react-router-dom'
import React, { useContext, useEffect, useState } from 'react'
import { CONST } from '../../constants'
// import UserContext from '../../context/user/UserContext'
import { isUndefined } from 'lodash'
// import { signOut } from 'firebase/auth'
// import { auth } from '../../firebase.config'
// import { toast } from 'react-toastify'
import { MdPersonAdd } from 'react-icons/md'
import defaultProfileImg from '../../img/anonymous.png'
// import StravaAuthContext from '../../context/strava_auth/StravaAuthContext'
// import { stravaLogin } from '../../context/strava_auth/StravaAuthActions'
import { STRAVA_AUTH_ACTION_TYPE } from '../../context/strava_auth/StravaAuthReducer'
import NoImage from './NoImage'
import LocationContext from '../../context/location/LocationContext'
import SelectCountry from '../form/SelectCountry'
import AuthContext from '../../context/auth/AuthContext'
import { defaultImage } from '../img/defaultImages'
import { newLogin } from '../../utils/stravaApi'


function MenuDrawer ({ drawerToggleButton }) {

  const { stravaUser, djangoProfile, logout } = useContext(AuthContext)





  // const { currentUser, profile } = useContext(UserContext)
  // const { userInfo, dispatch } = useContext(StravaAuthContext)
  const { geoLocationCountry, setCountryCode } = useContext(LocationContext)

  const navigate = useNavigate()

  const [country, setCountry] = useState('')

  useEffect(() => {
    if (geoLocationCountry) {
      setCountry(geoLocationCountry)
    }
  }, [geoLocationCountry])

  const onChange = (e) => {
    console.log('--------> e.target.value:', e.target.value)
    setCountryCode(e.target.value)
    navigate('/')
    drawerToggleButton.current.click()
  }

  const onLogin = async () => {
    console.log('------->>>>>> onLogin !!!')
    // await login()
    newLogin()
  }

  const onLogout = () => {
    console.log('------->>>>>> onLogout !!!')
    logout()
    // dispatch({type: STRAVA_AUTH_ACTION_TYPE.LOGOUT_RESET})
    // navigate('/')
    // drawerToggleButton.current.click()
    // toast.success('You have signed out successfully', { autoClose: CONST.TOAST_AUTO_CLOSE })
  }




  const onSignout = async () => {
    // try {
    //   await signOut(auth)
    //   navigate('/')
    //   drawerToggleButton.current.click()
    //   toast.success('You have signed out successfully', { autoClose: CONST.TOAST_AUTO_CLOSE })
    // } catch (e) {
    //   console.error('singout error:', e)
    // }
  }

  const navigateTo = (path) => {
    console.log('--------> drawerToggleButton:', drawerToggleButton)
    // setTimeout(() => {
    //   drawerToggleButton.current.click()
    // }, 500)
    navigate(path)
    drawerToggleButton.current.click()
  }

  const onCloseMenu = () => {
    drawerToggleButton.current.click()
  }

  return (
    <div className="drawer-side">
      <label htmlFor="my-drawer" className="drawer-overlay"></label>
      <div className="w-64 h-full bg-base-200 text-base-content pb-10">

        {/* App Name */}
        <a className="flex items-center space-x-3 p-4" onClick={onCloseMenu}>
          <FaBars className="text-xl cursor-pointer"/>
          <p className="text-xl font-bold">{CONST.APP_NAME}</p>
        </a>

        <div className="divider"/>

        {/* Sign In/Up */}
        {/*{!isUndefined(userInfo) && userInfo ? (*/}
        {djangoProfile ? (
          // 로그인 상태
          // <button className="btn btn-sm btn-outline" onClick={onSignout}>
          <div className="flex flex-col items-center">
            <Link to="/profile">
              <img
                className="h-10 w-10 rounded-full"
                // src={profile.photoURL || defaultProfileImg}
                src={stravaUser.profile || defaultProfileImg}
                alt="Avatar"
              />
            </Link>
            <p>{djangoProfile.first_name}</p>
            <button className="flex items-center border border-gray-400 mt-2 py-0.5 px-3 rounded-2xl"
                    onClick={onLogout}>
              <FaSignOutAlt className="text-lg mr-2"/>
              <p className="text-sm">Sign Out</p>
            </button>
          </div>

        ) : (
          // 로그아웃 상태
          // <div className="flex">
          <div className="flex flex-col items-center">
            {/* Left */}

            {/*<div className="flex-1 flex flex-col items-center py-3 border-r"*/}
            {/*     onClick={() => {window.location.href = '/sign-in'}}>*/}
            {/*  <FaSignInAlt className="text-2xl"/>*/}
            {/*  <span>Sign In</span>*/}
            {/*</div>*/}

            <div className="flex flex-col items-center py-3 border-r cursor-pointer"
                 // onClick={onLogin}>
                 onClick={newLogin}>
            {/*<div className="flex-1 flex flex-col items-center py-3 border-r"*/}
            {/*     onClick={() => navigateTo('/sign-in')}>*/}
            {/*  <FaSignInAlt className="text-2xl"/>*/}
                <img
                  className="h-10 w-10 rounded-full"
                  // src={profile.photoURL || defaultProfileImg}
                  src={defaultImage.stravaLogoImg}
                  alt="Login"
                />

                {/*<StravaLogo/>*/}
                {/*<MdDirectionsBike*/}
                {/*  className="w-48 h-20 h-full w-full"*/}
                {/*/>*/}
              <span>Log in with Strava</span>
            </div>

            {/* Right */}
            {/*<div className="flex-1 flex flex-col items-center py-3" onClick={() => navigateTo('/sign-up')}>*/}
            {/*  <MdPersonAdd className="text-2xl"/>*/}
            {/*  <span>Sign Up</span>*/}
            {/*</div>*/}
          </div>
        )}

        <div className="divider"/>

        <div className="flex flex-col items-center">
          {/*<label className="text-sm">Country</label>*/}
          {/* get full list from https://github.com/michaelwittig/node-i18n-iso-countries */}
          <SelectCountry
            id="country"
            className="select select-bordered w-5/6"
            onChange={onChange}
            value={country}
            required
          >
          </SelectCountry>
        </div>

        <div className="divider"/>


        <ul className="menu px-4">

          {djangoProfile && (
            <>
              <li>
                <a onClick={() => navigateTo('/profile')}>
                  <FaUserCircle className="text-xl"/>
                  <span>Profile</span>
                </a>
              </li>
              {/*<li>*/}
              {/*  <a onClick={() => navigateTo('/my-rides')}>*/}
              {/*    <FaBiking className="text-xl"/>*/}
              {/*    <span>My Rides</span>*/}
              {/*  </a>*/}
              {/*</li>*/}
              {/*<li>*/}
              {/*  <a onClick={() => navigateTo('/upcoming-my-club-rides')}>*/}
              {/*    <FaBiking className="text-xl"/>*/}
              {/*    <span>My Club Rides</span>*/}
              {/*  </a>*/}
              {/*</li>*/}
          <div className="divider"/>
            </>
          )}

          {/* STAFF에게만 보이는 메뉴 */}
          {djangoProfile?.is_staff && (
            <>
              <li>
                <a onClick={() => navigateTo('/new-ride-event')}>
                  <FaUserCircle className="text-xl"/>
                  <span>Create Ride Event</span>
                </a>
              </li>
              <li>
                <a onClick={() => navigateTo('/my-test-page')}>
                  <FaUserCircle className="text-xl"/>
                  <span>My Test Page</span>
                </a>
              </li>

          <div className="divider"/>
            </>
          )}


          <li>
            <a onClick={() => navigateTo('/')}>
              <FaHome className="text-xl"/>
              <span>Home</span>
            </a>
          </li>
          <li>
            <a onClick={() => navigateTo('/ride-event-list')}>
              <FaCalendarCheck className="text-xl"/>
              <span>Explore Ride Events</span>
            </a>
          </li>
          <li>
            <a onClick={() => navigateTo('/about')}>
              <FaUserFriends className="text-xl"/>
              <span>About</span>
            </a>
          </li>
          {/*<li>*/}
          {/*  <a onClick={() => navigateTo('/strava-resource-test')}>*/}
          {/*    <FaUserFriends className="text-xl"/>*/}
          {/*    <span>Strava Resource Test</span>*/}
          {/*  </a>*/}
          {/*</li>*/}
          {/*<li>*/}
          {/*  <a onClick={() => navigateTo('/django-resource-test')}>*/}
          {/*    <FaUserFriends className="text-xl"/>*/}
          {/*    <span>Django Resource Test</span>*/}
          {/*  </a>*/}
          {/*</li>*/}
        </ul>

        {/*<div className="flex justify-center mt-5">*/}
        {/*  {!isUndefined(currentUser) && currentUser ? (*/}
        {/*    // <button className="btn btn-sm btn-outline" onClick={onSignout}>*/}
        {/*    <button className="btn-touch btn-touch-sm" onClick={onSignout}>*/}
        {/*      <FaSignOutAlt className="text-2xl mr-2"/>*/}
        {/*      <span>Sign Out</span>*/}
        {/*    </button>*/}
        {/*  ) : (*/}
        {/*    // <button className="btn btn-sm btn-outline" onClick={() => navigateTo('/sign-in')}>*/}
        {/*    <button className="btn-touch btn-touch-sm" onClick={() => navigateTo('/sign-in')}>*/}
        {/*      <FaSignInAlt className="text-2xl mr-2"/>*/}
        {/*      <span>Sign In</span>*/}
        {/*    </button>*/}
        {/*  )}*/}
        {/*</div>*/}
      </div>
    </div>
  )
}

export default MenuDrawer