import { useEffect, useState } from 'react'
import _, { capitalize, isUndefined } from 'lodash'
import VerticalBarChartForCount from './charts/VerticalBarChartForCount'
import { getCategoryByAge, getCountryName } from '../../utils/commonUtils'
import HorizontalBarChart from './charts/HorizontalBarChart'
import { CONST } from '../../constants'

/*
        {
            "id": 93,
            "strava_activity_id": 9936505848,
            "distance": 46864.8,
            "moving_time": 16423,
            "elapsed_time": 17542,
            "elapsed_time_hour": 4.9,
            "start_date_local": "2013-03-28T12:05:09Z",
            "average_speed": 7.568,
            "age": 78,
            "team": "Team8",
            "club": "WCR",
            "rider_first_name": "Alejandro",
            "rider_last_name": "Hernandez",
            "rider_sex": "MALE",
            "rider_country_code": "KR",
            "rider_full_name": "Alejandro Hernandez"
        },
 */

const yKey = 'elapsedTime'
const xKey = 'fullname'

function Top10ElapsedTimeForWomen ({ rideRecords, color }) {

  const [data, setData] = useState()
  const [minElapsedTime, setMinElapsedTime] = useState()
  const [maxElapsedTime, setMaxElapsedTime] = useState()

  useEffect(() => {

    const filtered = _(rideRecords)
      .filter({ rider_sex: 'FEMALE'})
      .map((item) => ({
        [xKey]: item.rider_full_name,
        [yKey]: item.elapsed_time_hour,
      }))
      .sortBy(yKey)
      .slice(0, 10)
      .value()
    // console.log("--------> filtered:", JSON.stringify(filtered, undefined, 2))
    setData(filtered)

    const elapsedTimes = _.map(filtered, yKey)
    // console.log("--------> elapsedTimes:", JSON.stringify(elapsedTimes, undefined, 2))
    // console.log("------->>>>>> _.max(elapsedTimes):", _.max(elapsedTimes))
    const max = _.max(elapsedTimes)
    const min = _.min(elapsedTimes)
    // const min = 0
    setMaxElapsedTime(max)
    setMinElapsedTime(_.floor(min * 0.9, 1))
    // setMaxElapsedTime(_.max(elapsedTimes))
    // setMinElapsedTime(_.mix(elapsedTimes) * 0.95)

  }, [])


  return (
    <>
      <h1 className="chart-title">Top 10 Elapsed Time (Women)</h1>
      <div className="chart-container">
        {data && !isUndefined(minElapsedTime) && !isUndefined(maxElapsedTime) && (
          <HorizontalBarChart
            data={data}
            yKey={yKey}
            xKey={xKey}
            color={color}
            domainMin={minElapsedTime}
            domainMax={maxElapsedTime}
            unit="hrs"
          />
        )}
      </div>
    </>
  )
}

export default Top10ElapsedTimeForWomen