import axios from 'axios'
import djangoApiInterceoptor from '../components/axiosInterceptors/djangoAxiosInterceptor'


// todo note:
//  - context 로드는 component에서만 가능. hook에서 불가
//  - hook은 function에서 호출 불가

export const fetchRideEvents = async (geoLocationCountry) => {
  return await axios.get(
    // `${process.env.REACT_APP_DJANGO_HOST}/api/ride_events/?country_code=${geoLocationCountry}&ordering=-start_at`,
    `${process.env.REACT_APP_DJANGO_HOST}/api/ride_events/?ordering=-start_at`,
  )
}

// export const fetchRideEvent = async (id) => {
//   try {
//     const response = await axios.get(
//       `${process.env.REACT_APP_DJANGO_HOST}/api/ride_events/${id}/`,
//     )
//     return response.data
//   } catch (e) {
//     console.log("Failed to fetch ride event.", e)
//   }
// }

export const fetchRideEvent = async (id) => {
  return await axios.get(
    `${process.env.REACT_APP_DJANGO_HOST}/api/ride_events/${id}/`,
  )
}

export const fetchRideRecords = async (rideEventId) => {
  return await axios.get(
    `${process.env.REACT_APP_DJANGO_HOST}/api/ride_records/?limit=10000&ride_event__id=${rideEventId}`,
  )
}

export const fetchHierarachicalRideEventComments = async (rideEventId) => {
  return await axios.get(
    `${process.env.REACT_APP_DJANGO_HOST}/api/ride_event_comments/list_hierarchy/?ride_event__id=${rideEventId}`,
  )
}

// export const fetchRideRecords = async (rideEventId) => {
//   try {
//     const response = await axios.get(
//       `${process.env.REACT_APP_DJANGO_HOST}/api/ride_rexcords/?limit=10000&ride_event__id=${rideEventId}`,
//     )
//     return response.data.results
//   } catch (e) {
//     console.log("Failed to fetch ride records.", e)
//     // return []
//   }
// }

// export const fetchHierarachicalRideEventComments = async (rideEventId) => {
//   try {
//     const response = await axios.get(
//       `${process.env.REACT_APP_DJANGO_HOST}/api/ride_event_comments/list_hierarchy/?ride_event__id=${rideEventId}`,
//     )
//     return response.data
//   } catch (e) {
//     console.log("Failed to fetch hierarchical ride event comments.", e)
//   }
// }




export const fetchToken = async (access_token, stravaUserInfo) => {
  /*
    --------> stravaUserInfo: {
      "id": 89937081,
      "profile_medium": "https://dgalywyr863hv.cloudfront.net/pictures/athletes/89937081/24071241/1/medium.jpg",
      "profile": "https://dgalywyr863hv.cloudfront.net/pictures/athletes/89937081/24071241/1/large.jpg",
      "countryCode": "SG",
      "sex": "M"
    } <------- 이 정보는 Django에서 rider 생성시 사용됨
   */
  return await axios.post(
    `${process.env.REACT_APP_DJANGO_HOST}/rest-auth/strava/`,
    {
      access_token,
      stravaUserInfo,
    },
    { headers: { 'Content-Type': 'application/json' } }
  )
}


// /////////////////// NEW //////////////
// /////////////////// NEW //////////////
// /////////////////// NEW //////////////

export const deleteRideEvent = async (rideEventId) => {
  return await djangoApiInterceoptor.delete(`${process.env.REACT_APP_DJANGO_HOST}/api/ride_events/${rideEventId}/`)
}

export const createRideEvent = async (data) => {
  return await djangoApiInterceoptor.post(
    `${process.env.REACT_APP_DJANGO_HOST}/api/ride_events/`,
    data,
    {
      headers: {
        ...djangoApiInterceoptor.defaults.headers.common, //<-- auth 헤드를 사용하기 위해
        'Content-Type': 'multipart/form-data',
      }
    },
  )
}

export const updateRideEvent = async (data, rideEventId) => {
  return await djangoApiInterceoptor.patch(
    `${process.env.REACT_APP_DJANGO_HOST}/api/ride_events/${rideEventId}/`,
    data,
    {
      headers: {
        ...djangoApiInterceoptor.defaults.headers.common, //<-- auth 헤드를 사용하기 위해
        'Content-Type': 'multipart/form-data',
      }
    },
  )
}

export const fetchProfileNew = async () => {
  return await djangoApiInterceoptor.get(`${process.env.REACT_APP_DJANGO_HOST}/api/profile/`)
}

export const updateProfileNew = async (data) => {
  return await djangoApiInterceoptor.put(
    `${process.env.REACT_APP_DJANGO_HOST}/api/profile/`,
    data,
  )
}

export const submitRideRecord = async (data) => {
  return await djangoApiInterceoptor.put(
    `${process.env.REACT_APP_DJANGO_HOST}/api/ride_records/submit/`,
    data,
  )
}

export const revokeRideRecord = async (rideEventId) => {
  return await djangoApiInterceoptor.delete(
    `${process.env.REACT_APP_DJANGO_HOST}/api/ride_events/${rideEventId}/revoke/`,
  )
}

export const toggleLikeComment = async (rideEventCommentId) => {
  return await djangoApiInterceoptor.put(
    `${process.env.REACT_APP_DJANGO_HOST}/api/ride_event_comments/${rideEventCommentId}/toggle_like/`,
  )
}

export const createRideEventComment = async (data) => {
  return await djangoApiInterceoptor.post(
    `${process.env.REACT_APP_DJANGO_HOST}/api/ride_event_comments/`,
    data,
  )
}
export const deleteRideEventComment = async (id) => {
  return await djangoApiInterceoptor.delete(
    `${process.env.REACT_APP_DJANGO_HOST}/api/ride_event_comments/${id}/`,
  )
}