import { useEffect } from 'react'
import { fetchMyActivities2 } from '../utils/stravaApi'

function StravaResoruceTest () {

  useEffect(() => {
    const getMyActivities = async () => {
      const response = await fetchMyActivities2()
      console.log("------->>>>>> [getMyActivities] response:", response)
    }
    getMyActivities()
  }, [])

  return (
    <div>
      StravaResoruceTest_Page
    </div>
  )
}

export default StravaResoruceTest