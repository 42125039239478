import React from 'react'
import { useNavigate } from 'react-router-dom'

function NoDataAndBackButton ({ title, errorMsg }) {

  const navigate = useNavigate()

  return (
    <div className="flex flex-col justify-center items-center h-80 space-y-5">
      <div className="text-xl font-bold">
        {title || 'No Data Available'}
      </div>
      {errorMsg}
      <button className="btn btn-lg" onClick={() => navigate(-1)}>Go Back</button>
    </div>
  )
}

export default NoDataAndBackButton