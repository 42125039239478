import React from 'react'
import { CONST } from '../constants'

function About() {
  return (

    <div className="page-container space-y-5">
      <h1 className="text-2xl font-bold mt-5">About {CONST.APP_NAME}</h1>

      <img
        className="w-full rounded-xl"
        src="https://firebasestorage.googleapis.com/v0/b/orgride-prod-1f4c3.appspot.com/o/assets%2Fno_change_about_page_img.jpeg?alt=media&token=5a37f29f-1321-43c0-9826-4dfa1dfe1d00"
      />

      <h1>Welcome to <b>RideArc.com</b> - Your Premier Cycling Event Hub!</h1>
      <p>Discover thrilling rides, track your cycling performance, and share unforgettable memories. Easily join cycling events, link your Strava account for automatic ride uploads, view your rank, and connect with fellow riders. Share photos to capture the essence of your cycling journeys. Join our vibrant community for cyclists of all levels. RideArc.com: where your cycling adventures are archived, friendships are forged, and every ride is cherished. Whether you're a casual rider or a seasoned pro, RideArc.com is your destination for all things cycling. Start your journey today and make every pedal stroke count!</p>

    </div>
  )
}
export default About