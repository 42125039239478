import RideEventForm from '../../components/mgmt/RideEventForm'
import { createRideEvent } from '../../utils/djangoApi'
import { toast } from 'react-toastify'
import { CONST } from '../../constants'
import { useNavigate } from 'react-router-dom'

function CreateRideEvent () {

  const navigate = useNavigate()

  const create = async (newRideEvent) => {
    try {
      const response = await createRideEvent(newRideEvent)
      // console.log('------->>>>>> response:', response)
      if (response.status === 201) {
        toast.success('Ride event has been created', { autoClose: CONST.TOAST_AUTO_CLOSE })
        navigate(`/ride-event-list/${response.data.id}`)
      } else {
        console.log("[CreateRideEvent] response to ride event creation req:", response)
        toast.warn('Failed to create ride event', { autoClose: CONST.TOAST_AUTO_CLOSE })
      }
    } catch (e) {
      console.log('[CreateRideEvent] Failed to create ride event. e:', e)
      console.log("--------> e.response.data:", JSON.stringify(e.response.data, undefined, 2))
      toast.error('Failed to create ride event', { autoClose: CONST.TOAST_AUTO_CLOSE })
    }
  }

  return (
    <div className="page-container">
      <div className="section-title">
        Create Ride Event
      </div>
      <RideEventForm createOrUpdate={create}/>
    </div>
  )
}

export default CreateRideEvent