import countries from 'i18n-iso-countries'
import enLocale from 'i18n-iso-countries/langs/en.json'

countries.registerLocale(enLocale)

// REF
// https://github.com/michaelwittig/node-i18n-iso-countries
// https://javascript.plainenglish.io/create-a-country-select-component-with-react-2021-a259bd0350d5

// NOTE: react에서는 <option>에서 selected가 아닌 <select>에서 value를 지정해야 함
function SelectCountry ({ optionTitle = 'Select Your Country', id, className, onChange, required, value }) {

  const countriesObject = countries.getNames('en', { select: 'official' })

  return (
    <>
      <select
        id={id}
        className={className}
        onChange={onChange}
        required={!!required}
        value={value}
      >
        {/*<option disabled value="">Select Your Country</option>*/}
        <option disabled value="">{optionTitle}</option>
        {Object.entries(countriesObject).map(([country_code, country_name]) => {
          return (
            <option key={country_code} value={country_code}>{country_name}</option>
          )
        })}
      </select>

    </>
  )
}

export default SelectCountry
