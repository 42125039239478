import { useContext, useEffect, useState } from 'react'
import Spinner from '../components/layout/Spinner'
// import UserContext from '../context/user/UserContext'
// import { fetchAllDocs } from '../services/firebaseDB'
// import { CONST } from '../constants'
// import MyClubList from '../components/profile/MyClubList'
import EditUserInfo from '../components/profile/EditUserInfo'

// -----------------
// REVIEWED
// -----------------

function Profile () {



  // const { profile, loading } = useContext(UserContext)
  // const [myClubs, setMyClubs] = useState(null)

  const onSuccess = () => {
    // note: do nothing here
  }


  // useEffect(() => {
  //   const fetchMyClubs = async () => {
  //     try {
  //       const myClubs = await fetchAllDocs(`${CONST.COLLECTION.USERS}/${profile.id}/${CONST.COLLECTION.USER_MY_CLUBS}`)
  //       if (myClubs.length === 0) {
  //         return
  //       }
  //       setMyClubs(myClubs)
  //     } catch (e) {
  //       console.error('Failed to fetch my clubs:', e)
  //     }
  //   }
  //   if (profile?.id) {
  //     fetchMyClubs()
  //   }
  // }, [profile])


  {/* NOTE: logout (나중에 사용할 경우를 위해 유지) */}
  // const onSignout = async () => {
  //   dispatch({ type: USER_ACTION_TYPE.SET_LOADING })
  //   try {
  //     await signOut(auth)
  //     navigate('/')
  //   } catch (e) {
  //     console.error('singout error:', e)
  //     if (loading) {
  //       dispatch({ type: USER_ACTION_TYPE.STOP_LOADING })
  //     }
  //   }
  // }


  return (
    <div className="page-container items-center">

      {/* NOTE: logout 버튼 (나중에 사용할 경우를 위해 유지) */}
      {/*<div className="flex justify-end w-full">*/}
      {/*  <button className="btn btn-error btn-sm" onClick={onSignout}>Logout</button>*/}
      {/*</div>*/}

      <EditUserInfo onSuccess={onSuccess}/>


      {/* my clubs */}
      {/*<div className="flex flex-col items-center mt-16">*/}
      {/*  <p className="text-lg font-bold">My Clubs</p>*/}
      {/*  {myClubs && <MyClubList myClubs={myClubs}/>}*/}
      {/*</div>*/}

    </div>
  )
}

export default Profile
