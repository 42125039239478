import React, { useContext, useEffect, useState } from 'react'
import LocationContext from '../../context/location/LocationContext'
import {
  getDistanceInMeter, getDistanceKmMilesByCountryCode,
  getDistanceKmMilesUnitByCountryCode,
  getDistanceKmMilesWithUnitByCountryCode, getEndOfDateInISO, getFilenameFromPreSignedUrl, getStartOfDateInISO
} from '../../utils/commonUtils'
import Spinner from '../layout/Spinner'
import SelectCountry from '../form/SelectCountry'
import Datepicker from 'react-tailwindcss-datepicker'
import { FaEdit } from 'react-icons/fa'
import { defaultImage } from '../img/defaultImages'

function RideEventForm ({ rideEvent, createOrUpdate }) {
  // console.log('------->>>>>> [RideEventForm] rideEvent:', rideEvent)
  // rideEvent:
  // - undefined: form for NEW
  // - anyValue: form for EDIT

  const [loading, setLoading] = useState(false)
  const { geoLocationCountry } = useContext(LocationContext)
  const [errorMsg, setErrorMsg] = useState('')

  // todo here - v1. image_thumbnail 필드 추가 (서버)
  // todo here - v2. ride event 생성시 리사이징된 image_thumbnail 생성하고 submit (client)
  // todo here - v3. ride event list에서 위 필드를 사용
  // todo here - v4. client에서 photo_url, photo_thum,.. 사용 제거
  // todo here - v5. server에서 photo_url, photo_thum,.. 사용 제거
  // todo here - v6. ride event view에서 staff를 위한 edit 링크 출력
  // todo here - v6. start,end dates를 폼에서 start,end of day로 설정
  // todo here - v6. server에서 patch 가능한지 postman으로 체크. patch시 내가 created_by인지 확인하는 작업 테스트
  // todo here - v7. ride event edit page 작성
  // todo here - v8. 기존 image, course 파일 필드가 null로 되는 경우 해당 파일 삭제
  // todo here - v8. 기존 image, course 파일 필드가 있는 entry 삭제시 포함된 해당 파일 삭제
  // todo here - 8. ride event 소유자 또는 staff는 삭제 버튼 표시

  /*
{
    "name": "Fundamental zero-defect matrix",
    "distance_meter": 90000,

    "thumbnail_photo_url": "https://picsum.photos/160/90",
    "photo_url": "https://picsum.photos/1280/720",

    "course_url": "https://www.chen-sanders.com/category/tags/listindex.asp",
    "chat_url": "http://www.reed-martin.biz/categoriesprivacy.htm",
    "description": "Focused zero-defect superstructure",
    "country_code": "SG",
    "cities": [
        "Johor Bahru",
        "Singapore"
    ],
    "start_at": "2023-09-20T08:05:47Z",
    "end_at": "2023-10-10T08:05:47Z",

    "image": FILE !!!, (option)
    "course_file1": FILE !!! (option)
    "course_file2": FILE !!! (option)
}
 */

  // todo NOTE: 중요!
  //  - Form 데이터는 여기 form page에 있는 input form만을 위한 것
  //  - Edit의 경우 서버로부터 온 데이터를 이 input form data로 변환하여 초기값을 설정
  const [formData, setFormData] = useState({
    name: rideEvent?.name ?? '', //<-- todo note: input 엘리먼트에서는 undefined 보다 공백값인 ""으로 설정해야 에러가 나오지 않음
    distanceKmOrMiles: rideEvent?.distance_meter ? getDistanceKmMilesByCountryCode(rideEvent.distance_meter, geoLocationCountry, 0) : '',
    courseUrl: rideEvent?.course_url ?? '',
    chatUrl: rideEvent?.chat_url ?? '',
    description: rideEvent?.description ?? '',
    countryCode: rideEvent?.country_code ?? '',
    cities: rideEvent?.cities ?? '',
    dateRange: {
      startDate: rideEvent?.start_at ?? new Date(),
      endDate: rideEvent?.end_at ?? new Date(),
    },

    imageFile: undefined, //<-- File object
    courseFile1: undefined, //<-- File object
    courseFile2: undefined, //<-- File object

    removeImageFile: false,
    removeCourseFile1: false,
    removeCourseFile2: false,
  })

  // 쉽게 사용하기 위해 비구조화
  const {
    name,
    distanceKmOrMiles,
    courseUrl,
    chatUrl,
    description,
    countryCode,
    cities,
    dateRange,
    imageFile,
    courseFile1,
    courseFile2,

    removeImageFile,
    removeCourseFile1,
    removeCourseFile2,
  } = formData

  // todo note: (BEST) onMutate New - 2024
  const onMutate = (e) => {

    // FOR DEBUG
    // console.log("------->>>>>> e:", e)
    // console.log('--------> e.target.id:', e.target.id)
    // console.log('--------> e.target.type:', e.target.type)
    // console.log('--------> e.target.value:', e.target.value)
    // console.log('--------> e.target.checked:', e.target.checked)

    // NOTE: e.target.type 종류
    // - text
    // - textarea
    // - checkbox
    // - file
    // - select-one

    // state 설정: input type이 checkbox 인 경우 처리
    if (e.target.type === 'checkbox') {
      setFormData((prevState) => ({
        ...prevState,
        [e.target.id]: e.target.checked,
      }))
      return
    }

    // state 설정: 여기서는 단일 파일 입력이므로 첫번째 파일을 대입
    if (e.target.type === 'file') {
      setFormData((prevState) => ({
        ...prevState,
        [e.target.id]: e.target.files[0], //<-- 파일 객체를 대입. 파일하나만 선택하므로
      }))
      return
    }

    // 준비: 입력값이 문자열 'true','false'인 경우 boolean 타입의 값을 대입
    let boolean = null
    if (e.target.value === 'true') {
      boolean = true
    }
    if (e.target.value === 'false') {
      boolean = false
    }

    // state 설정: Text/Booleans/Numbers 타입 입력인 경우를 위해
    // note: ??를 사용하면 전자가 null, undefined인 경우에만 후자를 사용
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: boolean ?? e.target.value,
    }))
  }

  const onSubmit = async (e) => {
    e.preventDefault()

    setErrorMsg('')
    setLoading(true)
    // console.log("--------> formData:", formData)
    console.log('--------> formData:', JSON.stringify(formData, undefined, 2))

    // 1. form data를 submit하기 전 알맞게 변환
    const rideEventToCreateOrUpdate = {
      name,
      distance_meter: getDistanceInMeter(distanceKmOrMiles, geoLocationCountry),
      course_url: courseUrl,
      chat_url: chatUrl,
      description,
      country_code: countryCode,
      cities,
      start_at: getStartOfDateInISO(dateRange.startDate),
      end_at: getEndOfDateInISO(dateRange.endDate),

      // image: removeImageFile ? null:imageFile,
      image: removeImageFile ? '' : imageFile,
      course_file1: removeCourseFile1 ? '' : courseFile1,
      course_file2: removeCourseFile2 ? '' : courseFile2,
    }
    console.log('--------> after building dataToCreateOrUpdate with formData:', JSON.stringify(rideEventToCreateOrUpdate, undefined, 2))

    // 2. createOrUpdate 호출
    const errorMsg = await createOrUpdate(rideEventToCreateOrUpdate, rideEvent?.id)
    setErrorMsg(errorMsg)
    setLoading(false)
  }

  if (!geoLocationCountry || loading) {
    return (
      <Spinner/>
    )
  }

  return (
    <>
      {/*<form className="page-form" onSubmit={()=>onSubmit(formData)}>*/}
      <form className="page-form" onSubmit={onSubmit}>

        {/* name */}
        <div className="form-field">
          <label className="form-label">* Name</label>
          <input id="name"
                 type="text"
                 placeholder="Name"
                 className="input input-bordered w-full"
                 value={name}
                 onChange={onMutate}
                 required
          />
        </div>

        {/* distance */}
        <div className="flex flex-col space-y-2">
          <label className="form-label">* Route distance
            ({getDistanceKmMilesUnitByCountryCode(geoLocationCountry)})</label>
          <input type="number"
                 id="distanceKmOrMiles"
                 className="input input-bordered w-full"
                 value={distanceKmOrMiles}
            // value={getDistanceKmMilesWithUnitByCountryCode(distance_meter, geoLocationCountry)}
                 onChange={onMutate}
            // onChange={(e) => {
            //   setFormData((prevState) => ({
            //     ...prevState,
            //     [e.target.id]: getDistanceInMeter(e.target.value, geoLocationCountry)
            //   }))
            // }}
                 placeholder="e.g., 100"
                 required
          />
        </div>

        {/* courseUrl */}
        <div className="form-field">
          <label className="form-label">Course URL</label>
          <input id="courseUrl"
                 type="text"
                 placeholder="e.g., URL for Garmin or Strava Route"
                 className="input input-bordered w-full"
                 value={courseUrl}
                 onChange={onMutate}
                 required
          />
        </div>

        {/* chatUrl */}
        <div className="form-field">
          <label className="form-label">Chat URL</label>
          <input id="chatUrl"
                 type="text"
                 placeholder="e.g., URL for Whatsapp Group Chat"
                 className="input input-bordered w-full"
                 value={chatUrl}
                 onChange={onMutate}
          />
        </div>


        {/* description */}
        <div className="flex flex-col space-y-2">
          <label className="ml-1">Description (500 limit)</label>
          <textarea
            type="description"
            id="description"
            className="textarea textarea-bordered w-full leading-5"
            value={description}
            onChange={onMutate}
            rows="10"
            cols="33"
            placeholder="About this ride"
            maxLength="500"
          />
        </div>

        {/* countryCode */}
        <div className="form-field">
          <label className="form-label">Country</label>
          {/* get full list from https://github.com/michaelwittig/node-i18n-iso-countries */}
          <SelectCountry
            id="countryCode"
            className="select select-bordered w-full"
            onChange={onMutate}
            value={countryCode}
            required
          >
          </SelectCountry>
        </div>

        {/* cities */}
        <div className="form-field">
          <label className="form-label">Cities (comma separated)</label>
          <input id="cities"
                 type="text"
                 placeholder="e.g., Seoul, Busan"
                 className="input input-bordered w-full"
                 value={cities}
                 onChange={onMutate}
                 required
          />
        </div>

        {/* start_at and end_at /}
        {/* For Date or Date Range - 2023 */}
        {/* note: value의 값이 항상 {"startDate": "2000-01-05","endDate": "2000-01-05"} 형식이지만 뷰 때문에 그냥 사용 */}
        {/* https://react-tailwindcss-datepicker.vercel.app/install */}
        {/* https://beatsoft.co/react-tailwindcss-datepicker-step-by-step/ */}
        <div className="form-field">
          <label className="form-label">Start and End Dates</label>
          <Datepicker
            containerClassName="border border-neutral-300 rounded-lg"
            // value={{ startDate: start_at, endDate: end_at }}
            value={dateRange}
            // onChange={onDateChange}
            onChange={(dateRange) => {
              setFormData((prevState) => ({
                ...prevState,
                dateRange,
              }))
            }}
          />
        </div>

        {/* Replace Input type FILE by Icon: https://www.youtube.com/watch?v=dvyvikylNmI*/}
        {/* todo best note: File 입력 대신 사진 클릭시 파일 입력을 받도록 함.
        * 1. <label>에서 file input 대신 사용될 수 있는 'for'를 제공
        * 2. <label>이 아닌 <img> 클릭시 파일 입력을 받고자 하므로 <label>을 <img> 상단에 위치시키도록 함
        */}
        <div className="form-field">
          <label className="form-label flex justify-between">
            <span>Image (1280x720)</span>
            {rideEvent?.image && (
              <div className="flex space-x-2">
                <input id="removeImageFile"
                       type="checkbox"
                       checked={removeImageFile}
                       onChange={onMutate}
                       className="checkbox"/>
                <span className="font-light">Remove</span>
              </div>
            )}
          </label>
          <div className="relative">
            <img
              // className="object-cover h-24 mt-5 rounded"
              className="object-cover w-full rounded-xl border"
              src={imageFile ? URL.createObjectURL(imageFile) : rideEvent?.image || defaultImage.size1280x720}
              alt="Ride Image"
              onError={(e) => {
                // todo note: (BEST) image 로드시 404 에러가 나오는 경우 기본 이미지 설정
                e.target.src = defaultImage.size1280x720
              }}
            />
            <label className="absolute inset-0 cursor-pointer" htmlFor="imageFile"/>
          </div>
          <input
            className="hidden"
            type="file"
            id="imageFile"
            onChange={onMutate}
            accept=".jpg,.png,.jpeg"
          />
          {rideEvent?.image && (
            <span className="font-light text-sm">{getFilenameFromPreSignedUrl(rideEvent.image)}</span>
          )}
        </div>


        {/* course_file1 */}
        <div className="form-field">
          <label className="form-label flex justify-between">
            <span>Course File 1</span>
            {rideEvent?.course_file1 && (
              <div className="flex space-x-2">
                <input id="removeCourseFile1"
                       type="checkbox"
                       checked={removeCourseFile1}
                       onChange={onMutate}
                       className="checkbox"/>
                <span className="font-light">Remove</span>
              </div>
            )}
          </label>
          <input id="courseFile1"
                 type="file"
                 className="file-input file-input-bordered w-full"
                 onChange={onMutate}
          />
          {/*todo note: (BEST) File 입력에서 value는 설정하지 않음.*/}
          {/* - onMutate()를 통해 해당 state에 File 객체를 설정하기만 하면 됨*/}
          {/* - 값이 설정되면 input폼에 자동으로 파일 이름이 출력됨*/}
          {rideEvent?.course_file1 && (
            <span className="font-light text-sm">{getFilenameFromPreSignedUrl(rideEvent.course_file1)}</span>
          )}
        </div>

        {/* course_file2 */}
        <div className="form-field">
          <label className="form-label flex justify-between">
            <span>Course File 2</span>
            {rideEvent?.course_file2 && (
              <div className="flex space-x-2">
                <input id="removeCourseFile2"
                       type="checkbox"
                       checked={removeCourseFile2}
                       onChange={onMutate}
                       className="checkbox"/>
                <span className="font-light">Remove</span>
              </div>
            )}
          </label>
          <input id="courseFile2"
                 type="file"
                 className="file-input file-input-bordered w-full"
                 onChange={onMutate}
          />
          {rideEvent?.course_file2 && (
            <span className="font-light text-sm">{getFilenameFromPreSignedUrl(rideEvent.course_file2)}</span>
          )}
        </div>

        <div className="flex flex-col">
          {/* Error Msg */}
          <div className="flex justify-end h-8 text-red-600">
            <span>{errorMsg}</span>
          </div>
          {/*  SUBMIT */}
          <button type="submit" className="btn btn-primary">Submit</button>
        </div>
      </form>
    </>
  )
}

export default RideEventForm