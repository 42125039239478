import { useEffect, useState } from 'react'
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'
import humanizeDuration from 'humanize-duration'
import _ from 'lodash'
import { CONST } from '../../../constants'

// Recharts
// https://github.com/recharts/recharts <---- github
// https://recharts.org/en-US/examples <---- examples
// https://blog.logrocket.com/top-8-react-chart-libraries/ <--- other charts

function HorizontalBarChart ({ data, color, xKey, yKey, domainMin, domainMax, unit, yaxisTickFormatter, tooltipFormatter, xAxisHeight }) {

  // console.log("--------> domainMax:", domainMax)
  // console.log("--------> domainMin:", domainMin)

  return (
    <ResponsiveContainer width="100%" height="100%">
      {/* Recharts options */}
      {/* https://recharts.org/en-US/examples/CustomContentOfTooltip */}
      <BarChart
        // width={500}
        // height={300}
        data={data}
        margin={{
          top: 15,
          right: 0,
          left: 10,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3"/>
        <XAxis dataKey={xKey}
               height={xAxisHeight || 110}
               angle={CONST.BAR_CHART.XAXIS_ANGLE}
               textAnchor="end"
               fontSize={CONST.BAR_CHART.FONT_SIZE}
               interval={0} //<-- todo note: display all ticks !!
        />
        <YAxis unit={unit}
               type="number"
               tickFormatter={yaxisTickFormatter}
               // width={15}
               // tickFormatter={(value) => `${_.round(value / 60 / 60, 2)}\nhrs`}
               // domain={[
               //   Math.min(...top10.map(item => item.elapsed_time)) * 0.95,
               //   Math.max(...top10.map(item => item.elapsed_time)),
               // ]}
               domain={[ domainMin, domainMax ]}
               fontSize={CONST.BAR_CHART.FONT_SIZE}
        />
        {/*<Legend/>*/}
        {/*<Tooltip/>*/}
        <Tooltip formatter={tooltipFormatter}/>
        {/*<Tooltip formatter={(value, name, props) => `${shortEnglishHumanizer(value * 1000)}`}*/}
        {/*         labelFormatter={value => `${value}`}*/}
        {/*/>*/}
        <Bar dataKey={yKey} fill={color}/>
      </BarChart>
    </ResponsiveContainer>
  )
}

export default HorizontalBarChart