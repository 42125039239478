import React, { useState } from 'react'
import InputEmoji from 'react-input-emoji'

// Emoji
// https://github.com/ealush/emoji-picker-react
// https://cesarwbr.github.io/react-input-emoji/

function CommentForm ({onCreateComment, replyTo}) {

  // note: ride_event_id 혹은 reply_to 중 하나만 사용해 POST 요청
  // note: reply_to는 reply시 전달되는 인자로 ride_event_comment id

  const [comment_text, setComment_text] = useState('')

  const onSubmit = async () => {
    await onCreateComment(comment_text, replyTo)
    setComment_text('')
  }

  return (
    <div className="flex space-x-2 mt-3">
      <div className="flex-1">
        {/* https://cesarwbr.github.io/react-input-emoji/ */}
        <InputEmoji
          value={comment_text}
          onChange={setComment_text}
          onEnter={onSubmit}
          placeholder="Type a message"
          borderColor="#DADADC"
          borderRadius={15}
          fontSize={18}
        />
      </div>
      <button className="btn btn-outline border-neutral-400" onClick={onSubmit}>Post</button>
    </div>
  )
}

export default CommentForm