import { useContext, useEffect, useState } from 'react'
import Spinner from '../components/layout/Spinner'
import { fetchRideEvents } from '../utils/djangoApi'
import RideEventItem from '../components/ride_event_list/RideEventItem'
import { useNavigate } from 'react-router-dom'
import LocationContext from '../context/location/LocationContext'


// todo note: ride events 가져와서 출력. page는??
// todo note: ride events 가져와서 출력. page는??
// todo note: ride events 가져와서 출력. page는??
// todo note: ride events 가져와서 출력. page는??
// todo note: ride events 가져와서 출력. page는??

function RideEventList() {

  const { geoLocationCountry } = useContext(LocationContext)
  const navigate = useNavigate()
  const [rideEvents, setRideEvents] = useState(undefined)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    const getRideEvents = async () => {
      try {
        const res = await fetchRideEvents(geoLocationCountry)
        // console.log('------->>>>>> res:', res)
        setRideEvents(res.data.results)
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false)
      }
    }
    if (geoLocationCountry) {
      getRideEvents()
    }
  }, [geoLocationCountry])

  const onClickRideEvent = (rideEventId) => {
    navigate(`/ride-event-list/${rideEventId}`)
  }

  // if (rideEvents === undefined) {
  //   return <Spinner/>
  // }

  if (loading) {
    return (
      <div className="spinner-center">
        <Spinner/>
      </div>
    )
  }

  return (
    <div className="page-container">
      <h1 className="section-title">Explore Rides</h1>

      {rideEvents && (
        rideEvents.length > 0 ? (
          <>
            {rideEvents.map((rideEvent) => (
              <RideEventItem key={rideEvent.id}
                             rideEvent={rideEvent}
                             onClickRideEvent={onClickRideEvent}
              />
            ))}
          </>
        ) : (
          // 데이터는 받았지만 데이터가 빈 array
          <div>NO DATA</div>
        )
      )}

    </div>
  )
}
export default RideEventList