import axios from 'axios'
import { CONST } from '../../constants'

const stravaApiInterceoptor = axios.create({})

stravaApiInterceoptor.interceptors.request.use(
  (config) => { //<-- 1st arg
    const tokensData = JSON.parse(localStorage.getItem(CONST.LOCAL_STORAGE_KEY.STRAVA_TOKENS))
    config.headers.setAuthorization(`Bearer ${tokensData.access_token}`)
    return config
  }
)

stravaApiInterceoptor.interceptors.response.use(
  (response) => { //<-- 1st arg
    return response
  },
  async (error) => { //<-- 2nd arg
    if (error.response.status === 401) {
      const tokensData = JSON.parse(localStorage.getItem(CONST.LOCAL_STORAGE_KEY.STRAVA_TOKENS))
      const { REACT_APP_CLIENT_ID, REACT_APP_CLIENT_SECRET } = process.env
      const apiResponse = await axios.post(
        'https://www.strava.com/oauth/token',
        null,
        {
          params: {
            client_id: REACT_APP_CLIENT_ID,
            client_secret: REACT_APP_CLIENT_SECRET,
            refresh_token: tokensData.refresh_token,
            grant_type: "refresh_token"
          }
        }
      )
      localStorage.setItem(CONST.LOCAL_STORAGE_KEY.STRAVA_TOKENS, JSON.stringify({
        refresh_token: apiResponse.data.refresh_token,
        access_token: apiResponse.data.access_token,
      }))
      error.config.headers.setAuthorization(`Bearer ${apiResponse.data.access_token}`)
      return axios(error.config)
    } else {
      return Promise.reject(error)
    }
  }
)

export default stravaApiInterceoptor