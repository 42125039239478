import countries from 'i18n-iso-countries'
import enLocale from 'i18n-iso-countries/langs/en.json'
import { CONST } from '../constants'
import moment from 'moment'
import { DateTime } from 'luxon'
import _, { isEmpty, isNull, isString, isUndefined } from 'lodash'
import humanizeDuration from 'humanize-duration'

countries.registerLocale(enLocale)

export const randomItem = (items) => {
  return items[Math.floor(Math.random() * items.length)]
}

export const getCountryName = (countryCode) => {
  return countries.getName(countryCode, 'en')
}

export const getCountryCode = (countryName) => {
  return countries.getAlpha2Code(countryName, 'en')
}

export const isObjectEmpty = (obj) => {
  return Object.keys(obj).length === 0
}

export const getMiles = (meters) => {
  const miles = (meters * 0.000621371192).toFixed(1)
  return `${miles} Miles`
}

export const getKm = (meters) => {
  const km = (meters / 1000).toFixed(1)
  return `${km} Km`
}

export const getDistanceUnitByCountry = (country) => {
  if (country === 'US') {
    return CONST.DISTANCE_UNIT.MILE
  } else {
    return CONST.DISTANCE_UNIT.KM
  }
}

// export const getSpeedUnit = (distanceUnit) => {
//     if (distanceUnit === CONST.DISTANCE_UNIT.KM) {
//         return 'kph'
//     } else if (distanceUnit === CONST.DISTANCE_UNIT.MILE) {
//         return 'mph'
//     }
// }

export const getUserUnits = (distanceUnit) => {
  if (distanceUnit === CONST.DISTANCE_UNIT.MILE) {
    return {
      userSpeedUnit: 'mph',
      userDistanceUnit: 'Miles',
    }
  }
  return {
    userSpeedUnit: 'kph',
    userDistanceUnit: 'Km',
  }
  // if (distanceUnit === CONST.DISTANCE_UNIT.KM) {
  //     return {
  //         userSpeedUnit: 'kph',
  //         userDistanceUnit: 'Km',
  //     }
  // } else if (distanceUnit === CONST.DISTANCE_UNIT.MILE) {
  //     return {
  //         userSpeedUnit: 'mph',
  //         userDistanceUnit: 'Miles',
  //     }
  // }
}

export const getDistance = (meters, unit) => {
  if (unit === CONST.DISTANCE_UNIT.MILE) {
    return getMiles(meters)
  }
  return getKm(meters)
  // if (unit === CONST.DISTANCE_UNIT.KM) {
  //     return getKm(meters)
  // } else
  // }
}

export const getDistanceKmMiles = (meters, unit, fractionDigits=1) => {
  if (unit === CONST.DISTANCE_UNIT.MILE) {
    return (meters * 0.000621371192).toFixed(fractionDigits)
  }
  return (meters / 1000).toFixed(fractionDigits)
  // if (unit === CONST.DISTANCE_UNIT.KM) {
  //     return (meters/1000).toFixed(1)
  // } else if (unit === CONST.DISTANCE_UNIT.MILE) {
  //     return (meters*0.000621371192).toFixed(1)
  // }
}

export const getDistanceMeters = (kmOrMiles, unit) => {
  if (unit === CONST.DISTANCE_UNIT.KM) {
    return kmOrMiles * 1000
  } else if (unit === CONST.DISTANCE_UNIT.MILE) {
    return kmOrMiles * 1609.34
  }
}

export const getSpeed = (metersPerSec, unit) => {
  if (unit === CONST.DISTANCE_UNIT.KM) {
    const km = (metersPerSec * 3.6).toFixed(1)
    return `${km} kph`
  } else if (unit === CONST.DISTANCE_UNIT.MILE) {
    const miles = (metersPerSec * 2.23694).toFixed(1)
    return `${miles} mph`
  }
}
export const getSpeedKphMph = (metersPerSec, unit) => {
  if (unit === CONST.DISTANCE_UNIT.KM) {
    return (metersPerSec * 3.6).toFixed(0)
  } else if (unit === CONST.DISTANCE_UNIT.MILE) {
    return (metersPerSec * 2.23694).toFixed(1)
  }
}






export const getDistanceInMeter = (kmOrMiles, countryCode) => {
  if (countryCode === 'US') {
    return kmOrMiles * 1609.34
  } else {
    return kmOrMiles * 1000
  }
}
export const getDistanceKmMilesWithUnitByCountryCode = (meters, countryCode, fractionDigits=1) => {
  const unit = getDistanceKmMilesUnitByCountryCode(countryCode)
  if (countryCode === 'US') {
    const distance = (meters * 0.000621371192).toFixed(fractionDigits)
    return `${distance} ${unit}`
  }
  const distance = (meters / 1000).toFixed(fractionDigits)
  return `${distance} ${unit}`
}
export const getDistanceKmMilesByCountryCode = (meters, countryCode, fractionDigits=1) => {
  if (countryCode === 'US') {
    return (meters * 0.000621371192).toFixed(fractionDigits)
  }
  return (meters / 1000).toFixed(fractionDigits)
}
export const getDistanceKmMilesUnitByCountryCode = (countryCode) => {
  if (countryCode === 'US') {
    return 'Miles'
  }
  return 'Km'
}
export const getSpeedKphMphStrByCountryCode = (metersPerSec, countryCode) => {
  if (countryCode === 'US') {
    const speed = (metersPerSec * 2.23694).toFixed(1)
    return `${speed} Mph`
  }
  const speed = (metersPerSec * 3.6).toFixed(1)
  return `${speed} Kph`
}
export const getSpeedKphMphByCountryCode = (metersPerSec, countryCode) => {
  if (countryCode === 'US') {
    return (metersPerSec * 2.23694).toFixed(1)
  }
  return (metersPerSec * 3.6).toFixed(1)
}
export const getSpeedUnitByCountryCode = (countryCode) => {
  if (countryCode === 'US') {
    return 'Mph'
  }
  return 'Kph'
}







export const getSpeedMeterPerSec = (speedKphOrMph, unit) => {
  if (unit === CONST.DISTANCE_UNIT.KM) {
    return speedKphOrMph * 0.277778 //<-- meter per second
  } else if (unit === CONST.DISTANCE_UNIT.MILE) {
    return speedKphOrMph * 0.44704 //<-- meter per second
  }
}

export const getUniqueItems = (arr) => {
  return [...new Set(arr)]
}

export const removeNullUndefinedFromObj = (obj) => {
  return Object.keys(obj).reduce((accumulator, key) => {
    if (obj[key] != null) {
      accumulator[key] = obj[key]
    }
    return accumulator
  }, {})
}

export const getSpeedRange = (avgStr, rangeStr, distanceUnit) => {
  const speed1 = parseFloat(avgStr) - parseFloat(rangeStr)
  const speed2 = parseFloat(avgStr) + parseFloat(rangeStr)
  if (distanceUnit === CONST.DISTANCE_UNIT.KM) {
    return `${speed1.toFixed(0)} - ${speed2.toFixed(0)}`
  } else {
    return `${speed1.toFixed(1)} - ${speed2.toFixed(1)}`
  }
}

// Luxon
// official - https://moment.github.io/luxon/#/formatting?id=table-of-tokens
// good example - https://moment.github.io/luxon/demo/global.html

export const getRelativeCal = (fbTiemstamp) => {
  return DateTime.fromJSDate(new Date(fbTiemstamp.toDate())).toRelativeCalendar()
}

export const getFormattedDate1 = (isoDtStr) => {
  // Example: 29 Sept 2023
  return DateTime.fromISO(isoDtStr).toLocaleString(DateTime.DATE_MED)
  // return DateTime.fromISO(isoDtStr).toFormat('DD') //<-- same as above
}
export const getFormattedDate2 = (isoDtStr) => {
  // Example: 29 Sept
  return DateTime.fromISO(isoDtStr).toFormat('d LLL') //<-- same as above
}

export const getFormattedDate3 = (isoDtStr) => {
  // Example: 06/10/2023, 17:21
  return DateTime.fromISO(isoDtStr).toFormat('f') //<-- same as above
}

export const getAbsoluteTimeToNowSince = (isoDtStr) => {
  // Example: 1 h, 3 m, 36 s
  const timestamp_created_at = DateTime.fromISO(isoDtStr).toUnixInteger()
  const timestamp_now = DateTime.now().toUnixInteger()
  return getFormattedDeltaTimestamp(timestamp_now - timestamp_created_at)
}


export const getFormattedDateLong = (isoDtStr) => {
  return DateTime.fromISO(isoDtStr).toFormat('d LLL y, h:mm a ZZZZ')
}

export const getFormattedDatetime1 = (isoDtStr) => {
  // return DateTime.fromISO(isoDtStr).toLocaleString(DateTime.DATETIME_SHORT)
  return DateTime.fromISO(isoDtStr).toLocaleString(DateTime.DATETIME_MED)
  // return DateTime.fromISO(isoDtStr).toLocaleString(DateTime.DATETIME_FULL)
  // return DateTime.fromISO(isoDtStr).toLocaleString(DateTime.DATETIME_HUGE)
}




// export const getFormattedDate1 = (fbTiemstamp) => {
//   return DateTime.fromJSDate(new Date(fbTiemstamp.toDate())).toFormat('ccc, fff')
// }

export const getFormattedDateForFirebaseTimestamp = (fbTiemstamp) => {
  return DateTime.fromJSDate(new Date(fbTiemstamp.toDate())).toFormat('d LLL y, h:mm a ZZZZ')
}

export const isUndefinedNullEmptyStr = (v) => {
  return isUndefined(v) || isNull(v) || (isString(v) && isEmpty(v.trim()))
}

// export const groupBy = (array, key) => {
//   // Return the end result
//   return array.reduce((result, currentValue) => {
//     // If an array already present for key, push it to the array. Else create an array and push the object
//     (result[currentValue[key]] = result[currentValue[key]] || []).push(
//       currentValue
//     )
//     // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
//     return result
//   }, {}) // empty object is the initial value for result object
// }

export function* colorGenerator() {
  for (const hexColor of CONST.HEX_COLOR_CODES) {
    yield hexColor
  }
}

export const getCategoryByAge = (age) => {
  for (const ageCatetory of CONST.AGE_CATETORIES) {
    const {cat, lessThan} = ageCatetory
    if (age < lessThan) {
      return cat
    }
  }
}

// https://www.npmjs.com/package/humanize-duration
export const shortEnglishHumanizer = humanizeDuration.humanizer({
  language: 'shortEn',
  languages: {
    shortEn: {
      y: () => 'y',
      mo: () => 'mo',
      w: () => 'w',
      d: () => 'd',
      h: () => 'h',
      m: () => 'm',
      s: () => 's',
      ms: () => 'ms',
    },
  },
})

export const getFormattedDeltaTimestamp = (delta) => {
  const seconds = Math.abs(delta); // Ensure it's a positive value

  if (seconds >= 86400) {
    const days = Math.floor(seconds / 86400);
    return days + "d";
  } else if (seconds >= 3600) {
    const hours = Math.floor(seconds / 3600);
    return hours + "h";
  } else if (seconds >= 60) {
    const minutes = Math.floor(seconds / 60);
    return minutes + "m";
  } else {
    return seconds + "s";
  }
}


export const getBadgeByStatus = (status) => {
  // index.css에서 css 이름을 동적으로 하면 잘 동작하지 않음
  switch (status) {
    case 'UPCOMING':
      return 'badge badge-warning p-3'
    case 'OPEN':
      return 'badge badge-secondary p-3'
    case 'CLOSED':
      return 'badge badge-success p-3'
  }
}

export const isTokenValid = (expiresAt) => {
  if (expiresAt < new Date()/1000) {
    console.log("------->>>>>> token expired !!")
    return false
  } else {
    console.log("------->>>>>> token is valid !!")
    return true
  }
}

export const getFilenameFromPreSignedUrl = (url) => {
  const parts = url.split('/')
  // Get the last part of the path, which should be the filename
  const filename = parts[parts.length - 1]
  // If the filename contains query parameters, remove them
  const filenameWithoutQuery = filename.split('?')[0]
  return filenameWithoutQuery
}

export const getStartOfDateInISO = (dtString) => {
  const dt = new Date(dtString)
  dt.setHours(0, 0, 0, 0)
  return dt.toISOString()
}
export const getEndOfDateInISO = (dtString) => {
  const dt = new Date(dtString)
  dt.setHours(23, 59, 59, 999)
  return dt.toISOString()
}

// export const truncate = (str, maxlength) => {
//   return (str.length > maxlength) ?
//     str.slice(0, maxlength - 1) + '…' : str
// }

