import { useEffect, useState } from 'react'
import _, { capitalize } from 'lodash'
import VerticalBarChartForCount from './charts/VerticalBarChartForCount'
import { getCountryName } from '../../utils/commonUtils'

/*
        {
            "id": 93,
            "strava_activity_id": 9936505848,
            "distance": 46864.8,
            "moving_time": 16423,
            "elapsed_time": 17542,
            "elapsed_time_hour": 4.9,
            "start_date_local": "2013-03-28T12:05:09Z",
            "average_speed": 7.568,
            "age": 78,
            "team": "Team8",
            "club": "WCR",
            "rider_first_name": "Alejandro",
            "rider_last_name": "Hernandez",
            "rider_sex": "MALE",
            "rider_country_code": "KR",
            "rider_full_name": "Alejandro Hernandez"
        },
 */

const itemKey = 'rider_sex'
const yKey = 'country'
const xKey = 'riders'

function RiderCountByGender ({ rideRecords, color }) {

  const [data, setData] = useState()

  useEffect(() => {

    //
    // http://www.java2s.com/ref/javascript/node.js-lodash-index.html

    // todo REF: lodash groupby case (key)
    // const groupByCountry = _.groupBy(rideRecords, 'rider_country_code')
    // console.log("--------> groupByCountry:", JSON.stringify(groupByCountry, undefined, 2))

    // todo REF: lodash groupby case (fn)
    // const statusMap = _.groupBy(rideRecords,function(item) {
    //   return item.age >= 50 ? 'young' : 'old';
    // })
    // console.log("--------> statusMap:", JSON.stringify(statusMap, undefined, 2))

    // todo REF: lodash groupby case (fn with switch/case)
    // const catByAge = _.groupBy(rideRecords, function (item) {
    //   const catA = '-30'
    //   const catB = '30-60'
    //   const catC = '60-'
    //   switch (true) {
    //     case (item.age < 30):
    //       return catA
    //     case (item.age < 60):
    //       return catB
    //     default:
    //       return catC
    //   }
    // })
    // console.log('--------> statusMap:', JSON.stringify(catByAge, undefined, 2))

    // todo REF: lodash countBy case (key)
    // const count = _.countBy(rideRecords, 'rider_country_code')
    // console.log("--------> count:", JSON.stringify(count, undefined, 2))
    /*
    {
      "SG": 31,
      "MY": 38,
      "KR": 31
    }
     */

    // todo REF: lodash countBy case (fn)
    let filtered = _.countBy(rideRecords, (item) => item[itemKey])
    filtered = Object.entries(filtered).map(([key, value]) => ({
      [yKey]: key,
      [xKey]: value,
    }))
    // console.log('--------> filtered:', JSON.stringify(filtered, undefined, 2))
    filtered = _(filtered)
      .sortBy(xKey).reverse()
      .map((item) => ({
        ...item,
        [yKey]: capitalize(item[yKey])
      }))
      .value()
    // console.log('--------> filtered:', JSON.stringify(filtered, undefined, 2))

    /*
    [
      {
        "name": "MY",
        "count": 38
      },
      {
        "name": "KR",
        "count": 31
      },
      {
        "name": "SG",
        "count": 31
      }
    ]
     */

    setData(filtered)

  }, [])

  return (
    <>
      <h1 className="chart-title">Gender-Specific Rider Count</h1>
      <div className="mb-5">
        {data && (
          <VerticalBarChartForCount
            data={data}
            yKey={yKey}
            xKey={xKey}
            color={color}
          />
        )}
      </div>
    </>
  )
}

export default RiderCountByGender