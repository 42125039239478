import size1280x720 from '../../img/1280x720.png'
import size160x90 from '../../img/160x90.png'
import stravaLogoImg from '../../img/strava_logo.png'

// todo note: (BEST) size image download from:
// https://dummyimage.com/1280x720
// https://dummyimage.com/160x90

export const defaultImage = {
  size1280x720,
  size160x90,
  rideEventListDefaultImg: size160x90,
  rideEventDefaultImg: size1280x720,
  stravaLogoImg,
}
