import { useEffect, useState } from 'react'
import _ from 'lodash'
import VerticalBarChartForCount from './charts/VerticalBarChartForCount'
import { getCountryName } from '../../utils/commonUtils'

const itemKey = 'rider_country_code'
const yKey = 'country'
const xKey = 'riders'

function RiderCountByCountry ({ rideRecords, color }) {

  const [data, setData] = useState()

  useEffect(() => {

    //
    // http://www.java2s.com/ref/javascript/node.js-lodash-index.html

    // todo REF: lodash groupby case (key)
    // const groupByCountry = _.groupBy(rideRecords, 'rider_country_code')
    // console.log("--------> groupByCountry:", JSON.stringify(groupByCountry, undefined, 2))

    // todo REF: lodash groupby case (fn)
    // const statusMap = _.groupBy(rideRecords,function(item) {
    //   return item.age >= 50 ? 'young' : 'old';
    // })
    // console.log("--------> statusMap:", JSON.stringify(statusMap, undefined, 2))

    // todo REF: lodash groupby case (fn with switch/case)
    // const catByAge = _.groupBy(rideRecords, function (item) {
    //   const catA = '-30'
    //   const catB = '30-60'
    //   const catC = '60-'
    //   switch (true) {
    //     case (item.age < 30):
    //       return catA
    //     case (item.age < 60):
    //       return catB
    //     default:
    //       return catC
    //   }
    // })
    // console.log('--------> statusMap:', JSON.stringify(catByAge, undefined, 2))

    // todo REF: lodash countBy case (key)
    // const count = _.countBy(rideRecords, 'rider_country_code')
    // console.log("--------> count:", JSON.stringify(count, undefined, 2))
    /*
    {
      "SG": 31,
      "MY": 38,
      "KR": 31
    }
     */

    // todo REF: lodash countBy case (fn)
    let filtered = _.countBy(rideRecords, (item) => item[itemKey])
    filtered = Object.entries(filtered).map(([key, value]) => ({
      [yKey]: key,
      [xKey]: value,
    }))
    // console.log('--------> filtered:', JSON.stringify(filtered, undefined, 2))
    filtered = _(filtered)
      .sortBy(xKey).reverse()
      .map((item) => ({
        ...item,
        [yKey]: getCountryName(item[yKey])
      }))
      .value()
    // console.log('--------> filtered:', JSON.stringify(filtered, undefined, 2))

    /*
    [
      {
        "name": "MY",
        "count": 38
      },
      {
        "name": "KR",
        "count": 31
      },
      {
        "name": "SG",
        "count": 31
      }
    ]
     */

    setData(filtered)

  }, [])

  return (
    <div className="">
      <h1 className="chart-title">Country-Specific Rider Count</h1>
      <div className="mb-5">
        {data && (
          <VerticalBarChartForCount
            data={data}
            yKey={yKey}
            xKey={xKey}
            color={color}
          />
        )}
      </div>
    </div>
  )
}

export default RiderCountByCountry