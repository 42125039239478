import stravaApiInterceoptor from '../components/axiosInterceptors/stravaAxiosInterceptor'
import axios from 'axios'

export const newLogin = () => {
  const { REACT_APP_CLIENT_ID, REACT_APP_REDIRECT_URL } = process.env
  window.location = `https://www.strava.com/oauth/authorize?client_id=${REACT_APP_CLIENT_ID}&response_type=code&redirect_uri=${REACT_APP_REDIRECT_URL}/exchange_token&approval_prompt=force&scope=activity:read`
}

export const getStravaActivitiesWithQP = async (after, before) => {
  return await stravaApiInterceoptor.get(
    `https://www.strava.com/api/v3/athlete/activities`,
    {
      params: {
        before,
        after,
        page: 1,
        per_page: 100,
      }
    },
  )
}

export const fetchMyActivities2 = async () => {
  return await stravaApiInterceoptor.get(`https://www.strava.com/api/v3/athlete/activities`)
}

export const getAuthFromStravaNew = async (code) => {
  const data = {
    'client_id': process.env.REACT_APP_CLIENT_ID,
    'client_secret': process.env.REACT_APP_CLIENT_SECRET,
    code,
    'grant_type': 'authorization_code'
  }
  // console.log('--------> data:', data)

  // 1. strava에서 redirect url로 받은 code를 가지고 auth data를 요청
  return await axios.post('https://www.strava.com/oauth/token', data)

  // // 2. 로그인 후 받은 데이터를 local에 저장
  // console.log('--------> response:', response)
  // if (response.status === 200) {
  //   setStravaAuthData(response.data)
  //   // setAuthTokens(data);
  //   // setUser(jwt_decode(data.access));
  //   // localStorage.setItem("stravaAuthData", JSON.stringify(data));
  //   // navigate("/");
  // } else {
  //   console.log('------->>>>>> failed to get auth data from strava')
  // }
}