import Flags from "country-flag-icons/react/3x2";

// REF
// https://www.npmjs.com/package/country-flag-icons
// https://stackoverflow.com/questions/65681881/react-dynamic-use-of-country-flag-icons-library

function FlagImg({country_code, className}) {
  const Flag = Flags[country_code.toUpperCase()];

  return (
    <>
      <Flag className={className} />
    </>
  )
}

export default FlagImg
