import { Link, useNavigate, useParams } from 'react-router-dom'
import { useContext, useEffect, useMemo, useReducer, useRef, useState } from 'react'
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'
import humanizeDuration from 'humanize-duration'
import _, { isUndefined } from 'lodash'

import LocationContext from '../context/location/LocationContext'
import Spinner from '../components/layout/Spinner'
// import StravaAuthContext from '../context/strava_auth/StravaAuthContext'
import {
  createRideEventComment,
  deleteRideEvent, deleteRideEventComment, fetchHierarachicalRideEventComments,
  fetchRideEvent,
  fetchRideEvents,
  fetchRideRecords
} from '../utils/djangoApi'

import ScatterChartForTeamPerformance from '../components/ride_event/charts/ScatterChartForTeamPerformance'
import PieChartForClubTeamCount from '../components/ride_event/charts/PieChartForClubTeamCount'
import VerticalBarChartForCount from '../components/ride_event/charts/VerticalBarChartForCount'

import TableRideRecords from '../components/ride_event/TableRideRecords'
import RiderCountByCountry from '../components/ride_event/RiderCountByCountry'
import { CONST } from '../constants'
import {
  colorGenerator, getBadgeByStatus,
  // getBadgeByStatus,
  getCountryName,
  getDistanceKmMiles,
  getDistanceKmMilesByCountryCode,
  getDistanceKmMilesUnitByCountryCode,
  getFilenameFromPreSignedUrl,
  getFormattedDate1,
} from '../utils/commonUtils'
import RiderCountByGender from '../components/ride_event/RiderCountByGender'
import RiderCountByAge from '../components/ride_event/RiderCountByAge'
import Top10ElapsedTime from '../components/ride_event/Top10ElapsedTime'
import Top10ElapsedTimeForWomen from '../components/ride_event/Top10ElapsedTimeForWomen'
import Top10AvgSpeed from '../components/ride_event/Top10AvgSpeed'
import ShareButtons from '../components/layout/ShareButtons'
import {
  FaComments,
  FaDownload, FaEdit, FaFileAlt,
  FaMapMarkerAlt,
  FaRegCalendarCheck,
  FaRoute,
  FaShare, FaTrash,
  FaUserFriends
} from 'react-icons/fa'
import { TbTilde } from 'react-icons/tb'
import { BsDot } from 'react-icons/bs'
import FlagImg from '../components/img/FlagImg'
import { getStravaActivitiesWithQP, newLogin } from '../utils/stravaApi'
import SubmitButton from '../components/ride_event/SubmitButton'
import AuthContext from '../context/auth/AuthContext'
import ActivitySelect from '../components/ride_event/ActivitySelect'
import RiderListTable from '../components/ride_event/RiderListTable'
import { defaultImage } from '../components/img/defaultImages'
import { toast } from 'react-toastify'
import { MdLocationCity } from 'react-icons/md'
import { RiPinDistanceLine } from 'react-icons/ri'
import CommentList from '../components/ride_event/CommentList'
import CommentForm from '../components/ride_event/CommentForm'
import { comment } from 'postcss'
import NoDataAndBackButton from '../components/layout/NoDataAndBackButton'

// useReducer example
// https://www.w3schools.com/react/react_usereducer.asp

/*
rideEventData: {
  "id": 50,
  "name": "Function-based attitude-oriented process improveme",
  "distance_meter": 100000,
  "image": "https://storage.googleapis.com/ridearc1-ridearc-20231013-bucket-dev/https%3A/dummyimage.com/1280x720",
  "course_file1": null,
  "course_file2": null,
  "course_url": "https://www.mcmahon-perez.com/tag/poststerms.php",
  "chat_url": "https://stokes.com/main/search/wp-contentpost.asp",
  "description": "Seamless heuristic orchestration",
  "country_code": "SG",
  "cities": "East Angelamouth,East Melissa,West Lisaport",
  "start_at": "2023-10-28T03:38:50Z",
  "end_at": "2023-11-17T03:38:50Z",
  "image_thumbnail": "https://storage.googleapis.com/ridearc1-ridearc-20231013-bucket-dev/https%3A/placekitten.com/160/90",
  "created_by": null,
  "status": "UPCOMING",
  "rider_count": 100,
  "is_submittable": true,
  "created_at": "2023-10-27T02:03:45.345772Z",
  "updated_at": "2023-10-27T02:03:45.345776Z",
  "comments": [ <---------- 없는 경우 []
    {
      "id": 32,
      "text": "test comment 1",
      "rider_id": 101,
      "full_name": "Hojung Yun 🇰🇷🇸🇬",
      "strava_id": 89937081,
      "strava_profile_medium": "https://dgalywyr863hv.cloudfront.net/pictures/athletes/89937081/24071241/1/medium.jpg",
      "replies": []
    },
    ...
  }
 */

function RideEvent () {
  const isMounted = useRef(false)

  const { djangoProfile } = useContext(AuthContext)

  // const { userInfo, accessToken } = useContext(StravaAuthContext)
  // const [rideData, dispatch] = useReducer(reducer, initialData)

  const { geoLocationCountry } = useContext(LocationContext)
  const [rideRecords, setRideRecords] = useState()
  const [loading, setLoading] = useState(true)
  const [errorMsg, setErrorMsg] = useState('')

  const params = useParams()
  const { rideEventId } = params
  // console.log('--------> rideEventId:', rideEventId)
  const [rideEvent, setRideEvent] = useState()
  const [hierarchicalComments, setHierarchicalComments] = useState()

  const navigate = useNavigate()

  const colorGen = colorGenerator()
  const [myRideRecordSubmitted, setMyRideRecordSubmitted] = useState()

  const fetchAll = async () => {
    const promises = []
    promises.push(fetchRideEvent(rideEventId))
    promises.push(fetchRideRecords(rideEventId))
    promises.push(fetchHierarachicalRideEventComments(rideEventId))

    // Collect all the query results together into a single list
    return Promise.all(promises)
      .then((results) => {
        // console.log("------->>>>>> results:", results)
        // console.log("--------> results:", JSON.stringify(results, undefined, 2))
        return results
      })
    // .catch((error) => {
    //   console.error("failed to fetch all: ", error);
    // })
  }

  const updateRideEventAndRecords = async () => {
    // console.log('------->>>>>> [updateRideEventAndRecords] Fetch ride event and ride records !!!!!')
    try {
      const [
        rideEventData,
        rideRecordsData,
        hierarchicalComments,
      ] = await fetchAll()

      // console.log('------->>>>>> [RideEvent] rideEventData:', rideEventData)
      // console.log('------->>>>>> [RideEvent] rideRecordsData:', rideRecordsData)
      // console.log('------->>>>>> [RideEvent] hierarchicalComments:', hierarchicalComments)
      // console.log('--------> rideEventData:', JSON.stringify(rideEventData, undefined, 2))
      // console.log('--------> rideRecordsData:', JSON.stringify(rideRecordsData, undefined, 2))
      // console.log('--------> hierarchicalComments:', JSON.stringify(hierarchicalComments, undefined, 2))

      setRideEvent(rideEventData.data)
      setRideRecords(rideRecordsData.data)
      setHierarchicalComments(hierarchicalComments.data)

    } catch (e) {
      console.error('Error while fetching data for ride event, ride records, and hierarchical comments. e:', e)
      const msg = `${e.message} (${e.request?.responseURL})`
      setErrorMsg(msg)
    }
    setLoading(false)
  }

  useEffect(() => {
    updateRideEventAndRecords()

  }, [])

  useEffect(() => {
    // console.log('[RideEvent > useEffect] Either djangoProfile or rideRecords changed.')
    // console.log('[RideEvent > useEffect]  isMounted.current:', isMounted.current)

    if (djangoProfile !== undefined && rideRecords !== undefined) {
      // console.log('[RideEvent > useEffect]  djangoProfile and rideRecords are loaded. setting isMounted.current to TRUE now. ')
      isMounted.current = true
    }

    if (djangoProfile && rideRecords && rideRecords.length > 0) {
      // console.log('[RideEvent > useEffect] finding my ride record submitted. isMounted.current is ', isMounted.current)
      //   check if logged in user is submitted his/her activity
      // console.log('--------> djangoProfile:', djangoProfile)
      // console.log('--------> djangoProfile.username:', djangoProfile.username)
      for (const rideRecord of rideRecords) {
        if (djangoProfile.username === rideRecord.rider_username) {
          // console.log('------->>>>>> USER ACTIVITY SUBMITTED !!!')
          setMyRideRecordSubmitted(rideRecord)
          return
        }
      }
      // console.log('------->>>>>> USER ACTIVITY NOT SUBMITTED !!!')
      setMyRideRecordSubmitted(null)
    }
  }, [djangoProfile, rideRecords])

  // todo here !!!!!!!!!!!!!
  // todo here !!!!!!!!!!!!!
  // todo here !!!!!!!!!!!!!
  const onUserSubmitRevokeEventRecord = async (eventRecord) => {
    console.log('------->>>>>> [onUserSubmitRevokeEventRecord] checking isMounted.current:', isMounted.current)
    setMyRideRecordSubmitted(eventRecord)
    await updateRideEventAndRecords()
  }

  const onDeleteRideEvent = async () => {
    setLoading(true)
    try {
      const response = await deleteRideEvent(rideEventId)
      console.log('------->>>>>> response:', response)
      if (response.status === 204) {
        toast.success('Ride event has been removed', { autoClose: CONST.TOAST_AUTO_CLOSE })
        navigate('/ride-event-list')
        return
      }
      console.log('------->>>>>> Could not remove ride event. response:', response)
      toast.warning('Could not remove ride event', { autoClose: CONST.TOAST_AUTO_CLOSE })
    } catch (e) {
      console.log('------->>>>>> Could not remove ride event. e:', e)
      toast.error('Could not remove ride event', { autoClose: CONST.TOAST_AUTO_CLOSE })
    }
    setLoading(false)
  }

  const refreshCommentsForNested = async () => {
    const tempHierarchicalComments = hierarchicalComments
    setHierarchicalComments([])
    //<-- todo note: useState에서 nested 구조인 경우 내부에서 변경시 변경을 알 수 없어서 rerender가 되지 않음. 임시 해결책...... 개선 필요 !!!!
    try {
      const res = await fetchHierarachicalRideEventComments(rideEventId)
      if (res.status === 200) {
        setHierarchicalComments(res.data)
      }
    } catch (e) {
      console.log('Failed to fetch comments. e: ', e)
      toast.warn('Failed to fetch hierarachical comments', { autoClose: CONST.TOAST_AUTO_CLOSE })
      setHierarchicalComments(tempHierarchicalComments)
    }

  }

  const onCreateComment = async (new_comment_text, replyTo) => {
    console.log('--------> replyTo:', replyTo, '<---- type:', typeof replyTo)
    let data = { 'comment_text': new_comment_text }
    if (replyTo) {
      data['reply_to'] = replyTo
    } else {
      data['ride_event'] = rideEventId
    }

    try {
      let res = await createRideEventComment(data)
      console.log('--------> res:', res, '<---- type:', typeof res)
      if (res.status !== 201) {
        console.log('Failed to create a comment. res:', res)
        return
      }
      if (replyTo) {
        // todo note: nested comment의 경우만 새로 받음
        await refreshCommentsForNested()
      } else {
        setHierarchicalComments((prevState) => ([
          res.data,
          ...prevState,
        ]))
      }
    } catch (e) {
      console.log('Failed to create a comment. e:', e)
      toast.warn('Failed to create a comment', { autoClose: CONST.TOAST_AUTO_CLOSE })
    }
  }

  const onDeleteComment = async (rideEventCommentId, level) => {
    console.log('------->>>>>> rideEventCommentId: ', rideEventCommentId)
    console.log('------->>>>>> level: ', level)
    // level 1은 그냥 list에서 삭제. 1 초과시 refresh

    try {
      let res = await deleteRideEventComment(rideEventCommentId)
      if (res.status !== 204) {
        console.log('Failed to delete the comment. res:', res)
        return
      }
      if (level > 1) {
        // todo note: nested comment의 경우만 새로 받음
        await refreshCommentsForNested()
      } else {
        // const newCommentList = hierarchicalComments.filter((comment) => comment.id !== rideEventCommentId)
        // setHierarchicalComments(newCommentList)
        setHierarchicalComments((prevState) => prevState.filter((comment) => comment.id !== rideEventCommentId))
      }
    } catch (e) {
      console.log('Failed to delete the comment. e:', e)
      toast.warn(`Failed to delete the comment. ${e.message}`, { autoClose: CONST.TOAST_AUTO_CLOSE })
    }
  }

  if (loading || geoLocationCountry === undefined) {
    return (
      <div className="spinner-center">
        <Spinner/>
      </div>
    )
  }

  if (errorMsg) {
    return (
      <NoDataAndBackButton errorMsg={errorMsg}/>
    )
  }

  return (<>
    <div className="page-container">

      {/*  todo here - ride info */}
      {/*  todo here - submission */}
      {/*  todo here - chart */}
      {/*<pre>{data}</pre>*/}

      {/*{myRideRecordSubmitted && (*/}
      {/*  <div className="badge badge-lg badge-success mb-5">You submitted already</div>*/}
      {/*)}*/}

      {/*{rideEvent && rideEvent.status !== CONST.RIDE_EVENT_STATUS.PLANNING && userInfo ? (*/}
      {/*  <>*/}
      {/*    <div>test</div>*/}
      {/*  </>*/}
      {/*): rideEvent.status === CONST.RIDE_EVENT_STATUS.PLANNING }*/}

      {rideEvent && (
        <>
          {/* -------------------- */}
          {/* Absolute: 우상단 메뉴 */}
          {/* -------------------- */}
          {/*<div className="absolute top-5 right-3">*/}
          <div className="absolute top-5 right-5 flex flex-col items-center space-y-4 z-10">
            {/* note: 아래 소셜 공유 버튼을 버튼 리스트에 넣었으나 overflow로 인해 공유버튼들이 상단 레이어에 출력되지 않았음. 따라서 여기로 이동 */}
            <ShareButtons url={window.location.href}>
              <FaShare className="text-2xl"/>
            </ShareButtons>


            {/* Edit Link */}
            {rideEvent && djangoProfile?.is_staff && (
              <Link to={'/edit-ride-event'} state={{ rideEvent }}>
                <FaEdit className="text-2xl"/>
              </Link>
            )}

            {/* Edit Link */}
            {rideEvent && djangoProfile?.is_staff && (
              <FaTrash className="text-2xl fill-red-500 cursor-pointer" onClick={onDeleteRideEvent}/>
            )}

          </div>

          {/* ---------------- */}
          {/* Ride Event Info */}
          {/* ---------------- */}

          {/* Title */}
          <div className="section-title">
            {/*<span className={`badge ${getBadgeByStatus(rideEvent.status)} p-2`}>{rideEvent.status}</span>*/}
            <span className={getBadgeByStatus(rideEvent.status)}>{rideEvent.status}</span>
            <p>{rideEvent.name}</p>
          </div>


          {/* Ride details with photo */}
          {/*<div>*/}
          {/*  {rideEvent.photo_url && (*/}
          {/*    <img*/}
          {/*      className="float-right w-[50%] ml-2 rounded-2xl border"*/}
          {/*      // src={rideEvent.thumbnail_photo_url || defaultProfileImg}*/}
          {/*      src={rideEvent.photo_url}*/}
          {/*      alt="Photo"*/}
          {/*    />*/}
          {/*  )}*/}
          {/*  <p>{rideEvent.description}</p>*/}
          {/*</div>*/}

          {/* photo */}
          <div>
            {rideEvent.image && (
              <img
                className="w-full rounded-2xl border"
                // src={rideEvent.thumbnail_photo_url || defaultProfileImg}
                src={rideEvent.image || defaultImage.rideEventDefaultImg}
                onError={(e) => {
                  // todo note: (BEST) image 로드시 404 에러가 나오는 경우 기본 이미지 설정
                  e.target.src = defaultImage.rideEventDefaultImg
                }}
                alt="Photo"
              />
            )}
          </div>
          {/*<div>*/}
          {/*  {rideEvent.photo_url && (*/}
          {/*    <img*/}
          {/*      className="w-full rounded-2xl border"*/}
          {/*      // src={rideEvent.thumbnail_photo_url || defaultProfileImg}*/}
          {/*      src={rideEvent.photo_url}*/}
          {/*      alt="Photo"*/}
          {/*    />*/}
          {/*  )}*/}
          {/*</div>*/}

          {/* desc */}
          <div className="ride-item-row my-3">
            <p>{rideEvent.description}</p>
          </div>


          {/* Chat link */}
          {rideEvent.chat_url && (
            <div className="ride-item-row">
              <FaComments className="flex-none"/>
              <a href={rideEvent.chat_url} className="btn-link">Base Camp Chat</a>
            </div>
          )}

          {/* start datetime */}
          <div className="ride-item-row">
            <FaRegCalendarCheck className="flex-none"/>
            <span>Date: </span>
            <span>{getFormattedDate1(rideEvent.start_at)}</span>
            <TbTilde/>
            <span>{getFormattedDate1(rideEvent.end_at)}</span>
          </div>
          {/* start datetime */}
          {/*<div className="ride-item-row">*/}
          {/*  <FaRegCalendarCheck className="flex-none"/>*/}
          {/*  <span>Submission End Date: </span>*/}
          {/*  <span>{getFormattedDate1(rideEvent.end_at)}</span>*/}
          {/*</div>*/}


          {/* 국가 */}
          <div className="ride-item-row">
            <FaMapMarkerAlt className="flex-none"/>
            <span>Country: </span>
            <FlagImg country_code={rideEvent.country_code} className="w-5 shadow"/>
            <span>{getCountryName(rideEvent.country_code)}</span>
          </div>

          {/* 도시 */}
          <div className="ride-item-row">
            <MdLocationCity className="flex-none"/>
            <span>City: </span>
            <p>{rideEvent.cities}</p>
            {/*{rideEvent.cities.length > 0 && rideEvent.cities.map((item, index) => (*/}
            {/*  <div key={index} className="flex items-center">*/}
            {/*    <span>{item}</span>*/}
            {/*    <BsDot/>*/}
            {/*  </div>*/}
            {/*))}*/}
          </div>

          {/* Distance */}
          {rideEvent.distance_meter && (
            <div className="ride-item-row">
              <RiPinDistanceLine className="flex-none"/>
              <span>Distance: </span>
              <div className="text-2xl font-bold">
                <span>{getDistanceKmMilesByCountryCode(rideEvent.distance_meter, geoLocationCountry, 0)}</span>
                <span>{getDistanceKmMilesUnitByCountryCode(geoLocationCountry)}</span>
              </div>
            </div>
          )}

          {/* Route */}
          {rideEvent.course_url && (
            <div className="ride-item-row">
              <FaRoute className="flex-none"/>
              <span>Course URL: </span>
              <div className="flex items-center space-x-3">
                <a href={rideEvent.course_url} className="btn-link">Course</a>
              </div>
            </div>
          )}
          {rideEvent.course_file1 && (
            <div className="ride-item-row">
              <FaFileAlt className="flex-none"/>
              <span>{getFilenameFromPreSignedUrl(rideEvent.course_file1)}</span>
              <div className="flex items-center space-x-3">
                <a href={rideEvent.course_file1}>
                  <FaDownload className="flex-none"/>
                </a>
              </div>
            </div>
          )}
          {rideEvent.course_file2 && (
            <div className="ride-item-row">
              <FaFileAlt className="flex-none"/>
              <span>{getFilenameFromPreSignedUrl(rideEvent.course_file2)}</span>
              <div className="flex items-center space-x-3">
                <a href={rideEvent.course_file2}>
                  <FaDownload className="flex-none"/>
                </a>
              </div>
            </div>
          )}

          {rideEvent.status !== CONST.RIDE_EVENT_STATUS.UPCOMING && (
            <>
              {/* Attendees Count */}
              <div className="flex items-center space-x-2 mt-3">
                <FaUserFriends className="text-5xl"/>
                <p className="text-3xl">{rideEvent.rider_count}</p>
              </div>
            </>
          )}

        </>
      )}

      {rideEvent.status !== CONST.RIDE_EVENT_STATUS.UPCOMING && (
        <div className="flex mt-5">
          {/*<div className="divider"></div>*/}
          <SubmitButton djangoProfile={djangoProfile}
                        rideEvent={rideEvent}
                        rideEventStatus={rideEvent?.status}
                        myRideRecordSubmitted={myRideRecordSubmitted}
                        setMyRideRecordSubmitted={setMyRideRecordSubmitted}
                        onUserSubmitRevokeEventRecord={onUserSubmitRevokeEventRecord}
                        rideEventSubmittable={rideEvent?.is_submittable}
          />
        </div>
      )}

      {/* ******** */}
      {/* CHARTS */}
      {/* ******** */}
      {rideRecords && rideRecords.length > 0 && !isUndefined(geoLocationCountry) && (
        <div className="mt-5">
          {/*<div className="divider"></div>*/}

          <Top10ElapsedTime title="Top 10 Elapsed Time (Total)"
                            rideRecords={rideRecords}
                            color={colorGen.next().value}
          />
          <Top10ElapsedTime title="Top 10 Elapsed Time (Women)"
                            rideRecords={rideRecords}
                            color={colorGen.next().value}
                            filterParam={{ rider_sex: 'FEMALE' }}
          />
          <Top10ElapsedTime title="Top 10 Elapsed Time (Men)"
                            rideRecords={rideRecords}
                            color={colorGen.next().value}
                            filterParam={{ rider_sex: 'MALE' }}
          />

          <Top10AvgSpeed title="Top 10 AVG Speed (Total)"
                         rideRecords={rideRecords}
                         color={colorGen.next().value}
                         geoLocationCountry={geoLocationCountry}
          />
          <Top10AvgSpeed title="Top 10 AVG Speed (Women)"
                         rideRecords={rideRecords}
                         color={colorGen.next().value}
                         geoLocationCountry={geoLocationCountry}
                         filterParam={{ rider_sex: 'FEMALE' }}
          />
          <Top10AvgSpeed title="Top 10 AVG Speed (Men)"
                         rideRecords={rideRecords}
                         color={colorGen.next().value}
                         geoLocationCountry={geoLocationCountry}
                         filterParam={{ rider_sex: 'MALE' }}
          />


          <RiderCountByAge rideRecords={rideRecords}
                           color={colorGen.next().value}
          />
          <RiderCountByCountry rideRecords={rideRecords}
                               color={colorGen.next().value}
          />
          <RiderCountByGender rideRecords={rideRecords}
                              color={colorGen.next().value}
          />


          {/*<PieChartForClubTeamCount rideRecords={rideRecords}/>*/}
          <ScatterChartForTeamPerformance rideRecords={rideRecords} geoLocationCountry={geoLocationCountry}/>


          {/* Rider Table */}
          <RiderListTable rideRecords={rideRecords}
                          size={10}
                          geoLocationCountry={geoLocationCountry}
          />

          {/* PC를 위한 테이블 사용시 */}
          {/*<TableRideRecords rideRecords={rideRecords}/>*/}

        </div>
      )}

      {/* ********** */}
      {/* COMMENTS */}
      {/* ********** */}
      <h1 className="section-title">Comments</h1>
      <CommentForm onCreateComment={onCreateComment}/>
      {hierarchicalComments && hierarchicalComments.length > 0 && (
        <CommentList comments={hierarchicalComments}
                     level={1}
                     login_user_rider_id={djangoProfile?.id}
                     onCreateComment={onCreateComment}
                     onDeleteComment={onDeleteComment}
        />
      )}

    </div>

  </>)
}

export default RideEvent