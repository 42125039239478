import CommentList from './CommentList'
import defaultProfileImg from '../../img/anonymous.png'
import React, { useEffect, useState } from 'react'
import { getAbsoluteTimeToNowSince, shortEnglishHumanizer } from '../../utils/commonUtils'
import { toggleLikeComment } from '../../utils/djangoApi'
import { MdDelete, MdEdit, MdThumbUp, MdThumbUpOffAlt } from 'react-icons/md'
import { toast } from 'react-toastify'
import { CONST } from '../../constants'
import { useNavigate } from 'react-router-dom'
import { newLogin } from '../../utils/stravaApi'
import CommentForm from './CommentForm'

function CommentItem ({ comment, level, login_user_rider_id, onCreateComment, onDeleteComment }) {
  // console.log("--------> comment:", JSON.stringify(comment, undefined, 2))
  // --------> comment: {
  //   "id": 124,
  //   "ride_event": null,
  //   "comment_text": "asdf",
  //   "rider_id": 101,
  //   "full_name": "aaa bbb",
  //   "strava_id": 89937081,
  //   "strava_profile_medium": "https://dgalywyr863hv.cloudfront.net/pictures/athletes/89937081/24071241/1/medium.jpg",
  //   "strava_profile": "https://dgalywyr863hv.cloudfront.net/pictures/athletes/89937081/24071241/1/large.jpg",
  //   "replies": [],
  //   "created_at": "2023-10-29T07:24:40.052593Z",
  //   "updated_at": "2023-10-29T07:24:40.052620Z",
  //   "riders_who_like": []
  // }


  const [displayReply, setDisplayReply] = useState(false)

  // comment prop은 항상 값이 있으므로 초기값으로 사용
  const [stateComment, setStateComment] = useState(comment)
  const [liked, setLiked] = useState(false)

  useEffect(() => {
    if (login_user_rider_id) {
      for (const rider_who_likes of stateComment.riders_who_like) {
        if (rider_who_likes.id === login_user_rider_id) {
          setLiked(true)
          return
        }
      }
      setLiked(false)
    }
  }, [login_user_rider_id, stateComment])

  const toggleLike = async () => {
    if (!login_user_rider_id) {
      newLogin()
    }

    try {
      const res = await toggleLikeComment(stateComment.id)
      if (res.status === 200) {
        setStateComment(res.data)
      }

    } catch (e) {
      console.error('Failed to toggle like. ', e)
    }
  }


  return (
    <>
      <div className="flex items-start space-x-2 mt-3">
        <img
          className="rounded-full w-10 h-10"
          src={stateComment.strava_profile || defaultProfileImg}
          alt="Avatar"
        />
        <div className="flex flex-col">
          <div className="flex flex-col bg-neutral-200 px-3 py-1 rounded-xl">
            <span className="font-bold">{stateComment.full_name}</span>
            <span>{stateComment.comment_text}</span>
          </div>
          <div className="flex items-center space-x-10">
            <span>{getAbsoluteTimeToNowSince(stateComment.created_at)}</span>
            <div className="flex items-center space-x-1 text-xl">
              <a className="cursor-pointer text-blue-700" onClick={toggleLike}>
                {liked ? (
                  <MdThumbUp/>
                ) : (
                  <MdThumbUpOffAlt/>
                )}
              </a>
              <span>{stateComment.riders_who_like.length}</span>
            </div>
            <a className="cursor-pointer" onClick={() => setDisplayReply(!displayReply)}>Reply</a>
            {stateComment.rider_id === login_user_rider_id && (
              <MdDelete className="cursor-pointer text-red-600 text-xl" onClick={() => onDeleteComment(stateComment.id, level)}/>
            )}
          </div>
        </div>
      </div>

      {displayReply && (
        <CommentForm onCreateComment={onCreateComment} replyTo={stateComment.id}/>
      )}

      {stateComment.replies && stateComment.replies.length > 0 && (
        <CommentList comments={stateComment.replies}
                     level={level + 1}
                     login_user_rider_id={login_user_rider_id}
                     onCreateComment={onCreateComment}
                     onDeleteComment={onDeleteComment}
        />
      )}
    </>
  )
}

export default CommentItem