import { useEffect, useState } from 'react'
import _, { capitalize, isUndefined, truncate } from 'lodash'
import VerticalBarChartForCount from './charts/VerticalBarChartForCount'
import { getCategoryByAge, getCountryName, shortEnglishHumanizer } from '../../utils/commonUtils'
import HorizontalBarChart from './charts/HorizontalBarChart'
import { CONST } from '../../constants'

/*
        {
            "id": 93,
            "strava_activity_id": 9936505848,
            "distance": 46864.8,
            "moving_time": 16423,
            "elapsed_time": 17542,
            "elapsed_time_hour": 4.9,
            "start_date_local": "2013-03-28T12:05:09Z",
            "average_speed": 7.568,
            "age": 78,
            "team": "Team8",
            "club": "WCR",
            "rider_first_name": "Alejandro",
            "rider_last_name": "Hernandez",
            "rider_sex": "MALE",
            "rider_country_code": "KR",
            "rider_full_name": "Alejandro Hernandez"
        },
 */

const yKey = 'elapsedTime'
const xKey = 'fullname'

function Top10ElapsedTime ({ title, rideRecords, color, filterParam }) {

  const [data, setData] = useState()
  const [minElapsedTime, setMinElapsedTime] = useState()
  const [maxElapsedTime, setMaxElapsedTime] = useState()

  useEffect(() => {

    const filtered = _(rideRecords)
      .filter(filterParam)
      .map((item) => ({
        [xKey]: truncate(item.rider_full_name, {'length':15, 'omission':'...'}),
        // [yKey]: item.elapsed_time_hour,
        [yKey]: item.elapsed_time,
      }))
      .sortBy(yKey)
      .slice(0, 10)
      .value()
    // console.log('--------> filtered:', JSON.stringify(filtered, undefined, 2))
    setData(filtered)

    const elapsedTimes = _.map(filtered, yKey)
    // console.log('--------> elapsedTimes:', JSON.stringify(elapsedTimes, undefined, 2))
    // console.log('------->>>>>> _.max(elapsedTimes):', _.max(elapsedTimes))
    const max = _.max(elapsedTimes)
    const min = _.min(elapsedTimes)
    // const min = 0
    setMaxElapsedTime(max)
    setMinElapsedTime(_.round(min * 0.9, 1))
    // setMaxElapsedTime(_.max(elapsedTimes))
    // setMinElapsedTime(_.mix(elapsedTimes) * 0.95)

  }, [])

  if (isUndefined(minElapsedTime) || isUndefined(maxElapsedTime) || !data || data.length === 0) {
    return
  }

  return (
    <div className="">
      <h1 className="chart-title">{title}</h1>
      <div className="chart-container">

        <HorizontalBarChart
          data={data}
          yKey={yKey}
          xKey={xKey}
          color={color}
          domainMin={minElapsedTime}
          domainMax={maxElapsedTime}
          unit="hrs"
          yaxisTickFormatter={(value) => _.round(value / 60 / 60, 2)}
          tooltipFormatter={(value, name, props) => shortEnglishHumanizer(value * 1000)}
        />
      </div>
    </div>
  )
}

export default Top10ElapsedTime