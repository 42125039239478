import Spinner from '../components/layout/Spinner'
import { useContext, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import queryString from 'query-string'
// import { getAuthFromStrava } from '../context/strava_auth/StravaAuthActions'
import { fetchToken } from '../utils/djangoApi'
import { toast } from 'react-toastify'
import { CONST } from '../constants'
import AuthContext from '../context/auth/AuthContext'
import axios from 'axios'
import { getAuthFromStravaNew } from '../utils/stravaApi'
import { getCountryCode } from '../utils/commonUtils'
// import { stravaLogin } from '../utils/stravaApi'
// import StravaAuthContext from '../context/strava_auth/StravaAuthContext'
// import { STRAVA_AUTH_ACTION_TYPE } from '../context/strava_auth/StravaAuthReducer'
// import DjangoAuthContext from '../context/django_auth/DjangoAuthContext'
// import { isNull, isUndefined } from 'lodash'
// import _ from 'lodash'
// import { getAuthFromStrava } from '../utils/stravaSerivceNew'
// import * as querystring from 'querystring'

function StravaRedirect () {

  const { setStravaUser, saveDjangoProfile, logout } = useContext(AuthContext)
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    const postLoginProcess = async () => {
      console.log('location: ', location)

      try {
        // todo note: 1. strava 응답에서 code 가져오기
        //   성공시 - search: "?state=&code=fb4c83b27d4bb8511cba9fb1d4cb3242dcbfd27e&scope=read,activity:read"
        //   실패시 - search: "?state=&error=access_denied"
        let code = queryString.parse(location.search).code
        // console.log('--------> [StravaRedirect] code:', code)
        if (!code) {
          throw new Error('Failed to get code from redirect url')
        }

        // 	2) code를 가지고 strava로 auth 요청하고 access, refresh, exp, user info 등등을 받음
        // 		- user info를 AuthContext에 저장
        // 		- user info를 local storage에 저장
        // 		- access, refresh 토큰을 local storage에 저장
        const resFromStrava = await getAuthFromStravaNew(code)
        /*
          {
              "data": {
                  "token_type": "Bearer",
                  "expires_at": 1695143452,
                  "expires_in": 21600,
                  "refresh_token": "17728f94d04dcd8c286274ef7fddac76c8f31f29",
                  "access_token": "7ec043a3450c4b1761773c563307986280751912",
                  "athlete": {
                      "id": 89937081,
                      "username": "yhojung",
                      "resource_state": 2,
                      "firstname": "Hojung",
                      "lastname": "Yun 🇰🇷🇸🇬",
                      "bio": "",
                      "city": "",
                      "state": "",
                      "country": "Singapore",
                      "sex": "M",
                      "premium": false,
                      "summit": false,
                      "created_at": "2021-08-03T07:33:15Z",
                      "updated_at": "2023-01-24T08:16:34Z",
                      "badge_type_id": 0,
                      "weight": 90,
                      "profile_medium": "https://dgalywyr863hv.cloudfront.net/pictures/athletes/89937081/24071241/1/medium.jpg",
                      "profile": "https://dgalywyr863hv.cloudfront.net/pictures/athletes/89937081/24071241/1/large.jpg",
                      "friend": null,
                      "follower": null
                  }
              },
              "status": 200,
              "statusText": "",
           */
        const stravaAuthData = resFromStrava.data
        if (!stravaAuthData) {
          throw new Error('No data found from Strava auth')
        }
        const stravaUser = {
          id: stravaAuthData.athlete.id,
          profile_medium: stravaAuthData.athlete.profile_medium,
          profile: stravaAuthData.athlete.profile,
        }
        const stravaTokens = {
          refresh_token: stravaAuthData.refresh_token,
          access_token: stravaAuthData.access_token,
        }
        setStravaUser(stravaUser)
        localStorage.setItem(CONST.LOCAL_STORAGE_KEY.STRAVA_USER, JSON.stringify(stravaUser))
        localStorage.setItem(CONST.LOCAL_STORAGE_KEY.STRAVA_TOKENS, JSON.stringify(stravaTokens))

        // 	3) strava access token을 가지고 django로 auth 요청. access 받음 (django는 해당 user를 생성)
        // 		- django access, refresh를 local storage에 저장
        const resFromDjango = await fetchToken(stravaTokens.access_token, {
          ...stravaUser,
          countryCode: getCountryCode(stravaAuthData.athlete.country), //<-- stravaAuthData.athlete.country가 undefined 혹은 알 수 없는 나라인 경우 undefined 반환. undefined인 경우 server로 해당 데이터가 보내지지 않음
          sex: stravaAuthData.athlete.sex, //<-- M or F
        })
        /*
        resFromDjango: {
          "data": {
            "access": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjk1ODMzNTM1LCJpYXQiOjE2OTU2NjA3MzUsImp0aSI6Ijg1MzA5Yzc1MDhkMjRiNWFiYzQ0ZjdkMzM2OWU3ZjEwIiwidXNlcl9pZCI6MTA0fQ.zdAKNLGLNnHAdBeTZ5_C0DOmt8cRv283VxIOgqPovPc",
            "refresh": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoicmVmcmVzaCIsImV4cCI6MTcwMDg0NDczNSwiaWF0IjoxNjk1NjYwNzM1LCJqdGkiOiI2MDM3NjhjOGJlNjI0YWY0OWQzNGIxMTlmNTJlZWI3NiIsInVzZXJfaWQiOjEwNH0.M5zuQqWhV-1JnEMG73xWzoLmyX4d5AgSSDq3dU7OAOk",
            "user": {
              "pk": 104,
              "username": "yhojung",
              "email": "",
              "first_name": "Hojung",
              "last_name": "Yun 🇰🇷🇸🇬"
            }
          },
          "status": 200,
          "statusText": "OK",
          },
          ...
        }
         */
        const djangoAuthData = resFromDjango.data
        const djangoTokens = {
          access: djangoAuthData.access,
          refresh: djangoAuthData.refresh,
        }
        localStorage.setItem(CONST.LOCAL_STORAGE_KEY.DJANGO_TOKENS, JSON.stringify(djangoTokens))

        // 	4) django에서 profile 받기 (django access token 사용)
        // 		- profile 데이터를 AuthContext의 djangoProfile에 저장
        // 		- profile 데이터를 local storage에 저장
        // 		- djangoProfile의 property 중에 빈 값이 있으면 "force-to-get-user-info" 페이지로 이동
        // todo here - service 사용하기 !!!
        // todo here - service 사용하기 !!!
        // todo here - service 사용하기 !!!
        // todo here - service 사용하기 !!!
        // todo here - service 사용하기 !!!
        // todo here - service 사용하기 !!!
        // todo here - service 사용하기 !!!
        const response = await axios.get(
          `${process.env.REACT_APP_DJANGO_HOST}/api/profile/`,
          { headers: { 'Authorization': 'Bearer ' + djangoTokens.access } }
        )
        // console.log("------->>>>>> response:", response)
        /*response.data:
        {
          "id": 103,
          "sex": null,
          "email": null,
          "country_code": null,
          "dob": null,
          "club": null,
          "username": "yhojung",
          "first_name": "aaa",
          "last_name": "bbb"
        }
         */
        saveDjangoProfile(response.data)

        // 5) check profile values
        const profileValues = Object.values(response.data)
        for (const profileVal of profileValues) {
          if (!profileVal) {
            console.log("------->>>>>> profileVal is false. profileVal:", profileVal)
            navigate("/force-to-get-user-info")
            return
          }
        }
        navigate("/")

      } catch (e) {
        console.error('Failed to auth', e)
        toast.error('failed to auth', { autoClose: CONST.TOAST_AUTO_CLOSE })
        logout()
      }

      // await getAuthFromStrava(code)

      // const response = await stravaLogin(code)
      // console.log("--------> response:", response)
      // if (response.status === 200) {
      //   // context에 저장
      //   setStravaAuthData(response.data)
      //   // storage에 저장
      //   localStorage.setItem("stravaAuthData", JSON.stringify(response.data));
      //   // setAuthTokens(data);
      //   // setUser(jwt_decode(data.access));
      //   // localStorage.setItem("stravaAuth", JSON.stringify(data));
      // } else {
      //   console.log("------->>>>>> failed to get auth data from strava")
      // }


    }
    postLoginProcess()

  }, [])

  return (
    <div className="spinner-center">
      <Spinner/>
    </div>
  )
}

export default StravaRedirect
