import { useNavigate } from 'react-router-dom'
import EditUserInfo from '../components/profile/EditUserInfo'

// TODO NOTE: header/footer 없는 페이지
//  - 로그인 상태이나 displayName, country, city 정보가 없으면 오는 페이지
//  - 나중에 이메일 확인 기능도 여기서 구현

function ForceToGetUserInfo () {

  const navigate = useNavigate()
  const onSuccess = () => {
    navigate('/')
  }

  return (
    <div className="page-container items-center">
      <p className="section-title">Welcome !</p>
      <p>Please update your information.</p>
      <EditUserInfo onSuccess={onSuccess}/>
    </div>
  )
}

export default ForceToGetUserInfo