import { createContext, useEffect, useReducer, useState } from 'react'
// import Geocode from 'react-geocode'
import {
  setKey,
  setDefaults,
  setLanguage,
  setRegion,
  fromAddress,
  fromLatLng,
  fromPlaceId,
  setLocationType,
  geocode,
  RequestType,
} from 'react-geocode'

// Set default response language and region (optional).
// This sets default values for language and region for geocoding requests.
setDefaults({
  key: process.env.REACT_APP_GOOGLE_MAP_API_KEY, // Your API key here.
  language: 'en', // Default language for responses.
  region: 'es', // Default region for responses.
})

// // set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
// // eslint-disable-next-line
// // Geocode.setKey(process.env.REACT_APP_GOOGLE_MAP_API_KEY)
// Geocode.setKey(process.env.REACT_APP_GOOGLE_MAP_API_KEY)
//
// // set response language. Defaults to english.
// Geocode.setLanguage('en')

/*
react-geocod
https://github.com/shukerullah/react-geocode
 */

// todo note: 구조
//  1. app 시작
//  2. LocationContext 로드하여 현재 위치 파악. currentCountry 데이터 3가지 케이스
//    - undefined: 기본 currentCountry. 아직 위치 파악 전
//    - null: 승인 받지 못하거나 geolocation 알아내지 못하거나, 혹은 geolocation를 가지고 국가를 파악하지 못함
//    - <country_value>: 위치 파악 승인된고 위치 파악됨
//  3. 컴포에서 location 정보 필요시 이 context 로드 후 currentCountry 값이 null이면

const LocationContext = createContext()

export const LocationProvider = ({ children }) => {

  const [geoLocationCountry, setGeoLocationCountry] = useState()

  // 최초에 브라우저를 통해 위치를 가져오기
  useEffect(() => {
    // const getCurrentLocation = () => {
    //   // console.log("--------> navigator.geolocation:", navigator.geolocation)
    //   if (navigator.geolocation) {
    //     navigator.geolocation.getCurrentPosition(
    //       position => {
    //         // console.log("--------> position:", position)
    //         const { latitude, longitude } = position.coords
    //         let currentCountry
    //         fromLatLng(latitude, longitude).then(
    //           (response) => {
    //             for (let i = 0; i < response.results[0].address_components.length; i++) {
    //               for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
    //                 switch (response.results[0].address_components[i].types[j]) {
    //                   case 'country':
    //                     currentCountry = response.results[0].address_components[i].short_name
    //                     console.log('currentCountry detected by geolocation: ', currentCountry)
    //                     break
    //                 }
    //               }
    //             }
    //             // const location = {
    //             //   latitude,
    //             //   longitude,
    //             //   currentCountry,
    //             // }
    //             // console.log("--------> location:", location, "type:", typeof location)
    //             setGeoLocationCountry(currentCountry)
    //             localStorage.setItem('countryCode', currentCountry)
    //           },
    //           // (error) => { //<--- note: 에러가 발생해도 이 블럭이 실행되지 않았음.... 제거해도 되는 듯...
    //           //   console.error(error)
    //           //   dispatch({type: LOCATION_ACTION_TYPE.GET_FAILED_OR_DENIED_COORDINATES})
    //           // }
    //         )
    //       },
    //       error => {
    //         // todo note: 사용자가 geolocation을 deny하면 여기서 처리
    //         console.error('Error getting current location:', error)
    //         setGeoLocationCountry(process.env.REACT_APP_DEFAULT_COUNTRY)
    //         localStorage.setItem('countryCode', process.env.REACT_APP_DEFAULT_COUNTRY)
    //       }
    //     )
    //   } else {
    //     console.error(`Geolocation is not supported by this browser. Using the default country code. '${process.env.REACT_APP_DEFAULT_COUNTRY}'`)
    //     setGeoLocationCountry(process.env.REACT_APP_DEFAULT_COUNTRY)
    //     localStorage.setItem('countryCode', process.env.REACT_APP_DEFAULT_COUNTRY)
    //   }
    // }

    // 1. local storage에서 country 가져오기
    const countryCode = localStorage.getItem('countryCode')
    console.log("countryCode from localStorage:", countryCode)
    if (countryCode !== null) {
      setGeoLocationCountry(countryCode)
    } else {
      // 2. 실패시 geo에서 받은 country로 설정 (이것도 실패하면 기본값 사용)
      // getCurrentLocation()

      // todo note: mobile에서 위치를 가져오는데 실패함. 일단 기본 SG로 설정되도록 함
      setGeoLocationCountry(process.env.REACT_APP_DEFAULT_COUNTRY)
      localStorage.setItem('countryCode', process.env.REACT_APP_DEFAULT_COUNTRY)
    }
  }, [])

  const setCountryCode = (countryCode) => {
    setGeoLocationCountry(countryCode)
    localStorage.setItem('countryCode', countryCode)
  }

  return (
    <LocationContext.Provider value={{
      geoLocationCountry,
      setCountryCode,
    }}>
      {children}
    </LocationContext.Provider>
  )
}

export default LocationContext
