import { useLocation, useNavigate } from 'react-router-dom'
import React, { useContext, useEffect, useState } from 'react'
import LocationContext from '../context/location/LocationContext'
import { getStravaActivitiesWithQP } from '../utils/stravaApi'
import { toast } from 'react-toastify'
import { CONST } from '../constants'
import Spinner from '../components/layout/Spinner'
import { revokeRideRecord, submitRideRecord } from '../utils/djangoApi'
import {
  getDistanceKmMilesWithUnitByCountryCode,
  getFormattedDate1,
  getFormattedDatetime1
} from '../utils/commonUtils'

function StravaActivitySubmission () {

  const [errorMsg, setErrorMsg] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isRevoking, setIsRevoking] = useState(false)
  const navigate = useNavigate()

  const location = useLocation()
  const { rideEvent, myRideRecordSubmitted } = location.state
  //<-- todo note: 위 location.state으로 받은 데이터는 지연없이 바로 옴. 즉, useEffect로 모니터링 필요 없음

  /*
    --------> location: {
      "pathname": "/strava-activity-submission",
      "search": "",
      "hash": "",
      "state": {
        "rideEvent": {
          "id": 1,
          "name": "Fundamental zero-defect matrix",
          "status": "Active",
          "distance_meter": 90000,
          "thumbnail_photo_url": "https://picsum.photos/160/90",
          "photo_url": "https://picsum.photos/1280/720",
          "course_url": "https://www.chen-sanders.com/category/tags/listindex.asp",
          "chat_url": "http://www.reed-martin.biz/categoriesprivacy.htm",
          "description": "Focused zero-defect superstructure",
          "country_code": "SG",
          "cities": [
            "Johor Bahru",
            "Singapore"
          ],
          "start_at": "2023-09-20T08:05:47Z",
          "end_at": "2023-10-10T08:05:47Z",
          "rider_count": 1,
          "is_submittable": true,
          "created_at": "2023-10-02T02:08:24.587976Z",
          "updated_at": "2023-10-02T02:27:33.241446Z"
        },
        "myRideRecordSubmitted": {
          "id": 157,
          "strava_activity_id": 9901400011,
          "distance": 92384.4,
          "moving_time": 9777,
          "elapsed_time": 10653,
          "elapsed_time_hour": 3,
          "start_date_local": "2023-09-23T05:09:12Z",
          "average_speed": 9.449,
          "team": "TeamX",
          "club": "TMCR",
          "rider_username": "yhojung",
          "rider_sex": "FEMALE",
          "rider_country_code": "AD",
          "rider_full_name": "Hojung Yun 🇰🇷🇸🇬",
          "rider_age_cat": 20
        }
      },
      "key": "lx7vqyjq"
    }
   */

  const { geoLocationCountry } = useContext(LocationContext)
  const [stravaActivities, setStravaActivities] = useState()

  // 실제로 서버에 submit 완료된 RideRecord 저장
  const [submittedRideRecord, setSubmittedRideRecord] = useState(myRideRecordSubmitted || null)
  // form에서 사용자가 설정한 입력
  const [formData, setFormData] = useState({
    club: myRideRecordSubmitted?.club ?? '', //<-- todo note: undefined 보다 공백값인 ""으로 설정해야 에러가 나오지 않음
    team: myRideRecordSubmitted?.team ?? '',
    selectedActivityId: myRideRecordSubmitted?.strava_activity_id ?? '',
  })
  const { club, team, selectedActivityId } = formData

  // submit 버튼 disable에 사용. 또는 select 가능한 activity만 출력할 경우 사용
  const [selectableActivities, setSelectableActivities] = useState()

  // -------------------------
  // 최초 컴포 마운트시 1회만 실행
  // -------------------------
  useEffect(() => {

    const fetchMyActivities = async () => {
      // console.log('------->>>>>> start_at:', rideEvent.start_at)
      // console.log('------->>>>>> end_at:', rideEvent.end_at)
      // console.log('------->>>>>> rideEvent.distance_meter:', rideEvent.distance_meter)
      // console.log('------->>>>>> username:', djangoProfile.username)

      const startTimestamp = new Date(rideEvent.start_at).getTime() / 1000
      const endTimestamp = new Date(rideEvent.end_at).getTime() / 1000

      try {
        console.log('[ActivitySelect] Fetch strava activities !!!')
        const response = await getStravaActivitiesWithQP(
          startTimestamp,
          endTimestamp,
        )
        // console.log('------->>>>>> response for activities:', response)
        // console.log('--------> response:', JSON.stringify(response, undefined, 2))
        /*
          --------> response: {
            "data": [
              {
                "athlete": {
                  "id": 89937081,
                  "resource_state": 1
                },
                "name": "Evening Ride",
                "distance": 43425.4,
                "moving_time": 4220,
                "elapsed_time": 4456,
                "total_elevation_gain": 136,
                "type": "Ride",
                "sport_type": "Ride",
                "id": 9924023920,
                "start_date": "2023-09-26T12:37:52Z",
                "start_date_local": "2023-09-26T20:37:52Z",
                "timezone": "(GMT+08:00) Asia/Singapore",
                "utc_offset": 28800,
                "location_country": "Singapore",
                "kudos_count": 70,
                "average_speed": 10.29,
                "max_speed": 15.514,
                "average_cadence": 76.9,
                "average_watts": 203.7,
                "max_watts": 687,
                "weighted_average_watts": 225,
                "kilojoules": 859.7,
                "average_heartrate": 159.6,
                "max_heartrate": 175,
                "elev_high": 22.4,
                "elev_low": 5.4,
                ...
              },
           */

        // console.log("------->>>>>> response from activity request to Strava: ", response)

        // activity가 발견되지 않으면 일단 alert 출력하고 계속 진행
        if (response.data.length === 0) {
          toast.info('No activities found during that period', { autoClose: CONST.TOAST_AUTO_CLOSE })
        }

        // Filter for distance
        // const filteredActivities = response.data.filter((activity) => activity.distance + process.env.REACT_APP_TOLERANT_METERS > rideEvent.distance_meter)
        // console.log("------->>>>>> filteredActivities:", filteredActivities)

        // 데이터 가공하여 추가 필드 설정
        const activities = response.data.map((activity) => ({
          ...activity,
          applicable: activity.distance + parseInt(process.env.REACT_APP_TOLERANT_METERS) > rideEvent.distance_meter,
          is_submitted: activity.id === myRideRecordSubmitted?.strava_activity_id,
        }))
        setStravaActivities(activities)

        // selectableActivities 설정
        setSelectableActivities(() => {
          return activities.filter((activity) => activity.applicable)
        })

      } catch (e) {
        console.log('Failed to fetch strava activities. e:', e)
        toast.error('Failed to fetch strava activities', { autoClose: CONST.TOAST_AUTO_CLOSE })
      }
    }

    fetchMyActivities()
  }, [])

  if (stravaActivities === undefined) {
    return (
      <div className="spinner-center">
        <Spinner/>
      </div>
    )
  }

  if (stravaActivities.length === 0) {
    return (
      <div className="flex flex-col justify-center items-center h-80 space-y-5">
        <span>No Applicable Activities Found</span>
        <button className="btn btn-lg" onClick={()=>navigate(-1)}>Go Back</button>
      </div>
    )
  }

  const onRevoke = async () => {
    setIsRevoking(true)
    try {
      // const response = await revokeRideRecord(selectedActivity.id)
      const response = await revokeRideRecord(rideEvent.id)
      if (response.status === 204) {
        setSubmittedRideRecord(null)
        setFormData((prevState) => ({
          ...prevState,
          selectedActivityId: null,
        }))
        toast.success('Ride record has been revoked', { autoClose: CONST.TOAST_AUTO_CLOSE })
        navigate(-1)
        return
      }
      throw new Error('Response code is not 204.')
    } catch (e) {
      console.error('Failed to revoke your ride record. e:', e)
      toast.error('Failed to revoke your ride record', { autoClose: CONST.TOAST_AUTO_CLOSE })
    } finally {
      setIsRevoking(false)
    }
  }

  const onSubmit = async (e) => {
    // 이미지를 제외한 입력을 업데이트 (auth 및 profile in DB)
    e.preventDefault()
    setErrorMsg('')
    /*
    --------> selectedActivity: {
      "resource_state": 2,
      "athlete": {
        "id": 89937081,
        "resource_state": 1
      },
      "name": "Morning Ride",
      "distance": 99933.2,
      "moving_time": 11220,
      "elapsed_time": 13962,
      "total_elevation_gain": 338,
      "type": "Ride",
      "sport_type": "Ride",
      "workout_type": null,
      "id": 9811476103,
      "start_date": "2023-09-08T20:39:01Z",
      "start_date_local": "2023-09-09T04:39:01Z",
      "timezone": "(GMT+08:00) Asia/Singapore",
      "utc_offset": 28800,
      "location_city": null,
      "location_state": null,
      "location_country": "Singapore",
      "achievement_count": 55,
      "kudos_count": 73,
      "comment_count": 0,
      "athlete_count": 26,
      "photo_count": 0,
      "map": {
        "id": "a9811476103",
        "summary_polyline": "oe`G_yfxR{B|DeGv`@gB@}H{DgFy@uU@qI`BuJdJ{Uf`@uIlJgFbE{GnDoJfAoGoAuKsG}KmCmUwIwCAaMfByIvCeIzAoGY}FuBoQePe`@}Yqg@ak@iEiCkKkCgHgFcB{C{AcHoEeJuDoMyBwC}R`ImPrN_O|H}NnD_RzA}FKyZgHuS{BwVGmRdAsGKm@][eD}@kR`AqFvEcN`AgVr@{a@{DoVToT]qPe@sDiCsI{D_\\cIw[aFkWuCiVj@uEdH{Kl@eCQyC_EwKUcGn@}EtHyVDuF}BkXf@kGxB}FnBUdHbBtd@xQbLbHjKbDpFOvTyFzI{@tF@lSvCxE?tCu@tC}BxWae@jD}BbYsLbJaBh`@s@hl@jB`MApMgCrLEfCmAzE{EtGoO|LgNhJ{ChRwLdFy@vMNp@k@yFgH{@yEZ}CtE_NEmWxCk_@Syf@y@sLeC}LCkDdHcVPoB{CoKAaOeDoOZwBpFyJ~IoVlA{Ic@mGaHwQwCcMOoDv@yDhF}IrP_b@jHij@HoEq@uGwG{Q{CeFoEsD{NaIsLiNuBaE{IgXcFaG_x@}]eFiAon@U}YtB_Hi@_]sL}PiKmSsJDw@fFoJtJcJ`Q_W~Mu[|@_E^gGoBy[OuRxBqUhDaHy@cAyFmBo@oBd@qAhXqX}d@se@Lw@rEeE}CcDsGqAgc@|@uEw@gD}BoC{EoCeLjAk\\Q_GcDqFuJaEmA_EnCoKtAeWlAeDxBqBhR{JlLuA`l@sP~OyC`O?zBw@dVst@dCyLfCwEbCiH`DwEbZo}@`DuEdGyC~Fg@nFl@hkFjbBdEz@|W~AvDtB~CfFj@~DMtDaJzYY`CjAtXmAfMw`@dqAyJxYsKvSmBxAoEPcUqGsDpA{@vDtA`EtN|PpCnJvH~J|JxFvCxCrD?`f@f~AhFbNpBpHOdE~@zD@zHrPpm@wIjDdAvC`GnEjAbCDjC{BjH`ApJrB`JzHzR`WntAfAvBrFnDfAlB?|BeCpHtD`TbNvf@bCN`A~BxAIRn@dFbn@w@vXeCdB{Dx@aYa@oDtCmDPcAn@oAe@yCr@sHzG{@nAIbClJ|VaEvDgC`AqDt@PnVdAxBhBvAKn@gAZdAjChSf^vXhb@fJnJf_@bRn^tEbSzFbKlFxVrObM~FjMhKn@`SKtQ\\fDNj_@f@lF|K~N~T|UjBlEv@tV[hFaRr`@{JjQ{A|UaGjVaA~P_F|Ng]|vA_DxDoMnHc^`XmEpHcEbLyJna@cCjHyBvCmDdCoTvH",
        "resource_state": 2
      },
      "trainer": false,
      "commute": false,
      "manual": false,
      "private": false,
      "visibility": "everyone",
      "flagged": false,
      "gear_id": null,
      "start_latlng": [
        1.32,
        103.75
      ],
      "end_latlng": [
        1.3,
        103.76
      ],
      "average_speed": 8.907,
      "max_speed": 15.769,
      "average_cadence": 70.9,
      "average_temp": 27,
      "average_watts": 153.5,
      "max_watts": 848,
      "weighted_average_watts": 188,
      "kilojoules": 1722.2,
      "device_watts": true,
      "has_heartrate": true,
      "average_heartrate": 138.9,
      "max_heartrate": 180,
      "heartrate_opt_out": false,
      "display_hide_heartrate_option": true,
      "elev_high": 48.8,
      "elev_low": 6.6,
      "upload_id": 10517751204,
      "upload_id_str": "10517751204",
      "external_id": "garmin_ping_293647862205",
      "from_accepted_tag": false,
      "pr_count": 6,
      "total_photo_count": 0,
      "has_kudoed": false,
      "applicable": true
    }
     */
    setIsSubmitting(true)
    const selectedActivity = stravaActivities.find((activity) => activity.id === parseInt(selectedActivityId))
    const data = {
      strava_activity_id: selectedActivity.id,
      distance: selectedActivity.distance,
      moving_time: selectedActivity.moving_time,
      elapsed_time: selectedActivity.elapsed_time,
      start_date: selectedActivity.start_date,
      start_date_local: selectedActivity.start_date_local,
      timezone: selectedActivity.timezone,
      utc_offset: selectedActivity.utc_offset,
      location_country: selectedActivity.location_country,
      summary_polyline: selectedActivity.map.summary_polyline,
      start_latlng: selectedActivity.start_latlng,
      end_latlng: selectedActivity.end_latlng,
      average_speed: selectedActivity.average_speed,

      team,
      club,
      ride_event: rideEvent.id,
    }
    try {
      const response = await submitRideRecord(data)
      if (response.status === 200) {
        // setMyRideRecordSubmitted(response.data) //<-- todo note: 컴포 최상단으로 올라가 다시 내려옴
        // onUserSubmitRevokeEventRecord(response.data) //<-- todo note: 컴포 최상단으로 올라가 다시 내려옴
        toast.success('Your ride record has been submitted successfully', { autoClose: CONST.TOAST_AUTO_CLOSE })
        setSubmittedRideRecord(response.data)
        navigate(-1)
        return
      }
      throw new Error('Response code is not 200.')
    } catch (e) {
      console.log('Failed to submit ride record. e:', e)
      setErrorMsg(JSON.stringify(e.response.data, undefined, 2))
      toast.error('Failed to submit your ride record', { autoClose: CONST.TOAST_AUTO_CLOSE })
    }
    setIsSubmitting(false)
  }

  const onMutate = (e) => {



    setFormData((prevState) => ({
      ...prevState,
      // [e.target.id]: parseInt(e.target.value),
      [e.target.id]: e.target.value,
    }))
  }

  return (
    <div className="page-container">

      <h1 className="section-title">Strava Activity Submission</h1>

      <form className="page-form" onSubmit={onSubmit}>

        <div className="form-field">
          <label className="form-label">Strava Activities ({getFormattedDate1(rideEvent.start_at)} ~ {getFormattedDate1(rideEvent.end_at)})</label>
          <div className="flex flex-col space-y-2 mt-5">
            {stravaActivities.map((activity) => (
              <div key={activity.id}
                   className={`flex space-x-2 p-2 border rounded-xl ${activity.id === submittedRideRecord?.strava_activity_id && 'bg-green-200'}`}>
                <input type="radio"
                       name="radio-1"
                       id="selectedActivityId"
                       className="radio"
                       disabled={!activity.applicable}
                       onChange={onMutate}
                       value={activity.id}
                       checked={activity.id === parseInt(selectedActivityId)}
                />
                <label htmlFor={activity.id}
                       className={`truncate ${activity.applicable || 'text-neutral-300'}`}
                >
                  <div className="flex flex-col">
                    <div>
                      {getFormattedDatetime1(activity.start_date)}
                      &nbsp;
                      ({getDistanceKmMilesWithUnitByCountryCode(activity.distance, geoLocationCountry)})
                    </div>
                    <div className="">
                      <p className="truncate">{activity.name}</p>
                    </div>
                  </div>
                </label>
              </div>
            ))}
          </div>
        </div>

        {/* club */}
        <div className="form-field">
          <label className="form-label">Club</label>
          <input id="club"
                 type="text"
                 placeholder="Club"
                 className="input input-bordered w-full"
                 value={club}
                 onChange={onMutate}
          />
        </div>

        {/* team */}
        <div className="form-field">
          <label className="form-label">Team</label>
          <input id="team"
                 type="text"
                 placeholder="Team"
                 className="input input-bordered w-full"
                 value={team}
                 onChange={onMutate}
          />
        </div>

        <div className="flex justify-end h-8 text-red-600">
          {errorMsg}
        </div>

        {/* Buttons */}
        <div className="flex flex-col space-y-2">
          {/*  SUBMIT */}
          <button className="btn btn-primary"
                  type="submit"
                  disabled={selectableActivities && selectableActivities.length === 0}>
            {isSubmitting ? (
              <span className="loading loading-spinner"></span>
            ):(
              <span>Submit</span>
            )}
          </button>
          {/*  REVOKE */}
          {submittedRideRecord && (
            <button type="button" className="btn btn-warning" onClick={onRevoke}>
              {isRevoking ? (
                <span className="loading loading-spinner"></span>
              ):(
                <span>Revoke</span>
              )}
            </button>
          )}

        </div>
      </form>

    </div>

  )
}

export default StravaActivitySubmission