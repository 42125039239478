import { useEffect, useState } from 'react'
// import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'
import { ScatterChart, Scatter, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'
import humanizeDuration from 'humanize-duration'
import _ from 'lodash'
import { CONST } from '../../../constants'
import {
  getSpeedKphMphByCountryCode,
  getSpeedKphMphStrByCountryCode,
  getSpeedUnitByCountryCode,
  shortEnglishHumanizer
} from '../../../utils/commonUtils'

// Recharts
// https://github.com/recharts/recharts <---- github
// https://recharts.org/en-US/examples <---- examples
// https://blog.logrocket.com/top-8-react-chart-libraries/ <--- other charts

function ScatterChartForTeamPerformance ({ rideRecords, geoLocationCountry }) {

  const [rideRecordGroupedByTeam, setRideRecordGroupedByTeam] = useState()

  const [minMax, setMinMax] = useState({})

  useEffect(() => {
    const filteredRideRecords = rideRecords.filter((item) => item.team) //<-- Team이 명시되지 않으면 생략
    setMinMax({
      elapsedTimeMin: Math.min(...rideRecords.map(item => item.elapsed_time)),
      elapsedTimeMax: Math.max(...rideRecords.map(item => item.elapsed_time)),
      avgSpeedMin: Math.min(...rideRecords.map(item => item.average_speed)),
      avgSpeedMax: Math.max(...rideRecords.map(item => item.average_speed)),
    })
    const data = _.groupBy(filteredRideRecords, 'team')
    // console.log("--------> data:", data, "<---- type:", typeof data)
    setRideRecordGroupedByTeam(_.groupBy(filteredRideRecords, 'team'))
    /*
    {
      "Team1": [
        {
          "id": 100,
          "strava_activity_id": 9933256000,
          "distance": 10088.9,
          "moving_time": 18439,
          "elapsed_time": 15632,
          "elapsed_time_hour": 4.3,
          "start_date_local": "2022-01-01T19:26:02Z",
          "average_speed": 6.967,
          "age": 77,
          "team": "Team1",
          "club": "WCR",
          "rider_sex": "MALE",
          "rider_country_code": "MY",
          "rider_full_name": "Edward Werner"
        },
     */

  }, [])

  if (!rideRecordGroupedByTeam || Object.keys(rideRecordGroupedByTeam).length === 0) {
    return
  }

  return (
    <>
      <h1 className="chart-title">Team Performance</h1>
      <div className="chart-container">
        <ResponsiveContainer width="100%" height="100%">
          <ScatterChart
            // width={500}
            // height={300}
            margin={{
              top: 15,
              right: 0,
              left: 30,
              bottom: 30,
            }}
          >
            <CartesianGrid/>
            <XAxis dataKey="average_speed"
                   type="number"
                   name="AVG Speed"
                   tickFormatter={(value) => getSpeedKphMphStrByCountryCode(value, geoLocationCountry)}
                   domain={[
                     minMax.avgSpeedMin * 0.95,
                     minMax.avgSpeedMax * 1.05,
                   ]}
                   angle={CONST.BAR_CHART.XAXIS_ANGLE}
                   textAnchor="end"
                   fontSize={CONST.BAR_CHART.FONT_SIZE}
                   tickCount={10}
            />
            <YAxis dataKey="elapsed_time"
                   type="number"
                   width={15}
                   name="Elapsed Time"
                   tickFormatter={(value) => `${_.round(value / 60 / 60, 2)}\nhrs`}
                   domain={[
                     _.round(minMax.elapsedTimeMin * 0.95, 2),
                     _.round(minMax.elapsedTimeMax * 1.05, 2),
                   ]}
              // domain={[
              //   minMax.elapsedTimeMin * 0.95,
              //   minMax.elapsedTimeMax * 1.05,
              // ]}
                   fontSize={CONST.BAR_CHART.FONT_SIZE}
            />
            <Tooltip cursor={{ strokeDasharray: '3 3' }}
                     formatter={(value, name, props) => {
                       if (name === 'AVG Speed') {
                         const speed = getSpeedKphMphByCountryCode(value, geoLocationCountry)
                         const unit = getSpeedUnitByCountryCode(geoLocationCountry)
                         return `${speed} ${unit}`
                       }
                       if (name === 'Elapsed Time') {
                         return shortEnglishHumanizer(value * 1000)
                       }
                     }}
            />
            {Object.entries(rideRecordGroupedByTeam).map(([team, data], index) => (
              <Scatter name={team} data={data} fill={CONST.HEX_COLOR_CODES[index]} key={index}/>
            ))}
            {/*<Scatter name="A school" data={data} fill="#8884d8" />*/}
            {/*<Scatter name="B school" data={data2} fill="#FF4500" />*/}
            <Legend verticalAlign="top" height={36}/>
          </ScatterChart>
        </ResponsiveContainer>
      </div>
    </>
  )
}

export default ScatterChartForTeamPerformance