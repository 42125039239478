import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom'

// https://github.com/wojtekmaj/react-datetime-picker
// 1. npm i react-datetime-picker
// 2. copy css files here
// 3. update css files
import './assets/css/DateTimePicker.css';
import './assets/css/Calendar.css';
import './assets/css/Clock.css';

// NOTE: App.js에서 useLocation()을 사용해 현재 path를 가져오기 위해 여기서 <Router>로 감싸기
ReactDOM.createRoot(document.getElementById('root')).render(
  <Router>
    <App/>
  </Router>
)
