import { useLocation, useNavigate } from 'react-router-dom'
import RideEventForm from '../../components/mgmt/RideEventForm'
import { createRideEvent, updateRideEvent } from '../../utils/djangoApi'
import { toast } from 'react-toastify'
import { CONST } from '../../constants'

function EditRideEvent () {

  const navigate = useNavigate()

  const location = useLocation()
  const { rideEvent } = location.state
  console.log('--------> rideEvent:', JSON.stringify(rideEvent, undefined, 2))
  /*
  --------> rideEvent: {
    "id": 51,
    "name": "xxx",
    "distance_meter": 90000,
    "image": "https://storage.googleapis.com/ridearc-bucket-1/ride_event_images/81c269b2-649f-4e1f-a95b-1f9c6a2f5b74/ride_event_images-ebd5d2ec-c11e-40e6-9c3.jpeg?X-Goog-Algorithm=GOOG4-RSA-SHA256&X-Goog-Credential=service-account-to-upload-file%40ridearc-dev.iam.gserviceaccount.com%2F20231006%2Fauto%2Fstorage%2Fgoog4_request&X-Goog-Date=20231006T102933Z&X-Goog-Expires=300&X-Goog-SignedHeaders=host&X-Goog-Signature=2efca1979ffb81bae1f59ab33fe43148dd04ccf480daaeefd082a8e8290022ae9b995bc23d37c8e91d0a1309fef77d3436f240fa222a67d4b13084b4faa1774aeea0a68d8b8fc1bc83a9fa6b96d6293bd208bb6819a768911c2828f1bbba0294078cd8cd455715a3f7b479fbc8cd3133f537a40ad9287706e35aa0af8a286c94e6f0b486d790a91bb738a98ad77cba7e7fad3cebad6d5aeb79f1f129c9faebecb78d0d979f3ac0b79c94f9c8819935084b8007380e37460cd9d0067be5ada71aab36e75690f3cf7aac32c0ec9a1b1c5c19b81050f048a2573246cb3f7ab46d27d685620b3ebcc18b623b76508e89abbb4648a420ed182c4655fb9f9d4a2e8dbb",
    "image_thumbnail": "https://storage.googleapis.com/ridearc-bucket-1/ride_event_images/bf278b9b-c1d8-4eb2-aeaa-16488b03da49/ride_event_images-ebd5d2ec-c11e-40e6-9c3.jpeg?X-Goog-Algorithm=GOOG4-RSA-SHA256&X-Goog-Credential=service-account-to-upload-file%40ridearc-dev.iam.gserviceaccount.com%2F20231006%2Fauto%2Fstorage%2Fgoog4_request&X-Goog-Date=20231006T102933Z&X-Goog-Expires=300&X-Goog-SignedHeaders=host&X-Goog-Signature=7fa3ade1a51c842cd8c5ab764bdf3c34de468ec30f1a53863f1dc2b58e2a1b6b3cb6c81398344befbf31c73e63bb395c492b8ea97a1efcc68f393079c26582181833a6fce7c0821887b11580cbf09db4df56bcaadb78645db477e6b6af7b97f43f6e7306f99936ffcb58754775e6bb59fd252cc17171e3780afb7a65459007fd72da4bea716fbd09254522311a55b7951c8d5783201c5857b3eceb4606b50476c091b579cacc8f97d22ba8fc7bdb71c3d87c1d21c6edf88fde81a129865b19e00b70e0455d8a92d28232f0c737915c2c049df09743daefc1dead5ca25d56d68263e6b8a6e5a2781cea8477b25d1309e3d64c799fef10d76bd02d7833fcf550ab",
    "course_file1": "https://storage.googleapis.com/ridearc-bucket-1/ride_event_files/3da3de94-5fa3-4971-8724-21786c4b3db3/COURSE_3213016_1.fit?X-Goog-Algorithm=GOOG4-RSA-SHA256&X-Goog-Credential=service-account-to-upload-file%40ridearc-dev.iam.gserviceaccount.com%2F20231006%2Fauto%2Fstorage%2Fgoog4_request&X-Goog-Date=20231006T102933Z&X-Goog-Expires=300&X-Goog-SignedHeaders=host&X-Goog-Signature=4ef574903b044d315c6d28b4460db9ff6b472fcb885d86af7223244ea485a357007ed3c90a20a95776e8330a4c47106d6e416f082c1bc26a23a08e40efd27c59d290412d15ace1062cfa493d563dbad922fe8b1268d4d11d1a549f68e4951fe1b389caf89cbc6d147f7a117618362a8d7e7ba0e7609e1fe63cc1fd8d986d471cc30631ec30f77922ff012756ae3f57a43759fcdb85655726a86245158411e39ee7e4e743c1b1478c10833830a025f1739fbb59065fe9e6b0cf0ccda437a17bdd375e6468685b47111fa60a461543847574fccb51cd2596b02a534fd8f4fac617564fc2c339c427cc958a7c3ed9f2c8176bc0212aa8758ffc40e401bc3cf318fa",
    "course_file2": null,
    "course_url": "http://xxx.com/xxx",
    "chat_url": "http://xxx.com/xxx",
    "description": "xxx",
    "country_code": "SG",
    "cities": "xxx,yyy",
    "start_at": "2023-09-01T09:56:31Z",
    "end_at": "2023-10-11T09:56:31Z",
    "created_by": 104,
    "status": "Active",
    "rider_count": 1,
    "is_submittable": true,
    "created_at": "2023-10-06T09:57:20.004539Z",
    "updated_at": "2023-10-06T10:21:04.530443Z"
  }
   */

  const update = async (editedRideEvent, rideEventId) => {
    // console.log("--------> editedRideEvent:", JSON.stringify(editedRideEvent, undefined, 2))

    /* CASE 1 - 모든 필드가 입력된 경우:
    --------> editedRideEvent: {
      "name": "Focused disintermediate challenge",
      "distance_meter": 600000,
      "course_url": "https://ibarra.com/applogin.php",
      "chat_url": "http://www.richardson-christensen.com/categories/listlogin.html",
      "description": "Switchable regional open architecture",
      "country_code": "SG",
      "cities": "New Danielfurt,Micheleville,Clarkshire",
      "start_at": "2023-10-25T16:00:00.000Z",
      "end_at": "2023-11-15T15:59:59.999Z",
      "image": {}, <------------ File 객체 설정됨
      "course_file1": {}, <------------ File 객체 설정됨
      "course_file2": {} <------------ File 객체 설정됨
    }
     */

    /* CASE 2 - 파일 입력 필드를 지정하지 않은 경우: (필드 자체가 없음)
    --------> editedRideEvent: {
      "name": "Focused disintermediate challenge",
      "distance_meter": 600000,
      "course_url": "https://ibarra.com/applogin.php",
      "chat_url": "http://www.richardson-christensen.com/categories/listlogin.html",
      "description": "Switchable regional open architecture",
      "country_code": "SG",
      "cities": "New Danielfurt,Micheleville,Clarkshire",
      "start_at": "2023-10-25T16:00:00.000Z",
      "end_at": "2023-11-15T15:59:59.999Z"
    }
     */

    /* CASE 3 - 파일 삭제를 위한 필드는 빈칸으로 입력됨
    --------> editedRideEvent: {
      "name": "Focused disintermediate challenge",
      "distance_meter": 600000,
      "course_url": "https://ibarra.com/applogin.php",
      "chat_url": "http://www.richardson-christensen.com/categories/listlogin.html",
      "description": "Switchable regional open architecture",
      "country_code": "SG",
      "cities": "New Danielfurt,Micheleville,Clarkshire",
      "start_at": "2023-10-25T16:00:00.000Z",
      "end_at": "2023-11-15T15:59:59.999Z",
      "course_file2": "" <---------- 파일 삭제 요청
    }
     */

    try {
      const response = await updateRideEvent(editedRideEvent, rideEventId)
      // console.log('------->>>>>> response:', response)
      if (response.status === 200) {
        toast.success('Ride event has been updated', { autoClose: CONST.TOAST_AUTO_CLOSE })
        navigate(`/ride-event-list/${response.data.id}`)
      } else {
        console.log("[EditRideEvent] response to ride event update req:", response)
        toast.warn('Failed to update ride event', { autoClose: CONST.TOAST_AUTO_CLOSE })
      }
    } catch (e) {
      console.log('[EditRideEvent] Failed to update ride event. e:', e)
      console.log("--------> e.response.data:", JSON.stringify(e.response.data, undefined, 2))
      const status = e.response.status
      const detail = e.response.data?.detail
      let errMsg = ''
      if (status && detail) {
        errMsg = `Status(${status}): ${detail}`
      }
      toast.error('Failed to update ride event.', { autoClose: CONST.TOAST_AUTO_CLOSE })
      return errMsg
    }
  }

  return (
    <div className="page-container">
      <div className="section-title">
        Edit Ride Event
      </div>
      <RideEventForm rideEvent={rideEvent} createOrUpdate={update} />
    </div>
  )
}

export default EditRideEvent