import RiderListTable from '../components/ride_event/RiderListTable'
import { useLocation } from 'react-router-dom'

function RideListPage () {

  const location = useLocation()
  const { rideRecords, geoLocationCountry } = location.state

  return (
    <div className="page-container">
      <RiderListTable rideRecords={rideRecords}
                      geoLocationCountry={geoLocationCountry}
      />
    </div>
  )
}

export default RideListPage