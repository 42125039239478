import { useNavigate, useParams } from 'react-router-dom'
import { useContext, useEffect, useMemo, useReducer, useState } from 'react'
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table'
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'
import humanizeDuration from 'humanize-duration'
import _ from 'lodash'
import FlagImg from '../img/FlagImg'
import { getCountryName } from '../../utils/commonUtils'

// Mantine React Table
// https://github.com/KevinVandy/mantine-react-table

// todo here - !!!!!!!!!!!!
// todo here - !!!!!!!!!!!!
// todo here - !!!!!!!!!!!!
// todo here - !!!!!!!!!!!!
// todo here - !!!!!!!!!!!!
// todo here - !!!!!!!!!!!!
// todo here - !!!!!!!!!!!!
// todo here - !!!!!!!!!!!!
// todo here - !!!!!!!!!!!!
//  - v 테이블 컴포는 data 받아서 처리
//  - v server: ride record 모델에서 age 필드 추가
//  - 다른 chart들 계속 생성
//  - table에 다른 컬럼 추가 (strava activity 링크도 추가)
//  - ride event 페이지 정보 칼럼 꾸미기
//  - ride event 페이지에서 submit 꾸미기 (로그인 유무에 따른 제어 필요)
//  - server: rider 모델에서 strava profile 링크 필드 추가
//  - sso 로그인시 rider 정보가 비는 것이 있으면 자동으로 rider 업데이트 페이지로 이동. 서버는 '로그인 사용자'의 rider 데이터를 생성/업데이트하는 view 필요
//  - server: rider 모델에서 club을 main_club으로 변경. none 가능 (클럽에 가입하지 않은 경우 있음)
//  - server: club 모델에서 country_code 추가. 나라/이름 중복 방지 설정
//  - server: ride record에서 club 필드 추가. none 가능. (이벤트를 다른 클럽아래 참가했을 수 있으므로)
//  - server: ride record 'submit'시 나이는 해당일 자동 계산하여 ride record 데이터에 자동 입력되로록 함

function TableRideRecords ({ rideRecords }) {

  useEffect(() => {
    // console.log('--------> data:', data)
    rideRecords.sort((a, b) => a.rider_full_name - b.rider_full_name)
  }, [])

  // //should be memoized or stable
  // const columns = useMemo(
  //   () => [
  //     {
  //       accessorKey: 'name', //simple recommended way to define a column
  //       header: 'Name',
  //       mantineTableHeadCellProps: { style: { color: 'green' } }, //optional custom props
  //       Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
  //     },
  //     {
  //       accessorFn: (row) => row.age, //alternate way
  //       id: 'age', //id required if you use accessorFn instead of accessorKey
  //       header: 'Age',
  //       Header: () => <i>Age</i>, //optional custom header render
  //     },
  //   ],
  //   [],
  // )

  //should be memoized or stable
  const columns = useMemo(
    () => [
      {
        accessorKey: 'rider_full_name', //simple recommended way to define a column
        header: 'Name',
      },
      {
        accessorKey: 'rider_country_code', //simple recommended way to define a column
        header: 'Country',
        Cell: ({ cell }) => (
          <div className="flex space-x-1">
            <FlagImg country_code={cell.getValue()} className="w-5 shadow"/>
            <span>{getCountryName(cell.getValue())}</span>
          </div>
        ), //optional custom cell render
      },
      // {
      //   accessorKey: 'team', //simple recommended way to define a column
      //   header: 'Team',
      // },
      // {
      //   accessorKey: 'rider_club', //simple recommended way to define a column
      //   header: 'Club',
      // },
    ], [],)


  // Table options
  // https://www.mantine-react-table.com/docs/guides/density-toggle
  const table = useMantineReactTable({
    columns,
    data: rideRecords,
    enableColumnOrdering: true, //enable some features
    enablePagination: false, //disable a default feature
    enableColumnDragging: false,
    initialState: {
      density: 'xs',
      sorting: [ //<-- 초기 정렬
        {
          id: 'rider_full_name', //sort by age by default on page load
          desc: false,
        },
        {
          id: 'rider_country_code', //then sort by lastName if age is the same
          desc: false,
        },
      ],
    },
  })

  return (
    <>
      <h1 className="chart-title">Riders</h1>
      <MantineReactTable table={table}/>
    </>
  )
}

export default TableRideRecords