import { CONST } from '../../constants'
import ActivitySelect from './ActivitySelect'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { newLogin } from '../../utils/stravaApi'

function SubmitButton ({
  djangoProfile,
  rideEvent,
  myRideRecordSubmitted, //<--null:not sumitted, value:submitted
  setMyRideRecordSubmitted,
  onUserSubmitRevokeEventRecord,
  removeEventRecord,
  submitEventRecord,
}) {

  /*
  djangoProfile: undefined, {...}
  rideEvent: undefined, {...}
  activitySubmitted: undefined, true/false. 내 activity submit 유무
  removeEventRecord: 자신이 submit한 eventRecord를 삭제시 호출
  submitEventRecord:내 activity 리스트를 출력하는 컴포를 출력하도록 호출

  [조건별 출력]
  login
v      complete: completed/you have submitted <--- after checking if my records exists
      active: submit your activity/you have submitted - delete? <----
v      planning: hidden
  logout
v      complete: completed
v      active: login to submit
v      planning: hidden
   */

  const navigate = useNavigate()

  // console.log('------->>>>>> -----------------------------------------')
  // console.log('------->>>>>> djangoProfile:', djangoProfile)
  // console.log('------->>>>>> rideEvent:', rideEvent)
  // console.log('------->>>>>> myRideRecordSubmitted:', myRideRecordSubmitted)

  const [displayActivities, setDisplayActivities] = useState(false)

  // const buttonToDisplayStravaActivities = () => {
  //   return (
  //     <>
  //       <button className="ride-record-button-outline"
  //               onClick={() => setDisplayActivities(!displayActivities)}
  //       >
  //         {displayActivities ? 'Hide My Strava Activities' : 'Display My Strava Activities to ' + (myRideRecordSubmitted ? 'Edit' : 'Submit')}
  //       </button>
  //       {displayActivities && (
  //         <ActivitySelect rideEvent={rideEvent}
  //                         djangoProfile={djangoProfile}
  //                         myRideRecordSubmitted={myRideRecordSubmitted}
  //                         setMyRideRecordSubmitted={setMyRideRecordSubmitted}
  //                         onUserSubmitRevokeEventRecord={onUserSubmitRevokeEventRecord}
  //         />
  //       )}
  //     </>
  //   )
  // }

  const buttonToDisplayStravaActivities = () => {
    return (
      <>
        {/* todo note: naviate()로 state 전달가능. key는 state해야 하고 목적지 컴포에서 useLocation()으로 받음 */}
        <button className="ride-record-button-outline"
                onClick={() => navigate('/strava-activity-submission', {state:{rideEvent, myRideRecordSubmitted}})}
        >
          {myRideRecordSubmitted ? 'Edit your submission':'Submit your strava activity'}
          {/*{(myRideRecordSubmitted && 'Revoke/RE-') + 'Submit Your Strava Activity'}*/}
        </button>
      </>
    )
  }
  const buttonToLogin = () => {
    return (
      <>
        {/* todo note: naviate()로 state 전달가능. key는 state해야 하고 목적지 컴포에서 useLocation()으로 받음 */}
        <button className="ride-record-button-yellow"
                onClick={newLogin}
        >
          Login To Submit Your Ride Record
        </button>
      </>
    )
  }

  if (rideEvent?.status === CONST.RIDE_EVENT_STATUS.OPEN) {
    if (djangoProfile) {
      return buttonToDisplayStravaActivities()
    } else {
      return buttonToLogin()
      // return (
      //   <>
      //     <button className="ride-record-button-yellow">
      //       Login To Submit Your Ride Record
      //     </button>
      //   </>
      // )
    }
  }

  if (rideEvent?.status === CONST.RIDE_EVENT_STATUS.CLOSED) {
    if (djangoProfile) {
      // 로그인 상태
      if (myRideRecordSubmitted) {
        // Submit함
        if (rideEvent?.is_submittable) {
          // 아직 re-submit 가능
          return buttonToDisplayStravaActivities()
        } else {
          // re-submit 불가
          return (
            <>
              <div className="ride-record-button-gray">
                Event Closed
              </div>
            </>
          )
        }
      } else {
        // Submit하지 않음
        if (rideEvent?.is_submittable) {
          // 아직 submit 가능
          return buttonToLogin()
          // return (
          //   <>
          //     <button className="ride-record-button-yellow">
          //       Login To Submit Your Ride Record
          //     </button>
          //   </>
          // )
        } else {
          // submit 불가
          return (
            <>
              <button className="ride-record-button-gray">
                Event Closed
              </button>
            </>
          )
        }

      }

    } else {
      // 비로그인 상태
      if (rideEvent?.is_submittable) {
        // 아직 submit 가능
        return buttonToLogin()
        // return (
        //   <>
        //     <button className="ride-record-button-yellow">
        //       Login To Submit Your Ride Record
        //     </button>
        //   </>
        // )
      } else {
        // submit 불가
        return (
          <>
            <button className="ride-record-button-gray">
              Event Closed
            </button>
          </>
        )
      }



    }
  }
}

export default SubmitButton