import { Navigate, Outlet } from 'react-router-dom'
// import { useAuthStatus } from "../../hooks/useAuthStatus"
import { useContext, useEffect, useState } from 'react'
// import StravaAuthContext from './context/strava_auth/StravaAuthContext'
import Spinner from './components/layout/Spinner'
// import { isTokenValid, stravaLogin } from './context/strava_auth/StravaAuthActions'
// import { STRAVA_AUTH_ACTION_TYPE } from './context/strava_auth/StravaAuthReducer'
import AuthContext from './context/auth/AuthContext'
import { newLogin } from './utils/stravaApi'

const PrivateRoutes = () => {

  const { djangoProfile } = useContext(AuthContext)
  // const { expiresAt, dispatch } = useContext(StravaAuthContext)
  // const [loggedIn, setLoggedIn] = useState()
  //
  useEffect(() => {
    // console.log('------->>>>>> [PrivateRoutes] djangoProfile:', djangoProfile)
    if (djangoProfile === null) {
      // login()
      newLogin()
    }

    // const login = async () => {
    //   await stravaLogin()
    // }
    //
    // // todo note: check if valid
    // // todo note: check if valid
    // if (expiresAt && isTokenValid(expiresAt)) {
    //   setLoggedIn(true)
    // } else {
    //   dispatch({type: STRAVA_AUTH_ACTION_TYPE.LOGOUT_RESET})
    //   setLoggedIn(false)
    //   login()
    // }

  }, [djangoProfile])

  // // checkingStatus는 Spinner 출력을 위함
  // if (djangoProfile === undefined) {
  //   return <Spinner/>
  // }
  // if (loggedIn === undefined) {
  //   return <Spinner/>
  // }
  // return loggedIn ? <Outlet/> : <Spinner/>
  return djangoProfile ? <Outlet/> : (
    <div className="spinner-center">
      <Spinner/>
    </div>
  )

}

export default PrivateRoutes
