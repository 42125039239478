import {memo} from "react";
import {
  FacebookIcon,
  FacebookShareButton, LinkedinIcon, LinkedinShareButton, RedditIcon, RedditShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton, WhatsappIcon, WhatsappShareButton
} from 'react-share'
// import { FaShare } from 'react-icons/fa'
import { CONST } from '../../constants'

// share buttons
// https://www.makeuseof.com/add-social-share-buttons-in-react/

const SHARE_BUTTONS = [
  [TwitterShareButton, TwitterIcon],
  [FacebookShareButton, FacebookIcon],
  [TelegramShareButton, TelegramIcon],
  [WhatsappShareButton, WhatsappIcon],
  [LinkedinShareButton, LinkedinIcon],
  [RedditShareButton, RedditIcon],
]

// function ShareButtons ({ className, children }) {
function ShareButtons ({ children, url, clasName, ulClassName}) {
  return (
    // <div className={className}>
    //   <div className="dropdown dropdown-left">
      <div className={clasName}>
        {/* todo note: 아래를 label로 해야 모바일 환경에서 정상적으로 출력됨 */}
        {/* https://daisyui.com/components/dropdown/ */}
        <label tabIndex="0">
          {children}
          {/*<FaShare/>*/}
        </label>
        {/*<ul tabIndex="0" className="dropdown-content mr-3 -mt-5">*/}
        <ul tabIndex="0" className={ulClassName}>
          {SHARE_BUTTONS.map(([SButton, SIcon], index) => (
            <li key={index} className="mt-1">
              <SButton url={url}
                       quote={CONST.SHARE_INFO.QUOTE}
                       hashtag={CONST.SHARE_INFO.HASHTAG}>
                <SIcon size={CONST.SHARE_INFO.SIZE} round/>
              </SButton>
            </li>
          ))}
        </ul>
      </div>
    // </div>
  )
}

// 기본값은 share 버튼들이 아래로 출력됨
ShareButtons.defaultProps = {
  clasName: "dropdown dropdown-left",
  ulClassName: "dropdown-content mr-5 -mt-5",
}

export default memo(ShareButtons)