import { useEffect, useState } from 'react'
// import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'
// import { ScatterChart, Scatter, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'
import { PieChart, Pie, Sector, Cell, ResponsiveContainer, Tooltip, Legend } from 'recharts'
import humanizeDuration from 'humanize-duration'
import _ from 'lodash'
import { CONST } from '../../../constants'
import { getSpeedKphMphStrByCountryCode } from '../../../utils/commonUtils'

// Recharts
// https://github.com/recharts/recharts <---- github
// https://recharts.org/en-US/examples <---- examples
// https://blog.logrocket.com/top-8-react-chart-libraries/ <--- other charts



const hexColorCodes = [
  '#0088FE', '#00C49F', '#FFBB28', '#FF8042',
  '#FF0000', '#00FF00', '#0000FF', '#FFFF00', '#FF00FF',
  '#00FFFF', '#FFA500', '#800080', '#008000', '#A52A2A',
  '#FFD700', '#008080', '#800000', '#000080', '#808080',
  '#FFFFFF', '#000000', '#FF4500', '#32CD32', '#8A2BE2',
  '#FF69B4', '#2E8B57', '#FFFFE0', '#7FFF00', '#4682B4',
  '#8B0000', '#5F9EA0', '#D2691E', '#20B2AA', '#FF7F50',
  '#40E0D0', '#7B68EE', '#00CED1', '#7CFC00', '#6495ED',
  '#DC143C', '#00FF7F', '#FF8C00', '#9932CC', '#00FA9A',
  '#B22222', '#228B22', '#48D1CC', '#6B8E23', '#D2B48C',
  '#8B008B', '#BDB76B', '#556B2F', '#2F4F4F', '#FF00FF'
]

function getItemCount(arr, key){
  let arr2 = []

  arr.forEach((x)=>{

    // Checking if there is any object in arr2
    // which contains the key value
    if(arr2.some((val)=>{ return val[key] == x[key] })){

      // If yes! then increase the occurrence by 1
      arr2.forEach((k)=>{
        if(k[key] === x[key]){
          k['count']++
        }
      })

    }else{
      // If not! Then create a new object initialize
      // it with the present iteration key's value and
      // set the occurrence to 1
      let a = {}
      a[key] = x[key]
      a['count'] = 1
      arr2.push(a)
    }
  })

  return arr2
}

/*
{
  "Team1": [
    {
      "id": 100,
      "strava_activity_id": 9933256000,
      "distance": 10088.9,
      "moving_time": 18439,
      "elapsed_time": 15632,
      "elapsed_time_hour": 4.3,
      "start_date_local": "2022-01-01T19:26:02Z",
      "average_speed": 6.967,
      "age": 77,
      "team": "Team1",
      "club": "WCR",
      "rider_sex": "MALE",
      "rider_country_code": "MY",
      "rider_full_name": "Edward Werner"
    },
 */

function PieChartForClubTeamCount({rideRecords}) {

  const [rideRecordGroupedByTeam, setRideRecordGroupedByTeam] = useState()
  const [minMax, setMinMax] = useState({})

  const [clubCount, setClubCount] = useState([])
  const [teamCount, setTeamCount] = useState([])

  useEffect(() => {

    // team 및 club이 설정되지 않은 경우 'NO TEAM', 'NO CLUB'으로 설정
    const updatedRideRecords = rideRecords.map((item) => {
      if (!item.team) {
        item = {
          ...item,
          team: 'NO TEAM',
        }
      }
      if (!item.club) {
        item = {
          ...item,
          club: 'NO CLUB',
        }
      }
      return item
    })

    setClubCount(getItemCount(updatedRideRecords, 'club'))
    setTeamCount(getItemCount(updatedRideRecords, 'team'))

    /*
    [
      {
        "club": "CD",
        "count": 24
      },
      ...
    ]
     */




  }, [])

    return (
        <>
          <h1 className="chart-title">Club/Team Count</h1>
          <div className="chart-container">
            {clubCount.length > 0 && teamCount.length > 0 && (
              <ResponsiveContainer width="100%" height="100%">
                {/*<PieChart width={400} height={400}>*/}
                <PieChart>
                  <Pie data={clubCount} dataKey="count" nameKey="club" cx="50%" cy="50%" outerRadius={60} fill="#8884d8" />
                  <Pie data={teamCount} dataKey="count" nameKey="team" cx="50%" cy="50%" innerRadius={70} outerRadius={90} fill="#82ca9d"
                       label/>
                  <Tooltip />
                  {/*<Legend />*/}
                </PieChart>
              </ResponsiveContainer>
            )}
          </div>
        </>
    )
}
export default PieChartForClubTeamCount