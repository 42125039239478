import { useState } from 'react'

// react-comments-section
// https://github.com/RiyaNegi/react-comments-section

// todo here - remove this package then use my solution  !!!!!!!!!!!!!!!!
// todo here - remove this package then use my solution  !!!!!!!!!!!!!!!!
// todo here - remove this package then use my solution  !!!!!!!!!!!!!!!!
// todo here - remove this package then use my solution  !!!!!!!!!!!!!!!!

function MyTestPage () {

  // const [commentData, setCommentData] = useState({
  //   data: [
  //     // {
  //     //   userId: '01a',
  //     //   comId: '012',
  //     //   fullName: 'Riya Negi',
  //     //   avatarUrl: 'https://ui-avatars.com/api/name=Riya&background=random',
  //     //   userProfile: 'https://www.linkedin.com/in/riya-negi-8879631a9/',
  //     //   text: 'Hey, Loved your blog! ',
  //     //   replies: []
  //     // },
  //     // {
  //     //   userId: '02b',
  //     //   comId: '017',
  //     //   fullName: 'Lily',
  //     //   userProfile: 'https://www.linkedin.com/in/riya-negi-8879631a9/',
  //     //   text: 'I have a doubt about the 4th point🤔',
  //     //   avatarUrl: 'https://ui-avatars.com/api/name=Lily&background=random',
  //     //   replies: []
  //     // }
  //   ]
  // })

  const onSubmitAction = (data) => {
    console.log('this comment was posted!',data)
    console.log("--------> data:", JSON.stringify(data, undefined, 2))
  }

  const customNoComment = () => (
    <div className='no-com'>No comments here. Be the first one to comment!</div>
  )

  return (
    <div>
      MyTestPage_Page
      {/*<CommentSection*/}
      {/*  currentUser={{*/}
      {/*    currentUserId: '01a',*/}
      {/*    currentUserImg: 'https://ui-avatars.com/api/name=Riya&background=random',*/}
      {/*    currentUserProfile: 'https://www.linkedin.com/in/riya-negi-8879631a9/',*/}
      {/*    currentUserFullName: 'Riya Negi'*/}
      {/*  }}*/}
      {/*  commentData={commentData.data}*/}
      {/*  onSubmitAction={(data) => onSubmitAction(data)}*/}
      {/*  customNoComment={() => customNoComment()}*/}
      {/*  logIn={{*/}
      {/*    loginLink: 'http://localhost:3001/',*/}
      {/*    signupLink: 'http://localhost:3001/'*/}
      {/*  }}*/}
      {/*/>*/}
    </div>
  )
}

export default MyTestPage