export const CONST = {
  APP_NAME: 'RideArc',
  TOAST_AUTO_CLOSE: 1500,

  LOCAL_STORAGE_KEY: {
    STRAVA_TOKENS: 'strava_tokens',
    STRAVA_USER: 'strava_user',
    DJANGO_TOKENS: 'django_tokens',
    DJANGO_PROFILE: 'django_profile',
  },

  NEW_CLUB_MEMBERS_COUNT: 10, //<-- 클럽뷰에서 기본으로 보여줄 사용자 수
  LIST_ITEM_COUNT_FOR_FULL_PAGE: 5,

  DISTANCE_UNIT: {
    KM: 'Km',
    MILE: 'Mile',
  },

  RIDE_EVENT_STATUS: {
    UPCOMING: "UPCOMING",
    OPEN: "OPEN",
    CLOSED: "CLOSED",
  },

  SEX: {
    MALE: 'MALE',
    FEMALE: 'FEMALE',
  },

  BAR_CHART: {
    XAXIS_ANGLE: -50,
    FONT_SIZE: 15,
  },

  SHARE_INFO: {
    URL: 'https://ridearc.com',
    QUOTE: 'Check out this ride!',
    HASHTAG: '#ridearc.com',
    SIZE: 35,
  },

  FILTER_SELECTED_CSS_CLASS: 'bg-gray-200 rounded-r-full',
  INFINITE_SC_END_MSG: 'End of List',
  SUPPORT_EMAIL: 'support@ridearc.com',

  HEX_COLOR_CODES: [
    '#0088FE', '#00C49F', '#FFBB28', '#FF8042',
    '#FF0000', '#0000FF',
    '#FF00FF', '#00FFFF', '#FFA500', '#800080',
    '#008000', '#A52A2A', '#FFD700', '#008080',
    '#800000', '#000080', '#808080', '#FFFFFF',
    '#000000', '#FF4500', '#32CD32', '#8A2BE2',
    '#FF69B4', '#2E8B57', '#FFFFE0', '#7FFF00',
    '#4682B4', '#8B0000', '#5F9EA0', '#D2691E',
    '#20B2AA', '#FF7F50', '#40E0D0', '#7B68EE',
    '#00CED1', '#7CFC00', '#6495ED', '#DC143C',
    '#00FF7F', '#FF8C00', '#9932CC', '#00FA9A',
    '#B22222', '#228B22', '#48D1CC', '#6B8E23',
    '#D2B48C', '#8B008B', '#BDB76B', '#556B2F',
    '#2F4F4F', '#FF00FF'
  ],

  AGE_CATETORIES: [
    { cat: '- 19', lessThan: 20 },
    { cat: '20 - 24', lessThan: 25 },
    { cat: '25 - 29', lessThan: 30 },
    { cat: '30 - 34', lessThan: 35 },
    { cat: '35 - 39', lessThan: 40 },
    { cat: '40 - 44', lessThan: 45 },
    { cat: '45 - 49', lessThan: 50 },
    { cat: '50 - 54', lessThan: 55 },
    { cat: '55 - 59', lessThan: 60 },
    { cat: '60 - 64', lessThan: 65 },
    { cat: '65 - 69', lessThan: 70 },
    { cat: '70 - 74', lessThan: 75 },
    { cat: '75 - 79', lessThan: 80 },
    { cat: '80 -', lessThan: 99 },
  ],
}
